import { ITextScript, ITextSource } from "../VideoEditor";
import ASRRepository, { IASRRepository, IASRResponse, ISubtitle } from "../ASRRepository";

interface ITextSourceUseCase {
  getTextSource: (key: string, fileId: string) => Promise<ITextSource | null>;
}

class TextSourceUseCase implements ITextSourceUseCase {
  private ASRRepository: IASRRepository;

  constructor() {
    this.ASRRepository = new ASRRepository();
  }

  public getTextSource = async (key: string, fileId: string) => {
    const asrResponse = await this.ASRRepository.getASRText(fileId);

    if (!asrResponse.done) {
      return null;
    }

    return convertToTextSource(key, asrResponse);
  };
}

export default TextSourceUseCase;

const convertToTextSource = (key: string, asrResponse: IASRResponse): ITextSource => {
  return {
    key,
    type: "TEXT",
    data: {
      durationInMilliSeconds: asrResponse.response.duration.total_milliseconds,
      scripts: convertToTextScripts(asrResponse.response.subtitles),
    },
  };
};

const convertToTextScripts = (subtitle: ISubtitle[]): ITextScript[] => {
  return subtitle.map((s) => ({
    startTime: s.start.total_milliseconds,
    endTime: s.end.total_milliseconds,
    type: "TEXT_SCRIPT",
    data: {
      language: "",
      text: s.text,
    },
  }));
};
