import * as React from "react";
import * as EventListeners from "../../../eventListeners";
import { ISubscription } from "./Interfaces";
import { IDirectoryUseCase, IFileUseCase } from "../../../UseCases/Interfaces";
import { FileUseCases } from "../../../UseCases/Files/File";
import { DirectoryUseCases } from "../../../UseCases/Directory/Directory";

export interface IUpdateFileSubscribe {
  subscribeFileRenamed?: (
    func: (params: { directoryId: string; targetId: string; name: string }) => void
  ) => ISubscription;

  subscribeFileMoved?: (
    func: (params: { targetId: string; fromDirectoryId: string; toDirectoryId: string }) => void
  ) => ISubscription;
}

export interface IUpdateDirectorySubscribe {
  subscribeDirectoryRenamed?: (
    func: (params: { directoryId: string; targetId: string; name: string }) => void
  ) => ISubscription;

  subscribeDirectoryMoved?: (
    func: (params: { targetId: string; fromDirectoryId: string; toDirectoryId: string }) => void
  ) => ISubscription;
}

export const UpdateFileManagerContext = React.createContext<
  IUpdateFileSubscribe &
    IUpdateDirectorySubscribe & {
      renameDirectory?: (directoryId: string, targetId: string, name: string) => Promise<void>;
      renameFile?: (directoryId: string, targetId: string, name: string) => Promise<void>;
      updateDirectoryParentDirectory?: (
        targetId: string,
        fromParentId: string,
        parentDirectoryId: string
      ) => Promise<void>;
      updateFileParentDirectory?: (targetId: string, fromParentId: string, parentDirectoryId: string) => Promise<void>;
    }
>({});

class UpdateFileManagerContextProvider extends React.Component {
  private fileUseCase: IFileUseCase;
  private directoryUseCase: IDirectoryUseCase;

  constructor(props: {}) {
    super(props);

    this.fileUseCase = new FileUseCases();
    this.directoryUseCase = new DirectoryUseCases();
  }

  public renameFile = async (directoryId: string, targetId: string, name: string) => {
    await this.fileUseCase.renameFile(targetId, name);
    this.notifyFileUpdated(directoryId, targetId, name);
  };

  public renameDirectory = async (directoryId: string, targetId: string, name: string) => {
    await this.directoryUseCase.renameDirectory(targetId, name);
    this.notifyDirectoryRenamed(directoryId, targetId, name);
  };

  public updateDirectoryParentDirectory = async (targetId: string, fromParentId: string, parentDirectoryId: string) => {
    await this.directoryUseCase.updateParentDirectory(targetId, parentDirectoryId);
    this.notifyDirectoryMoved(targetId, fromParentId, parentDirectoryId);
  };

  private notifyDirectoryRenamed = (directoryId: string, targetId: string, name: string) => {
    EventListeners.emit("DIRECTORY_RENAMED", { directoryId, targetId, name });
  };

  public subscribeDirectoryRenamed = (
    func: (params: { directoryId: string; targetId: string; name: string }) => void
  ) => {
    EventListeners.addEventListeners("DIRECTORY_RENAMED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("DIRECTORY_RENAMED", func),
    };
  };

  private notifyDirectoryMoved = (targetId: string, fromDirectoryId: string, toDirectoryId: string) => {
    EventListeners.emit("DIRECTORY_MOVED", {
      targetId,
      fromDirectoryId,
      toDirectoryId,
    });
  };

  public subscribeDirectoryMoved = (
    func: (params: { targetId: string; fromDirectoryId: string; toDirectoryId: string }) => void
  ) => {
    EventListeners.addEventListeners("DIRECTORY_MOVED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("DIRECTORY_MOVED", func),
    };
  };

  public updateFileParentDirectory = async (targetId: string, fromParentId: string, parentDirectoryId: string) => {
    await this.fileUseCase.updateParentDirectory(targetId, parentDirectoryId);

    this.notifyFileMoved(targetId, fromParentId, parentDirectoryId);
  };

  private notifyFileUpdated = (directoryId: string, targetId: string, name: string) => {
    EventListeners.emit("FILE_UPDATED", { targetId, directoryId, name });
  };

  public subscribeFileRenamed = (func: (params: { directoryId: string; targetId: string; name: string }) => void) => {
    EventListeners.addEventListeners("FILE_UPDATED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_UPDATED", func),
    };
  };

  private notifyFileMoved = (targetId: string, fromDirectoryId: string, toDirectoryId: string) => {
    EventListeners.emit("FILE_MOVED", {
      targetId,
      fromDirectoryId,
      toDirectoryId,
    });
  };

  public subscribeFileMoved = (
    func: (params: { targetId: string; fromDirectoryId: string; toDirectoryId: string }) => void
  ) => {
    EventListeners.addEventListeners("FILE_MOVED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_MOVED", func),
    };
  };
  public render() {
    return (
      <UpdateFileManagerContext.Provider
        value={{
          renameFile: this.renameFile,
          renameDirectory: this.renameDirectory,
          updateFileParentDirectory: this.updateFileParentDirectory,
          updateDirectoryParentDirectory: this.updateDirectoryParentDirectory,
          subscribeDirectoryMoved: this.subscribeDirectoryMoved,
          subscribeDirectoryRenamed: this.subscribeDirectoryRenamed,
          subscribeFileMoved: this.subscribeFileMoved,
          subscribeFileRenamed: this.subscribeFileRenamed,
        }}
      >
        {this.props.children}
      </UpdateFileManagerContext.Provider>
    );
  }
}

export default UpdateFileManagerContextProvider;
