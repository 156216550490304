// eslint-disable-next-line
import * as React from "react";
import { useState } from "react";
import { ISearchResult } from "../Models/Interfaces";

const useSearchStore = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);

  return {
    searchValue,
    setSearchValue,
    searchResults,
    setSearchResults,
  };
};

export default useSearchStore;
