export enum FileNodeType {
  FILE = "FILE",
  DIRECTORY = "DIRECTORY",
}

export enum FileStatus {
  UPLOADING = "UPLOADING",
  UPLOADED = "UPLOADED",
  ANALYZING = "ANALYZING",
  ANALYZED = "ANALYZED",
}

export interface IFile {
  id?: string;
  accountId?: string;
  directoryId: string;
  name: string;
  createdDateTime?: string;
  updatedDateTime?: string;
  size?: number;
  s3Path: string;
  s3Url?: string;
  thumbnailS3Path?: string;
  thumbnailS3Url?: string;
  status?: FileStatus;
  analysisRequestId?: string;
  audioExtractionRequestId?: number;
  waveFormRequestId?: number;
}

export interface IDirectory {
  id?: string;
  name: string;
  accountId?: string;
  parentDirectoryId?: string;
  directories?: IDirectory[];
  files?: IFile[];
  deleted?: boolean;
  size?: number;
  createdDateTime?: string;
  updatedDateTime?: string;
}

export interface IFileDTO {
  type: FileNodeType.FILE;
  id: string;
  accountId?: string;
  directoryId: string;
  name: string;
  createdDateTime?: string;
  updatedDateTime?: string;
  size: number;
  s3Path: string;
  s3Url?: string;
  thumbnailS3Path?: string;
  thumbnailS3Url?: string;
  status: FileStatus;
  analysisRequestId?: string;
  audioExtractionRequestId?: number;
  waveFormRequestId?: number;
}

export interface IDirectoryDTO {
  type: FileNodeType.DIRECTORY;
  id: string;
  name: string;
  accountId?: string;
  parentDirectoryId: string;
  directories?: IDirectoryDTO[];
  files?: IFileDTO[];
  deleted: boolean;
  isOpen: boolean;
  size: number;
  createdDateTime?: string;
  updatedDateTime?: string;
  isInitialized: boolean;
}

export interface ISearchResult {
  analysisId: string;
  metadata: {
    fileId: string;
    directoryId: string;
  };
  subtitle: {
    start: {
      total_milliseconds: number;
    };
    end: {
      total_milliseconds: number;
    };
    text: string;
  };
}

export interface IAccountInfo {
  id: number;
  email: string;
}
