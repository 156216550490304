import * as React from "react";
import { ReactElement } from "react";
import "./App.css";
import ContextWrapper from "./Frameworks/Components/ContextWrapper";
import api from "./api";

const App = (): ReactElement | null => {
  return (
    <>
      <ContextWrapper />
    </>
  );
};

api.hello();

export default App;
