import AudioDriver from "../../../Drivers/AudioDriver";

export interface IAudioDriver {
  extractAudio: (fileId: string, handlers?: IAudioExtractHandlers) => void;
  checkExtractAudio: (fileId: string) => Promise<{ status: string }>;
  extractWaveForm: (fileId: string, handlers?: IAudioExtractHandlers) => void;
  checkExtractWaveForm: (fileId: string) => Promise<{ status: string }>;
}

export interface IAudioExtractHandlers {
  onRequest?: () => void;
  onProgress?: () => void;
  onDone?: () => void;
  onCanceled?: () => void;
}

export interface IAudioExtractUseCase {
  requestGenerateAudio: (target: string, audioExtractHandlers?: IAudioExtractHandlers) => void;
  checkGenerateAudio: (target: string) => Promise<{ status: string }>;
  requestGenerateWaveForm: (target: string, waveExtractHandlers?: IAudioExtractHandlers) => void;
  checkGenerateAudioWave: (target: string) => Promise<{ status: string }>;
}

class AudioExtractUseCase implements IAudioExtractUseCase {
  private audioDriver: IAudioDriver;

  constructor() {
    this.audioDriver = new AudioDriver();
  }

  public requestGenerateAudio = (videoId: string, audioExtractHandlers?: IAudioExtractHandlers) => {
    this.audioDriver.extractAudio(videoId, audioExtractHandlers);
  };

  public checkGenerateAudio = async (fileId: string) => {
    return await this.audioDriver.checkExtractAudio(fileId);
  };

  public requestGenerateWaveForm = (videoId: string, waveFormExtractHandlers?: IAudioExtractHandlers) => {
    this.audioDriver.extractWaveForm(videoId, waveFormExtractHandlers);
  };

  public checkGenerateAudioWave = async (fileId: string) => {
    return await this.audioDriver.checkExtractWaveForm(fileId);
  };
}

export default AudioExtractUseCase;
