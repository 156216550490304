import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { IAudioSource, ISelection, ITextScript, ITextSource } from "../../../../../Programs/VideoEditor/VideoEditor";
import styled from "styled-components";
import TimelineHeader from "./TimelineHeader";
import TextTimeline from "./TextTimeline";
import AudioWaveTimeline from "./AudioWaveTimeline";
import SelectionBox from "./SelectionBox";
import PlayerManager from "../../../../../Programs/VideoEditor/VideoPlayer";

const Timeline = React.memo(
  (props: {
    textSource?: ITextSource;
    durationInMilliSeconds: number;
    focusScript?: ITextScript;
    selection: ISelection | null;
    select: (selection: ISelection | null) => void;
    audioSource?: IAudioSource;
    videoPlayer: PlayerManager;
  }) => {
    const { textSource, audioSource, durationInMilliSeconds, selection, select, videoPlayer } = props;
    // const textScripts = textSource?.data.scripts;

    const [unitWidth] = useState<number>(80);
    const parentWidth = (unitWidth * durationInMilliSeconds) / 1000;

    // const windowRef = useRef<HTMLDivElement | null>(null);
    const windowRef = useRef<HTMLDivElement | null>(null);

    const focusToCenter = useCallback(
      (time: number) => {
        const timeInMilliSeconds = time * 1000;
        if (windowRef && windowRef) {
          const windowWidth = windowRef.current?.clientWidth;
          if (windowWidth) {
            windowRef.current?.scrollTo({
              left: (parentWidth * timeInMilliSeconds) / durationInMilliSeconds - windowWidth / 2,
            });
          }
        }
      },
      [durationInMilliSeconds, parentWidth]
    );

    useEffect(() => {
      const subscription = videoPlayer?.subscribeTime(focusToCenter);
      return subscription?.unsubscribe;
    }, [focusToCenter, videoPlayer]);

    const timeBarRef = useRef<HTMLDivElement | null>(null);

    const timeBarMove = useCallback(
      (time: number) => {
        if (timeBarRef && timeBarRef.current) {
          const timeInMilliSeconds = time * 1000;
          const left = parentWidth * (timeInMilliSeconds / durationInMilliSeconds);
          timeBarRef.current.style.left = `${left}px`;
        }
      },
      [durationInMilliSeconds, parentWidth]
    );

    useEffect(() => {
      const subscription = videoPlayer?.subscribeTime(timeBarMove);
      return subscription?.unsubscribe;
    }, [timeBarMove, timeBarRef, videoPlayer]);

    if (parentWidth == null) {
      return null;
    }

    return (
      <TimelineWrapper selectMode={selection != null}>
        <StyledTimeline ref={windowRef}>
          <Alignment>
            <TimelineHeader
              unitWidth={unitWidth}
              parentWidth={parentWidth}
              durationInMilliSeconds={durationInMilliSeconds}
            />
            <SourceWrapper>
              <TextTimeline
                selection={selection}
                duration={durationInMilliSeconds}
                parentWidth={parentWidth}
                textSource={textSource}
                videoPlayer={videoPlayer}
                height={110}
              />
              {audioSource?.data && (
                <AudioWaveTimeline
                  selectionMode={selection != null}
                  durationInMilliSeconds={durationInMilliSeconds}
                  parentWidth={parentWidth}
                  wave={audioSource?.data.wave}
                  pps={audioSource.data.pps}
                  playerManager={videoPlayer}
                  height={80}
                />
              )}
              {selection && (
                <SelectionBoxWrapper>
                  <SelectionBox
                    videoPlayer={videoPlayer}
                    durationInMilliSeconds={durationInMilliSeconds}
                    parentWidth={parentWidth}
                    selection={selection}
                    select={select}
                  />
                </SelectionBoxWrapper>
              )}
              <StyledTimeBar ref={timeBarRef} />
            </SourceWrapper>
          </Alignment>
        </StyledTimeline>
      </TimelineWrapper>
    );
  }
);

export default Timeline;

const SelectionBoxWrapper = styled.div`
  height: 100%;
`;

const StyledTimeBar = styled.div`
  position: absolute;
  //will-change: left;
  //transition: left 0.4s linear;
  height: 100%;
  bottom: 0;
  width: 2px;
  background-color: #ff4444;
  border-top: 5px solid #ff4444;
  border-bottom: 5px solid #ff4444;
  margin-bottom: -5px;
`;

const SourceWrapper = styled.div`
  position: relative;
  padding-top: 29px;
`;

const Alignment = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
`;

const StyledTimeline = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100%;
  box-sizing: border-box;

  ${SelectionBoxWrapper} {
    position: absolute;
    bottom: 0;
  }
`;

const TimelineWrapper = styled.div<{ selectMode: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 29px 20px 21px 20px;
  box-sizing: border-box;
  ${(props) => (props.selectMode ? "border-radius: 3px;background-color: #f7f7f7;" : "")};
`;
