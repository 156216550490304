export interface IPlayerManager {
  getPlaybackRate: () => number;
  increasePlaybackRate: () => void;
  decreasePlaybackRate: () => void;
  play: () => void;
  pause: () => void;
  getDuration: () => number;
  getState: () => boolean | undefined;
  subscribeTime: (func: any) => void;
  seek: (time: number) => void;
}

class PlayerManager implements IPlayerManager {
  private readonly player: HTMLVideoElement;
  private state?: boolean;

  private arr: boolean[];
  private busy: boolean;
  private timeSubscriber: any[];
  private currentTime?: number;
  private interval: number;
  private checkerInterval?: number;
  constructor(player: HTMLVideoElement) {
    this.arr = [];
    this.busy = false;
    this.player = player;
    this.state = undefined;

    this.timeSubscriber = [];
    this.currentTime = undefined;

    this.interval = window.setInterval(() => {
      if (this.player == null) {
        return;
      }

      const currentTime = this.player.currentTime;
      if (currentTime !== this.currentTime) {
        this.currentTime = currentTime;
        this._timeEventFire(currentTime);
        this.state = !this.player.paused;
      }
    }, 16);

    this.pause();
  }

  public increasePlaybackRate = () => {
    this.player.playbackRate = Number((this.player.playbackRate + 0.1).toFixed(1));
    return this.player.playbackRate;
  };

  public decreasePlaybackRate = () => {
    this.player.playbackRate = Number((this.player.playbackRate - 0.1).toFixed(1));
    return this.player.playbackRate;
  };

  public getPlaybackRate = () => this.player.playbackRate;

  public subscribeTime = (func: any) => {
    this.timeSubscriber.push(func);
    return {
      unsubscribe: () => {
        this.timeSubscriber = this.timeSubscriber.filter((f) => f !== func);
      },
    };
  };

  public getTime = (): number => this.player.currentTime;

  public addState = (state: boolean) => {
    this.arr.push(state);
    this.applyState();
  };

  public getDuration = () => this.player.duration;

  public seek = (x: number) => {
    try {
      this.player.currentTime = x;
      this._timeEventFire(x);
    } catch (e) {
      console.log(e);
    }
  };

  public seekDuration = (x: number) => {
    try {
      this.player.currentTime = this.player.currentTime + x;
      this._timeEventFire(this.player.currentTime);
    } catch (e) {
      console.log(e);
    }
  };

  public play = () => {
    this.addState(true);
  };

  public pause = () => {
    this.addState(false);
  };

  public togglePlayState = () => {
    this.addState(!this.getState());
  };

  public applyState = () => {
    if (this.busy || this.arr.length === 0) {
      return;
    }
    this.busy = true;

    const lastState = this.arr.pop();
    this.arr = [];

    if (lastState != null) {
      if (lastState) {
        this.player.play();
      } else {
        this.player.pause();
      }

      if (this.checkerInterval) {
        clearInterval(this.checkerInterval);
      }

      this.checker(lastState, () => {
        this.busy = false;
        this.applyState();
      });
    }
  };

  public getState = () => this.state;

  public checker = (state: boolean, func: any) => {
    this.checkerInterval = window.setInterval(
      () => (state === this.getState() ? func() : this.checker(state, func)),
      500
    );
  };

  private _timeEventFire = (time: number) => {
    for (const func of this.timeSubscriber) {
      new Promise(() => func(time)).then(() => true).catch(() => false);
    }
  };

  public destroy = () => {
    clearInterval(this.interval);
    if (this.checkerInterval) {
      clearInterval(this.checkerInterval);
    }
  };
}

export default PlayerManager;
