const eventListeners: { [key: string]: any } = {};

export const addEventListeners = (type: string, func: (params: any) => void) => {
  eventListeners[type] = eventListeners[type] || [];
  eventListeners[type].push(func);
};

export const removeEventListeners = (type: string, func: (params: any) => void) => {
  if (eventListeners[type] != null) {
    eventListeners[type] = eventListeners[type].filter((f: (params: any) => void) => func !== f);
  }
};

export const emit = (type: string, params?: any) => {
  if (eventListeners[type] != null) {
    eventListeners[type].forEach((f: (params: any) => void) => {
      f(params);
    });
  }
};
