import * as React from "react";
import TopSideBar from "./TopSideBar";
import FileTree from "./FileTree";
import BottomSideBar from "./BottomSideBar";
import styled from "styled-components";

const SideBar = () => {
  return (
    <StyledSideBar>
      <TopSideBarWrapper>
        <TopSideBar />
      </TopSideBarWrapper>
      <FileTreeWrapper>
        <FileTree />
      </FileTreeWrapper>
      <BottomSideBarWrapper>
        <BottomSideBar />
      </BottomSideBarWrapper>
    </StyledSideBar>
  );
};

const TopSideBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
`;

const FileTreeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: auto;
  overflow-y: scroll;
  border-bottom: 1px solid #dcdde3;
  padding: 6px;
  box-sizing: border-box;
`;

const BottomSideBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
`;

const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export default SideBar;
