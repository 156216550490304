export const usageAgreement =
  "이용약관\n" +
  "제 1 조 (목적)\n" +
  '이 약관은 보이스루 주식회사 ("회사" 또는 "보이스루")가 제공하는 보이스루 및 보이스루 관련 제반 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.\n' +
  "제 2 조 (정의)\n" +
  "이 약관에서 사용하는 용어의 정의는 다음과 같습니다.\n" +
  '\t•\t"서비스"라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 "회원"이 이용할 수 있는 보이스루 및 보이스루 관련 제반 서비스를 의미합니다.\n' +
  '②"회원"이라 함은 회사의 "서비스"에 접속하여 이 약관에 따라 "회사"와 이용계약을 체결하고 "회사"가 제공하는 "서비스"를 이용하는 고객을 말합니다.\n' +
  '③"아이디(ID)"라 함은 "회원"의 식별과 "서비스" 이용을 위하여 "회원"이 정하고 "회사"가 승인하는 이메일주소를 의미합니다.\n' +
  '④"비밀번호"라 함은 "회원"이 부여 받은 "아이디와 일치되는 "회원"임을 확인하고 비밀보호를 위해 "회원" 자신이 정한 문자 또는 숫자의 조합을 의미합니다.\n' +
  '⑤"유료서비스"라 함은 "회사"가 유료로 제공하는 각종 온라인디지털콘텐츠(각종 정보콘텐츠, VOD, 아이템 기타 유료콘텐츠를 포함) 및 제반 서비스를 의미합니다.\n' +
  '⑥"포인트"라 함은 서비스의 효율적 이용을 위해 회사가 임의로 책정 또는 지급, 조정할 수 있는 재산적 가치가 없는 "서비스" 상의 가상 데이터를 의미합니다.\n' +
  '⑦"게시물"이라 함은 "회원"이 "서비스"를 이용함에 있어 "서비스상"에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.\n' +
  "제 3 조 (약관의 게시와 개정)\n" +
  '①"회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 서비스 초기 화면에 게시합니다.\n' +
  '②"회사"는 "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.\n' +
  '③"회사"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 30일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 공지 외에 일정기간 서비스내 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.\n' +
  "④회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.\n" +
  "⑤회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.\n" +
  "제 4 조 (약관의 해석)\n" +
  '①"회사"는 "유료서비스" 및 개별 서비스에 대해서는 별도의 이용약관 및 정책(이하 "유료서비스약관 등")을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 "유료서비스약관 등"이 우선하여 적용됩니다.\n' +
  '②이 약관에서 정하지 아니한 사항이나 해석에 대해서는 "유료서비스약관 등" 및 관계법령 또는 상관례에 따릅니다.\n' +
  "제 5 조 (이용계약 체결)\n" +
  '①이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 "회사"가 이러한 신청에 대하여 승낙함으로써 체결됩니다.\n' +
  '②"회사"는 "가입신청자"의 신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "회사"는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.\n' +
  '1.가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 "회사"의 회원 재가입 승낙을 얻은 경우에는 예외로 함.\n' +
  "2.실명이 아니거나 타인의 명의를 이용한 경우\n" +
  '3.허위의 정보를 기재하거나, "회사"가 제시하는 내용을 기재하지 않은 경우\n' +
  "4.14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우\n" +
  "5.이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우\n" +
  '③제1항에 따른 신청에 있어 "회사"는 "회원"의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.\n' +
  '④"회사"는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.\n' +
  '⑤제2항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, "회사"는 원칙적으로 이를 가입신청자에게 알리도록 합니다.\n' +
  '⑥이용계약의 성립 시기는 "회사"가 가입완료를 신청절차 상에서 표시한 시점으로 합니다.\n' +
  '⑦"회사"는 "회원"에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.\n' +
  '⑧"회사"는 "회원"에 대하여 "영화및비디오물의진흥에관한법률" 및 "청소년보호법"등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.\n' +
  "제 6 조 (회원정보의 변경)\n" +
  '①"회원"은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 이메일 등은 수정이 불가능합니다.\n' +
  '②"회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 "회사"에 대하여 그 변경사항을 알려야 합니다.\n' +
  '③제2항의 변경사항을 "회사"에 알리지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.\n' +
  "제 7 조 (개인정보보호 의무)\n" +
  '"회사"는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 "회사"의 개인정보처리방침이 적용됩니다. 다만, "회사"의 공식 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보처리방침이 적용되지 않습니다.\n' +
  '제 8 조 ("회원"의 "아이디" 및 "비밀번호"의 관리에 대한 의무)\n' +
  '①"회원"의 "아이디"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.\n' +
  '②"회사"는 "회원"의 "아이디"가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 "회사" 및 "회사"의 운영자로 오인한 우려가 있는 경우, 해당 "아이디"의 이용을 제한할 수 있습니다.\n' +
  '③"회원"은 "아이디" 및 "비밀번호"가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.\n' +
  '④제3항의 경우에 해당 "회원"이 "회사"에 그 사실을 통지하지 않거나, 통지한 경우에도 "회사"의 안내에 따르지 않아 발생한 불이익에 대하여 "회사"는 책임지지 않습니다.\n' +
  '제 9 조 ("회원"에 대한 통지)\n' +
  '①"회사"가 "회원"에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.\n' +
  '②"회사"는 "회원" 전체에 대한 통지의 경우 7일 이상 "회사"의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.\n' +
  '제 10 조 ("회사"의 의무)\n' +
  '①"회사"는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.\n' +
  '②"회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.\n' +
  '③"회사"는 서비스이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. "회원"이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달합니다.\n' +
  '제 11 조 ("회원"의 의무)\n' +
  '①"회원"은 다음 행위를 하여서는 안 됩니다.\n' +
  "1.신청 또는 변경 시 허위내용의 등록\n" +
  "2.타인의 정보도용\n" +
  '3."회사"가 게시한 정보의 변경\n' +
  '4."회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시\n' +
  '5."회사"와 기타 제3자의 저작권 등 지적재산권에 대한 침해\n' +
  '6."회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위\n' +
  '7.외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 "서비스"에 공개 또는 게시하는 행위\n' +
  '8.회사의 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위\n' +
  "9.기타 불법적이거나 부당한 행위\n" +
  '②"회원"은 관계법, 이 약관의 규정, 이용안내 및 "서비스"와 관련하여 공지한 주의사항, "회사"가 통지하는 사항 등을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는 안 됩니다.\n' +
  '③"회원"이 카페, 블로그 서비스를 이용하여 통신판매 또는 통신판매중개를 업으로 하는 경우 전자상거래 등에서의 소비자보호에 관한 법률에 따른 의무를 준수하여야 하며, 회사는 개별 서비스 이용약관 및 정책에서 관련 내용을 규정할 수 있습니다.\n' +
  '제 12 조 ("서비스"의 제공 등)\n' +
  "①회사는 회원에게 아래와 같은 서비스를 제공합니다.\n" +
  "1. 데이터 수집 (이미지, 음성, 동영상)\n" +
  "2. 데이터 가공 (이미지, 텍스트, 음성, 동영상)\n" +
  "3. 가공된 데이터 제공 (일정 권한을 가진 사용자 대상)\n" +
  "4. 데이터 검색(동영상, 이미지, 음성, 텍스트)\n" +
  "5. 파일 저장\n" +
  '6.기타 "회사"가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 "회원"에게 제공하는 일체의 서비스\n' +
  '②회사는 "서비스"를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에 공지합니다.\n' +
  '③"서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.\n' +
  '④"회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 제9조["회원"에 대한 통지]에 정한 방법으로 "회원"에게 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.\n' +
  '⑤"회사"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.\n' +
  '제 13 조 ("서비스"의 변경)\n' +
  '①"회사"는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경할 수 있습니다.\n' +
  '②"서비스"의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당 서비스 초기화면에 게시하여야 합니다.\n' +
  '③"회사"는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 "회원"에게 별도의 보상을 하지 않습니다.\n' +
  "제 14 조 (정보의 제공 및 광고의 게재)\n" +
  '①"회사"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.\n' +
  '②제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전 동의를 받아서 전송합니다. 다만, "회원"의 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.\n' +
  '③"회사"는 "서비스"의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 "회원"은 수신거절을 "회사"에게 할 수 있습니다.\n' +
  '④"이용자(회원, 비회원 포함)"는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.\n' +
  '제 15 조 ("게시물"의 저작권)\n' +
  '①"회원"이 "서비스" 내에 게시한 "게시물"의 저작권은 해당 게시물의 저작자에게 귀속됩니다.\n' +
  '②"회원"이 "서비스" 내에 게시하는 "게시물"은 검색결과 내지 "서비스" 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, "회원"은 언제든지 고객센터 또는 "서비스" 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.\n' +
  '③"회사"는 제2항 이외의 방법으로 "회원"의 "게시물"을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 "회원"의 동의를 얻어야 합니다.\n' +
  '제 16 조 ("게시물"의 관리)\n' +
  '①"회원"의 "게시물"이 "정보통신망법" 및 "저작권법"등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, "회사"는 관련법에 따라 조치를 취하여야 합니다.\n' +
  '②"회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 "게시물"에 대해 임시조치 등을 취할 수 있습니다.\n' +
  '③본 조에 따른 세부절차는 "정보통신망법" 및 "저작권법"이 규정한 범위 내에서 "회사"가 정한 "게시중단요청서비스"에 따릅니다.\n' +
  "제 17 조 (권리의 귀속)\n" +
  '①"서비스"에 대한 저작권 및 지적재산권은 "회사"에 귀속됩니다. 단, "회원"의 "게시물" 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.\n' +
  '②"회사"는 서비스와 관련하여 "회원"에게 "회사"가 정한 이용조건에 따라 계정, "아이디", 콘텐츠, "포인트" 등을 이용할 수 있는 이용권만을 부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.\n' +
  "제 18 조 (포인트)\n" +
  '"회사"는 서비스의 효율적 이용 및 운영을 위해 사전 공지 후 "포인트"의 일부 또는 전부를 조정할 수 있으며, "포인트"는 회사가 정한 기간에 따라 주기적으로 소멸할 수 있습니다.\n' +
  "제 19 조 (계약해제, 해지 등)\n" +
  '①"회원"은 언제든지 서비스초기화면의 고객센터 또는 내 정보 관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, "회사"는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.\n' +
  '②"회원"이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 "회사"가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 "회원"의 모든 데이터는 소멸됩니다.\n' +
  '③"회원"이 계약을 해지하는 경우, "회원"이 작성한 "게시물" 중 메일, 블로그 등과 같이 본인 계정에 등록된 게시물 일체는 삭제됩니다. 다만, 타인에 의해 담기, 스크랩 등이 되어 재게시되거나, 공용게시판에 등록된 "게시물" 등은 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.\n' +
  "제 20 조 (이용제한 등)\n" +
  '①"회사"는 "회원"이 이 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.\n' +
  '②"회사"는 전항에도 불구하고, "주민등록법"을 위반한 명의도용 및 결제도용, "저작권법" 및 "컴퓨터프로그램보호법"을 위반한 불법프로그램의 제공 및 운영방해, "정보통신망법"을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 영구이용정지 시 "서비스" 이용을 통해 획득한 "포인트" 및 기타 혜택 등도 모두 소멸되며, "회사"는 이에 대해 별도로 보상하지 않습니다.\n' +
  '③"회사"는 "회원"이 계속해서 3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.\n' +
  '④"회사"는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 이용제한정책 및 개별 서비스상의 운영정책에서 정하는 바에 의합니다.\n' +
  '⑤본 조에 따라 "서비스" 이용을 제한하거나 계약을 해지하는 경우에는 "회사"는 제9조["회원"에 대한 통지]에 따라 통지합니다.\n' +
  '⑥"회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 "회사"가 인정하는 경우 "회사"는 즉시 "서비스"의 이용을 재개합니다.\n' +
  "제 21 조 (책임제한)\n" +
  '①"회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.\n' +
  '②"회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.\n' +
  '③"회사"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.\n' +
  '④"회사"는 "회원" 간 또는 "회원"과 제3자 상호간에 "서비스"를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.\n' +
  '⑤"회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.\n' +
  "제 22 조 (준거법 및 재판관할)\n" +
  '①"회사"와 "회원"간 제기된 소송은 대한민국법을 준거법으로 합니다.\n' +
  '②"회사"와 "회원"간 발생한 분쟁에 관한 소송은 제소 당시의 "회원"의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 "회원"의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.\n' +
  "③해외에 주소나 거소가 있는 '회원' 의 경우 '회사'와 '회원'간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할 법원으로 합니다.";

export const privacyAgreement =
  "개인정보\n" +
  "개인정보처리방침\n" +
  "\n" +
  "주식회사 보이스루(이하 ‘회사’라 함)는 [개인정보보호법], [정보통신망 이용촉진 및 정보보호 등에 관한 법률] 등 관련 법령상의 개인정보보호 규정을 준수하고 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.\n" +
  "\n" +
  "본 개인정보처리방침은 회사가 운영하는 “보이스루” 서비스(이하 ‘보이스루’라 함) 등 관련 제반서비스에 적용되며 다음과 같은 내용을 담고 있습니다. 이 개인정보처리방침에서 사용하는 용어의 의미는 관련 법령 및 회사의 이용약관에서 정한 바에 따르며, 그 밖의 사항은 일반적인 상관례에 따릅니다.\n" +
  "\n" +
  "본 방침은 2020년 12월 15일부터 시행됩니다.\n" +
  "\n" +
  "제1조 수집하는 개인정보의 항목\n" +
  "회사는 회원이 입력한 정보를 기반으로 회원가입, ‘보이스루’서비스의 제공, 원활한 고객상담 등을 위하여 필요한 최소한의 개인정보를 수집하고 있습니다.\n" +
  "\n" +
  "•\t회원가입 시점\n" +
  "•\t필수 : 회원의 이름, 아이디(이메일), 비밀번호, 휴대폰번호\n" +
  "•\t서비스 이용과정\n" +
  "•\t‘보이스루’ 내의 개별 서비스 이용 과정에서 해당 서비스의 이용자에 한해 추가 개인정보 수집이 발생할 수 있습니다.\n" +
  "•\t추가로 개인정보를 수집한 경우에는 해당 개인정보 수집 시점에서 이용자에게 ‘수집하는 개인정보 항목, 애니정보의 수집 및 이용목적, 개인정보의 보관기간’에 대해 안내 드리고 동의를 받습니다.\n" +
  "•\t서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다.\n" +
  "\n" +
  "구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 정보통신서비스 제공자가 자동화된 방법으로 생성하여 이를 저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환한 후에 수집하는 경우를 의미합니다.\n" +
  "\n" +
  "제 2조 개인정보의 수집방법\n" +
  "회사는 다음과 같은 방법으로 개인정보를 수집합니다.\n" +
  "•\t서비스 가입이나 사용 중 이용자의 동의를 통한 수집\n" +
  "•\t기기 정보, 서비스 이용 기록 등 생성 정보는 서비스 이용과정에서 자동으로 수집\n" +
  "\n" +
  "제 3조 개인정보의 수집 및 이용목적\n" +
  "회사는 개인정보를 다음의 목적을 위해 수집, 이용합니다.\n" +
  "\n" +
  "•\t회원가입 및 서비스 이용 관리\n" +
  "회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만 14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 서비스 이용 관련 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존을 목적으로 개인정보를 처리합니다.\n" +
  "•\t민원사무 처리\n" +
  "민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.\n" +
  "•\t재화 또는 서비스 제공\n" +
  "서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.\n" +
  "•\t마케팅 및 광고에의 활용\n" +
  "신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.\n" +
  "\n" +
  "제 4조 개인정보 보유 및 이용기간\n" +
  "회사는 법령에 따른 개인정보 보유·이용기간 또는 회원으로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다. 회원의 개인정보는 원칙적으로 개인정보의 수집 및 이용 목적이 달성된 후 지체 없이 파기되며 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.\n" +
  "\n" +
  "•\t계약 또는 청약철회 등에 관한 기록\n" +
  "보관 근거: 전자상거래 등에서의 소비자보호에 관한 법률\n" +
  "보관 기간: 5년\n" +
  "•\t대금결제 및 재화 등의 공급에 관한 기록\n" +
  "보관 근거: 전자상거래 등에서의 소비자보호에 관한 법률\n" +
  "보관 기간: 5년\n" +
  "•\t소비자의 불만 또는 분쟁 처리에 관한 기록\n" +
  "보관 근거: 전자상거래 등에서의 소비자보호에 관한 법률\n" +
  "보관 기간: 3년\n" +
  "•\t서비스 이용 관련 기록\n" +
  "보존 근거: 통신비밀보호법\n" +
  "보존 기간: 3개월\n" +
  "•\t부정 이용 및 기록\n" +
  "부정 가입 및 부정이용기록은 부정 가입 및 이용 방지를 위하여 수집 시점으로부터 6개월간 보관합니다. 부정이용기록 내 개인정보는 가입인증, 정산 신청 시 인증을 위한 휴대폰번호가 있습니다. 결제 도용 등 관련 법령 및 이용약관을 위반하는 보이스루 정산서비스 내 부정거래기록(아이디, 이름, 휴대폰번호, IP 주소, 쿠키, 기기 정보)은 부정거래 방지 및 다른 선량한 이용자의 보호, 안전한 거래 환경 보장을 위하여 수집 시점으로부터 3년간 보관합니다.\n" +
  "\n" +
  "제 5조 이용자 및 법정대리인의 권리, 의무 및 행사방법\n" +
  "이용자 및 법정대리인은 언제든지 자신의 개인정보를 열람, 공개 및 비공개 처리하거나 수정할 수 있으며, 삭제 및 파기를 요청할 수 있습니다.\n" +
  "\n" +
  "•\t개인정보의 열람 및 수정 요청\n" +
  "이용자 및 법정대리인은 언제든지 등록되어 있는 본인 또는 만 14세미만 아동의 개인정보를 조회, 수정할 수 있습니다. 회원 또는 법정대리인은 당해 개인정보의 조회, 수정을 위하여 보이스루 서비스에 마이페이지 메뉴를 클릭하여 계정 등 등록된 개인정보를 직접 열람하거나 수정할 수 있습니다.\n" +
  "•\t개인정보의 삭제(회원탈퇴)\n" +
  "이용자 및 만 14세 미만 아동의 법정대리인이 회원탈퇴를 원하는 경우, 웹사이트 로그인한 후 ‘1:1 문의’ 내에 적힌 고객상담 전화번호, 보이스루 고객센터로 서면, 전화 또는 이메일로 연락하시면 지체없이 조치를 취하겠습니다.\n" +
  "\n" +
  "제 6조 개인정보의 제 3자 제공\n" +
  "•\t회사는 회원의 개인정보를 사전 동의 없이 제 3자에게 제공하지 않습니다.\n" +
  "•\t단, 평가자(일부 특정회원)에게 일반 회원(작업자)의 이메일 계정이 평가 혹은 검수가 진행되는 기간동안 제공될 수 있습니다.\n" +
  "•\t회원의 개인정보를 제공하거나 공유하는 경우에는 회원에게 제공받거나 공유하는 자가 누구이며, 제공 또는 공유되는 개인정보 항목이 무엇인지, 개인정보를 제공하거나 공유하는 목적이 무엇인지, 보유 및 이용기간 등에 대해 개별적으로 사이트, 전자우편 또는 서면, 신청서 등을 통해 고지한 후 이에 대하여 별도 동의를 구합니다.\n" +
  "•\t다만 관련 법령에서 달리 정하는 경우에는 회원의 동의 없이 개인정보를 제공하는 것이 가능합니다.\n" +
  "\n" +
  "제 7조 개인정보처리의 위탁에 관한 사항\n" +
  "회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.\n" +
  "\n" +
  "제 8조 개인정보의 파기 절차 및 방법\n" +
  "개인정보 파기절차 및 방법은 다음과 같습니다.\n" +
  "\n" +
  "•\t파기절차\n" +
  "회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.\n" +
  "단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우, 일정 기간 별도로 보관한 후 파기합니다.\n" +
  "이 경우 별도로 보관된 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.\n" +
  "\n" +
  "•\t파기방법\n" +
  "종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.\n" +
  "\n" +
  "제 9조 개인정보 자동수집장치의 설치/운영 및 그 거부에 관한 사항\n" +
  "회사는 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등 개인정보를 자동으로 수집하는 장치를 설치·운용합니다.\n" +
  "\n" +
  "쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.\n" +
  "회사는 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 방문 회수 파악 등을 통한 개인 맞춤 서비스를 제공하기 위하여 쿠키 등을 사용합니다.\n" +
  "\n" +
  "이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 사용하시는 웹브라우저의 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.\n" +
  "단, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용할 수 없습니다.\n" +
  "•\t설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구>인터넷 옵션>개인정보\n" +
  "\n" +
  "제 10조 개인정보 보호책임자에 관한 사항\n" +
  "회사는 개인정보를 보호하고 개인정보에 관련된 불만을 처리하는 등 개인정보의 처리에 관한 업무를 총괄해서 책임질 개인정보 보호책임자를 다음과 같이 지정하고 있습니다.\n" +
  "\n" +
  "개인정보 보호책임자 : 이상헌\n" +
  "직책 : 대표\n" +
  "연락처 : 070-4282-3117\n" +
  "이메일 : sangheon.lee@voithru.com\n" +
  "\n" +
  "제 11조 개인정보 처리방침의 변경에 관한 사항\n" +
  "이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 가능한 변경사항의 시행 7일 전에 홈페이지 또는 이메일 등을 통해 사전 공지하고 사전 공지가 곤란한 경우 지체없이 공지합니다.";
