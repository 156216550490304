import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import VideoEditor, { IVideoSource } from "../../../../../Programs/VideoEditor/VideoEditor";
import styled from "styled-components";
import PlayerManager from "../../../../../Programs/VideoEditor/VideoPlayer";
import VideoOverlay from "../VideoOverlay";

const INITIAL_VIDEO_PLAY = false;
const VideoPlayerComponent = React.memo(
  (props: {
    editor: VideoEditor;
    videoSource?: IVideoSource;
    playerManager: PlayerManager | null;
    setVideoPlayer: (player: PlayerManager | null) => void;
  }) => {
    const { playerManager, videoSource, setVideoPlayer } = props;
    const [videoRef, setVideoRef] = useState<HTMLVideoElement | null>(null);
    const [isMount, setIsMount] = useState<boolean>(false);
    const [playState, setPlayState] = useState(INITIAL_VIDEO_PLAY);

    useEffect(() => {
      return () => {
        setIsMount(false);
        setVideoRef(null);
        setVideoPlayer(null);
      };
    }, [setVideoPlayer, videoSource]);

    useEffect(() => {
      if (videoRef != null && playerManager == null && isMount) {
        const videoPlayer = new PlayerManager(videoRef);
        setVideoPlayer(videoPlayer);
        setPlayState(INITIAL_VIDEO_PLAY);
      }

      return () => {
        playerManager?.destroy();
      };
    }, [videoRef, isMount, playerManager, setVideoPlayer]);

    const togglePlay = useCallback(() => {
      if (playerManager) {
        if (playState) {
          playerManager.pause();
        } else {
          playerManager.play();
        }
      }
    }, [playerManager, playState]);

    const onPlay = useCallback(() => {
      setPlayState(true);
    }, []);

    const onPause = useCallback(() => {
      setPlayState(false);
    }, []);

    const updateRef = useCallback(
      (element: HTMLVideoElement | null) => {
        if (videoRef == null && element != null) {
          setVideoRef(element);
        }
      },
      [videoRef]
    );

    const videoWrapperRef = useRef<HTMLDivElement | null>(null);
    const [isHovered, setIsHovered] = useState(false);

    const onMouseOver = useCallback(
      (e: MouseEvent) => {
        if (videoWrapperRef.current && videoWrapperRef.current.contains(e.target as Node)) {
          setIsHovered(true);
        }
      },
      [videoWrapperRef]
    );

    const onMouseOut = useCallback(
      (e: MouseEvent) => {
        if (videoWrapperRef.current && videoWrapperRef.current.contains(e.target as Node)) {
          setIsHovered(false);
        }
      },
      [videoWrapperRef]
    );

    useEffect(() => {
      videoWrapperRef.current?.addEventListener("mouseenter", onMouseOver);
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoWrapperRef.current?.removeEventListener("mouseenter", onMouseOver);
      };
    }, [onMouseOver]);

    useEffect(() => {
      videoWrapperRef.current?.addEventListener("mouseleave", onMouseOut);
      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        videoWrapperRef.current?.removeEventListener("mouseleave", onMouseOut);
      };
    }, [onMouseOut]);

    return (
      <StyledVideoPlayer ref={videoWrapperRef} onClick={togglePlay}>
        <StyledVideo
          onLoadedMetadata={() => {
            setIsMount(true);
          }}
          ref={updateRef}
          src={videoSource?.data.src}
          onPlay={onPlay}
          onPause={onPause}
          controls={false}
        />
        {isHovered && (
          <OverlayWrapper>
            <VideoOverlay
              playing={playState}
              durationInMilliSeconds={playerManager?.getDuration() ? playerManager?.getDuration() * 1000 : 0}
            />
          </OverlayWrapper>
        )}
      </StyledVideoPlayer>
    );
  }
);

const StyledVideo = styled.video``;
const OverlayWrapper = styled.div``;
const StyledVideoPlayer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${StyledVideo} {
    width: 100%;
    height: 100%;
  }

  ${OverlayWrapper} {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export default VideoPlayerComponent;
