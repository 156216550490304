import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Description, Header, PageBody, PageHeader, PageWrapper, Title } from "../Commons";

const SignUpEmailCheck = () => {
  const history = useHistory();
  return (
    <PageWrapper>
      <PageHeader>
        <Header>회원가입</Header>
      </PageHeader>
      <PageBody>
        <FormWrapper>
          <Title>인증메일 전송</Title>
          <Description>
            이메일로 인증 링크가 전송되었습니다. 메일에서 인증완료 버튼을 누른 후 다시 로그인해주세요.
          </Description>
          <SignInButton
            onClick={() => {
              history.push("/authentication");
            }}
          >
            로그인하기
          </SignInButton>
        </FormWrapper>
      </PageBody>
    </PageWrapper>
  );
};

const SignInButton = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  padding: 17px 16px 17px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? "#acacac" : "#657eff")};
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
`;

const FormWrapper = styled.div`
  width: 343px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Title} {
    margin-bottom: 20px;
  }

  ${Description} {
    width: 100%;
    margin-bottom: 47px;
  }

  ${SignInButton} {
    width: 100%;
  }
`;

export default SignUpEmailCheck;
