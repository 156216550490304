import * as React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FileNodeType, IDirectoryDTO, IFileDTO } from "../../../../Models/Interfaces";
import { callContextMenu, closeContextMenu, IPosition } from "../../GlobalContextMenu";
import FileVieContextMenu from "./FileViewContextMenu";
import CreateDirectoryPopup from "../SideBar/CreateDirectoryPopup";
import { defaultName, nextDefaultFileName } from "../SideBar/BottomSideBar";
import { PopupContext } from "../../../Contexts/PopupContext";

import * as R from "ramda";
import { IFileSelection } from "../../../hooks/useFileSelection";
import { openProgram } from "../../MainTemplate";
import Files from "./Files";
import EmptyFileIcon from "../../../../assets/images/document@3x.jpg";
import { isMP4File } from "../../../../utils/utils";

interface IProps {
  isShowCheck: boolean;
  fileViewType: "GRID" | "LIST";
  directoryId: string;
  files: Array<IFileDTO | IDirectoryDTO>;
  copyFiles: () => void;
  pasteFile: (directoryId: string) => void;
  selections: IFileSelection[];
  addMultiSelections: (selections: IFileSelection[]) => void;
  select: (type: FileNodeType, fileId: string) => void;
  isSelected: (id: string) => boolean;
  clearSelections: () => void;
  isRoot?: boolean;
  parentId?: string;
  onCreateDirectory: (name: string) => void;
  moveFileToParent: (targetId: string) => void;
  onFileNameChange: (id: string, name: string) => void;
  onDirectoryNameChange: (id: string, name: string) => void;
  onFileDelete: (id: string) => void;
  onDirectoryDelete: (id: string) => void;
  createFile: (directoryId: string, file: File) => void;
}

const FileViewComponent = (props: IProps) => {
  const { showPopup } = useContext(PopupContext);
  const {
    isShowCheck,
    fileViewType,
    directoryId,
    files,
    onFileNameChange,
    onDirectoryNameChange,
    copyFiles,
    pasteFile,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addMultiSelections,
    isSelected,
    select,
    clearSelections,
    selections,
    onCreateDirectory,
    onDirectoryDelete,
    onFileDelete,
    isRoot,
    moveFileToParent,
    createFile,
  } = props;
  const history = useHistory();

  const onDragStart = () => {};

  const onFileClick = (e: React.MouseEvent, fileId: string) => {
    if (directoryId == null) {
      return;
    }

    // const fileSelection: IFileSelection = {
    //   type: FileNodeType.FILE,
    //   id: fileId,
    // };

    // if (e.shiftKey) {
    //   addMultiSelections([fileSelection]);
    //   return;
    // }

    select(FileNodeType.FILE, fileId);
  };

  const onFileDoubleClick = (e: React.MouseEvent, fileId?: string) => {
    if (!fileId) {
      return;
    }
    openProgram("videoEditor", fileId, {});
    clearSelections();
  };

  const onDirectoryClick = (e: React.MouseEvent, targetDirectoryId: string) => {
    if (directoryId == null) {
      return;
    }

    // const fileSelection: IFileSelection = {
    //   type: FileNodeType.DIRECTORY,
    //   id: targetDirectoryId,
    // };
    // if (e.shiftKey) {
    //   addMultiSelections([fileSelection]);
    //   return;
    // }

    select(FileNodeType.DIRECTORY, targetDirectoryId);
  };

  const onDirectoryDoubleClick = (e: React.MouseEvent, directoryId?: string) => {
    history.push(`/directory/${directoryId}`);
    clearSelections();
  };

  const callCreateDirectory = () => {
    clearSelections();
    if (showPopup) {
      showPopup!(
        <CreateDirectoryPopup
          defaultName={nextDefaultFileName(defaultName, [])}
          onCreateDirectory={onCreateDirectory}
        />
      );
    }
  };

  const callFileContext = (position: IPosition, onNameChange: () => void) => {
    const onDeleteHandlers = () => {
      const copiedSelections = R.clone(selections);
      files
        ?.filter((file) => copiedSelections.map((selection) => selection.id).includes(file.id))
        .forEach((file) => {
          if (copiedSelections.map((selection) => selection.id).includes(file.id)) {
            if (file.type === FileNodeType.FILE) {
              onFileDelete(file.id);
            }

            if (file.type === FileNodeType.DIRECTORY) {
              onDirectoryDelete(file.id);
            }
          }
        });

      closeContextMenu();
    };

    if (selections.length > 1) {
      callContextMenu(
        position,
        { width: 100, height: 100 },
        <FileVieContextMenu
          menus={[
            {
              text: "복사",
              onClick: () => {
                copyFiles();
                closeContextMenu();
              },
            },
            // {text:'다운로드', onClick: () => {closeContextMenu()}},
            { text: "삭제", onClick: onDeleteHandlers },
          ]}
        />
      );
    } else {
      callContextMenu(
        position,
        { width: 100, height: 100 },
        <FileVieContextMenu
          menus={[
            { text: "이름 바꾸기", onClick: onNameChange },
            {
              text: "복사",
              onClick: () => {
                copyFiles();
                closeContextMenu();
              },
            },
            // {text:'다운로드', onClick: () => {closeContextMenu()}},
            { text: "삭제", onClick: onDeleteHandlers },
          ]}
        />
      );
    }
  };

  const callEmptyContext = (position: IPosition) => {
    callContextMenu(
      position,
      { width: 100, height: 100 },
      <FileVieContextMenu
        menus={[
          {
            text: "새폴더 추가",
            onClick: () => {
              callCreateDirectory();
              closeContextMenu();
            },
          },
          {
            text: "붙여넣기",
            onClick: () => {
              pasteFile(directoryId);
              closeContextMenu();
            },
          },
          // {text:'다운로드', onClick: () => {closeContextMenu()}},
          // {text:'삭제', onClick: onDelete}
        ]}
      />
    );
  };

  const addFileWithDropsToCurrentDirectory = (e: React.DragEvent) => {
    addFileWithDrops(e, directoryId, createFile);
  };

  if (isRoot && files.length === 0) {
    return (
      <FileViewWrapper
        onDrop={addFileWithDropsToCurrentDirectory}
        onDrag={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragEnter={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragEnd={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          callEmptyContext({ x: e.pageX, y: e.pageY });
        }}
      >
        <EmptyFileView>
          <EmptyFileImage src={EmptyFileIcon} />
          <EmptyFileComment>아직 업로드 된 파일이 없어요</EmptyFileComment>
        </EmptyFileView>
      </FileViewWrapper>
    );
  }

  return (
    <FileViewWrapper
      onDrag={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragEnd={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onContextMenu={(e) => {
        e.preventDefault();
        callEmptyContext({ x: e.pageX, y: e.pageY });
      }}
      onDrop={addFileWithDropsToCurrentDirectory}
    >
      <Files
        isSelected={isSelected}
        onDragStart={onDragStart}
        onDragEnd={() => {}}
        onDrop={moveFileToParent}
        onFileDoubleClick={onFileDoubleClick}
        directoryId={directoryId}
        onFileClick={onFileClick}
        onDirectoryClick={onDirectoryClick}
        onDirectoryDoubleClick={onDirectoryDoubleClick}
        fileViewType={fileViewType}
        createFile={createFile}
        onDirectoryNameChange={onDirectoryNameChange}
        onFileNameChange={onFileNameChange}
        callContext={callFileContext}
        isShowCheck={isShowCheck}
        files={files}
      />
    </FileViewWrapper>
  );
};

export const addFileWithDrops = (
  e: React.DragEvent,
  targetDirectoryId: string,
  createFile: (directoryId: string, file: File) => void
) => {
  e.preventDefault();
  e.stopPropagation();
  if (e.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (e.dataTransfer.items[i].kind === "file") {
        const file = e.dataTransfer.items[i].getAsFile();

        if (file != null) {
          if (!isMP4File(file.name)) {
            alert("mp4 파일만 업로드 가능합니다");
          } else {
            createFile(targetDirectoryId, file);
          }
        }
      }
    }
  }
};

const EmptyFileImage = styled.img`
  max-width: 600px;
`;

const EmptyFileComment = styled.div`
  font-family: NanumSquareB;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #33343d;
`;

const EmptyFileView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${EmptyFileImage} {
    margin-left: -10px;
  }

  ${EmptyFileComment} {
    margin-top: -10px;
  }
`;

const FileViewWrapper = styled.div`
  height: 100%;
  flex: 0 1 auto;
  overflow-y: scroll;
`;

export default FileViewComponent;
