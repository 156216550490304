import * as React from "react";

import FileIcon from "../../../../assets/images/file-20-px-blue.svg";
import DisabledFileIcon from "../../../../assets/images/1-blur-gallary-file-24-px-light-blue.svg";

import { IPosition } from "../../GlobalContextMenu";
import { IFileDTO } from "../../../../Models/Interfaces";
import GridViewItem from "./GridViewFile/GridViewItem";
import ListViewItem from "./ListViewFile/ListViewItem";

const FileViewItem = (props: {
  type: "GRID" | "LIST";
  file: IFileDTO;
  selected: boolean;
  isShowCheck: boolean;
  onClick?: (e: React.MouseEvent, id: string) => void;
  onDoubleClick?: (e: React.MouseEvent, id: string) => void;
  callContext: (position: IPosition, onNameChange: () => void) => void;
  onNameChange: (id: string, name: string) => void;
  onDragStart?: (id: string) => void;
  onDragEnd?: (id: string) => void;
  onDrop?: (targetId: string) => void;
  disabled?: boolean;
}) => {
  const {
    type,
    file,
    selected,
    isShowCheck,
    onClick,
    onDoubleClick,
    callContext,
    onNameChange,
    onDragStart,
    onDrop,
    disabled,
  } = props;

  const onClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onClick) {
      onClick(e, file.id);
    }
  };

  const onDoubleClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onDoubleClick) {
      onDoubleClick(e, file.id);
    }
  };

  const onRightClickHandler = (e: React.MouseEvent, onNameChange: () => void) => {
    e.preventDefault();
    if (onClick) {
      onClick(e, file.id);
    }
    if (callContext) {
      callContext({ x: e.pageX, y: e.pageY }, onNameChange);
    }
  };

  const onNameChangeHandler = (name: string) => {
    onNameChange(file.id, name);
  };

  const onDragStartHandler = () => {
    if (onDragStart) {
      onDragStart(file.id);
    }
  };

  const onDropHandler = () => {
    if (onDrop) {
      onDrop(file.id);
    }
  };

  if (type === "GRID") {
    return (
      <GridViewItem
        onDragStart={onDragStartHandler}
        onDrop={onDropHandler}
        isShowCheck={isShowCheck}
        icon={disabled ? DisabledFileIcon : FileIcon}
        name={file.name}
        size={file.size}
        createdDateTime={file.createdDateTime!}
        selected={selected}
        onNameChange={onNameChangeHandler}
        onClick={onClickHandler}
        onDoubleClick={onDoubleClickHandler}
        onRightClick={onRightClickHandler}
        disabled={disabled}
      />
    );
  }

  return (
    <ListViewItem
      onDragStart={onDragStartHandler}
      onDrop={onDropHandler}
      isShowCheck={isShowCheck}
      icon={disabled ? DisabledFileIcon : FileIcon}
      name={file.name}
      size={file.size}
      createdDateTime={file.createdDateTime!}
      selected={selected}
      onNameChange={onNameChangeHandler}
      onClick={onClickHandler}
      onDoubleClick={onDoubleClickHandler}
      onRightClick={onRightClickHandler}
      disabled={disabled}
    />
  );
};

export default FileViewItem;
