import { IDirectoryUseCase } from "../Interfaces";
import { IDirectoryRepository } from "../../Respositories/Interfaces";
import DirectoryRepository from "../../Respositories/Directory";
import { DirectoryModel } from "../../Models/FileNode";
import { IDirectory } from "../../Models/Interfaces";
import api from "../../api";

export class DirectoryUseCases implements IDirectoryUseCase {
  private repository: IDirectoryRepository;

  constructor() {
    this.repository = new DirectoryRepository();
  }

  public getRootDirectory = async () => {
    const directory = await this.repository.getRootDirectory();
    return new DirectoryModel(directory).toDTO();
  };

  public getDirectory = async (id: string) => {
    const directory = await this.repository.getDirectory(id);
    return new DirectoryModel(directory).toDTO();
  };

  public createDirectory = async (parentDirectoryId: string, name: string) => {
    const model: IDirectory = {
      parentDirectoryId,
      name,
    };
    const directory = await this.repository.createDirectory(model);
    return new DirectoryModel(directory).toDTO();
  };

  public deleteDirectory = async (id: string) => {
    const directory = await this.repository.deleteDirectory(id);
    return new DirectoryModel(directory).toDTO();
  };

  public renameDirectory = async (id: string, name: string) => {
    const directory = await this.repository.renameDirectory(id, name);
    return new DirectoryModel(directory).toDTO();
  };

  public updateParentDirectory = async (id: string, directoryId: string) => {
    const { data } = await api.patchParentDirectory(id, directoryId);
    return data;
  };

  public askDirectoryFullPath = async (id: string): Promise<string[]> => {
    const path = await this.repository.askDirectoryFullPath(id);
    return path;
  };
}
