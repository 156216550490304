import * as React from "react";
import { PropsWithChildren, ReactElement, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

const DropDown = (
  props: PropsWithChildren<{
    toggled: boolean;

    setToggled: (state: boolean) => void;
    items: Array<{
      key: any;
      icon: string;
      name: ReactElement | string;
      onClick: () => void;
    }>;
  }>
) => {
  const { items, toggled, setToggled } = props;

  const dropDownRef = useRef<HTMLDivElement>(null);

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (dropDownRef && dropDownRef.current && e.target && !dropDownRef.current.contains(e.target as Node)) {
        setToggled(false);
      }
    },
    [dropDownRef, setToggled]
  );

  useEffect(() => {
    window.addEventListener("click", clickOutside);
    return () => {
      window.removeEventListener("click", clickOutside);
    };
  }, [clickOutside]);

  return (
    <Wrapper onClick={() => setToggled(!toggled)} ref={dropDownRef}>
      {props.children}
      {toggled && (
        <StyledDropdownMenu>
          {items.map((item) => (
            <StyledDropdownMenuItem
              key={item.key}
              onClick={(e) => {
                e.stopPropagation();
                item.onClick();
              }}
            >
              <Icon src={item.icon} />
              <Name>{item.name}</Name>
            </StyledDropdownMenuItem>
          ))}
        </StyledDropdownMenu>
      )}
    </Wrapper>
  );
};

export default DropDown;

const Icon = styled.img``;
const Name = styled.div``;
const StyledDropdownMenuItem = styled.div`
  padding: 11px 20px 11px 13px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: #33343d;
  background-color: #ffffff;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${Icon} {
    margin-right: 13px;
  }
  &:hover {
    background-color: #eff0f2;
  }
`;

const StyledDropdownMenu = styled.div`
  position: absolute;
  z-index: 1;
  padding: 5px 0;
  right: 0;
  margin-top: 13px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
`;

const Wrapper = styled.div`
  position: relative;
`;
