import { IAuthenticationUseCase } from "./Interfaces";
import AuthenticationRepository from "../Respositories/Authentication";
import { authorizationManager } from "../api";

export interface ISignUpInfo {
  name: string;
  email: string;
  password?: string;
  googleUserId?: string;
}

class AuthenticationUseCase implements IAuthenticationUseCase {
  private repository: AuthenticationRepository;
  constructor() {
    this.repository = new AuthenticationRepository();
  }

  public signIn = async (email: string, password: string) => {
    await authorizationManager.signIn(email, password);
  };

  public signUp = async (signUpInfo: { email: string; password: string; name: string }) => {
    const { email, password, name } = signUpInfo;
    await this.repository.signUp(email, password, name);
  };

  public resetPassword = async (email: string) => {
    await this.repository.resetPassword(email);
  };

  public changePassword = async (newPassword: string) => {
    await this.repository.changePassword(newPassword);
  };

  public googleSignIn = async (token: string) => {
    await authorizationManager.googleSignIn(token);
  };

  public googleSignUp = async (googleSignUpInfo: { googleUserId: string; name: string; email: string }) => {
    const { googleUserId, name, email } = googleSignUpInfo;
    await this.repository.googleSignUp(googleUserId, email, name);
  };
}

export default AuthenticationUseCase;
