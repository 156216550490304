import * as React from "react";
import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";

const SearchBar = (props: {
  value: string;
  setValue: (value: string) => void;
  isSearching: boolean;
  onFocus: () => void;
  onEnter: () => void;
}) => {
  const { value, setValue, isSearching, onFocus, onEnter } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isSearching && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearching]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        onEnter();
      }
    },
    [onEnter]
  );

  return (
    <StyledSearchBar>
      <StyledSearchInput
        ref={inputRef}
        value={value}
        placeholder={"스크립트 검색으로 원하는 장면을 찾아보세요."}
        onChange={(e) => setValue(e.currentTarget.value)}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />
    </StyledSearchBar>
  );
};

const StyledSearchInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  min-height: 32px;
`;

const StyledSearchBar = styled.div`
  width: 100%;
  padding: 13px;
  box-sizing: border-box;
`;

export default SearchBar;
