import * as React from "react";
import { ISearchResult } from "../../../../Models/Interfaces";
import SearchResult from "./SearchResult";

export interface IResultsSortByFile {
  [fileId: string]: ISearchResult[];
}

const SearchResultsSortByFile = (props: { searchResults: ISearchResult[]; searchValue: string }) => {
  const { searchResults, searchValue } = props;
  const searchResultsByFile = convert2ResultsSortByFile(searchResults);

  return (
    <>
      {searchResultsByFile.map((node, idx) => (
        <SearchResult
          initialOpen={idx === 0}
          fileId={node.getKey()}
          results={node.getChildren()}
          searchValue={searchValue}
        />
      ))}
    </>
  );
};

const convert2ResultsSortByFile = (searchResults: ISearchResult[]) => {
  const uniqueFileIds: string[] = Array.from(new Set<string>(searchResults.map((r) => r.metadata.fileId)));
  const getResultByFileId = (fileId: string) => searchResults.filter((r) => r.metadata.fileId === fileId);

  return uniqueFileIds.map((id) => {
    const results = getResultByFileId(id);
    return new SortNode(id, results);
  });
};

class SortNode {
  private key: string;
  private children: any[];
  constructor(key: string, children: any[]) {
    this.key = key;
    this.children = children;
  }

  public getChildren = () => {
    return this.children;
  };

  public getKey = () => {
    return this.key;
  };
}

export default SearchResultsSortByFile;
