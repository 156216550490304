import api from "../api";

export interface IAuthenticationRepository {
  resetPassword: (email: string) => Promise<void>;
  changePassword: (newPassword: string) => Promise<void>;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  googleSignUp: (googleUserId: string, email: string, name: string) => Promise<void>;
}

class AuthenticationRepository implements IAuthenticationRepository {
  public signUp = async (email: string, password: string, name: string) => {
    await api.signUp({ name, email, password });
  };
  public googleSignUp = async (googleUserId: string, email: string, name: string) => {
    await api.googleOauthSignUp({ googleUserId, name, email });
  };

  public resetPassword = async (email: string) => {
    await api.resetPassword(email);
  };

  public changePassword = async (newPassword: string) => {
    await api.changePassword(newPassword);
  };
}

export default AuthenticationRepository;
