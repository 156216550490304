import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import SideBar from "./Pages/SideBar";
import styled from "styled-components";
import ProgressFiles from "./Pages/ProgressFiles";
import MainView from "./Pages/MainView";
import SearchView from "./Pages/SearchView";
import useSearch from "../Containers/UseSearch";
import Authentication from "./Pages/Authentication";
import PopupContextGlobalPopup from "./Pages/Popup/GlobalPopup";
import GlobalContextMenu from "./GlobalContextMenu";
import VideoEditorPage, { getFileId, getSelection } from "./Pages/VideoEditor";
import { Location } from "history";
import { addEventListeners, emit, removeEventListeners } from "../../eventListeners";
import FeedBack from "./Pages/FeedBack/FeedBack";
import { AuthenticationContext } from "../Contexts/AuthenticationContext";
import { PrivacyAgreement, UsageAgreement } from "./Pages/Authentication/AgreementPage";
import PrivateRoute from "./PrivateRoute";

const addOpenProgramEventListeners = (func: (params: { type: string; fileId: string; options?: any }) => void) => {
  addEventListeners("OPEN_PROGRAM", func);
};

const removeOpenProgramEventListeners = (func: (params: { type: string; fileId: string; options?: any }) => void) => {
  removeEventListeners("OPEN_PROGRAM", func);
};

const addCloseProgramEventListeners = (func: (params: { fileId: string }) => void) => {
  addEventListeners("CLOSE_PROGRAM", func);
};

const removeCloseProgramEventListeners = (func: (params: { fileId: string }) => void) => {
  removeEventListeners("CLOSE_PROGRAM", func);
};

export const openProgram = (type: string, fileId: string, options?: any) => {
  emit("OPEN_PROGRAM", { type, fileId, options });
};

export const closeProgram = (fileId: string) => {
  emit("CLOSE_PROGRAM", { fileId });
};

const FileManagers = (props: { location: Location }) => {
  const { searchValue, setSearchValue, searchResults, setSearchResults, search } = useSearch();
  const { isLogin } = useContext(AuthenticationContext);
  return (
    <>
      <Route path={"/authentication"} component={Authentication} />
      <PrivateRoute path={"/feedback"} component={FeedBack} />
      <PrivateRoute
        location={props.location}
        path={"/directory/:id?"}
        render={(props) => (
          <MainView
            history={props.history}
            location={props.location}
            match={props.match}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            doSearch={search}
          />
        )}
      />
      <PrivateRoute
        location={props.location}
        path={"/search"}
        render={() => (
          <SearchView
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            doSearch={search}
          />
        )}
      />
      <Route path={"/usageAgreement"} component={UsageAgreement} />
      <Route path={"/privacyAgreement"} component={PrivacyAgreement} />
      <Route path={"/"} render={() => <Redirect to={isLogin ? "/directory" : "/authentication"} />} />
    </>
  );
};

const MainTemplate = () => {
  const location = useLocation<{ program: string; prevLocation: Location }>();
  const history = useHistory();

  const [fileManagerLocation, setFileManagerLocation] = useState<Location | null>(null);
  const [programLocation, setProgramLocation] = useState<Location | null>(null);

  const openProgram = useCallback(
    (params: { type: string; fileId: string; options?: any }) => {
      if (params.type === "videoEditor") {
        const prevLocation = fileManagerLocation;
        const startTimeInSeconds = params.options.startTimeInSeconds;
        const endTimeInSeconds = params.options.endTimeInSeconds;

        const range =
          startTimeInSeconds != null && endTimeInSeconds != null
            ? `?startTimeInSeconds=${startTimeInSeconds}&endTimeInSeconds=${endTimeInSeconds}`
            : "";

        history.push(`/videoEditor/${params.fileId}${range}`, {
          program: "videoEditor",
          prevLocation,
        });
      }
    },
    [fileManagerLocation, history]
  );

  useEffect(() => {
    const split = location.pathname.split("/");
    const findBeforeFileIdPath = (element: string) => element === "videoEditor";
    const isVideoEditorPath = split.findIndex(findBeforeFileIdPath) > 0;
    if (isVideoEditorPath) {
      const fileId = getFileId(location);
      const selection = getSelection(location);

      if (location.pathname.includes("/videoEditor") && programLocation == null) {
        openProgram({ type: "videoEditor", fileId, options: selection });
      }
    }
  }, [location, openProgram, programLocation]);

  const closeProgram = useCallback(
    (params: { fileId: string }) => {
      history.push(fileManagerLocation?.pathname || "/");
      setProgramLocation(null);
    },
    [history, fileManagerLocation?.pathname]
  );

  useEffect(() => {
    addCloseProgramEventListeners(closeProgram);
    return () => {
      removeCloseProgramEventListeners(closeProgram);
    };
  }, [closeProgram]);

  useEffect(() => {
    if (location.state && location.state.program === "videoEditor") {
      setProgramLocation(location);
      setFileManagerLocation(location.state.prevLocation);
    } else {
      setFileManagerLocation(location);
    }
  }, [location, openProgram]);

  useEffect(() => {
    addOpenProgramEventListeners(openProgram);
    return () => {
      removeOpenProgramEventListeners(openProgram);
    };
  }, [openProgram, fileManagerLocation, programLocation]);

  return (
    <>
      <Switch>
        <RootBody>
          {/*split view*/}
          <SideNavigation>
            <SideBar />
          </SideNavigation>
          <Page>
            <FileManagers
              location={(location.state != null && location.state.program != null && fileManagerLocation) || location}
            />
          </Page>
          <PopupContextGlobalPopup />
          <GlobalContextMenu />
          <ProgressFiles />
        </RootBody>
      </Switch>
      {programLocation ? <Route component={VideoEditorPage} location={programLocation} /> : null}
    </>
  );
};

const RouterWrapperMainTemplate = () => (
  <BrowserRouter>
    <MainTemplate />
  </BrowserRouter>
);

export default RouterWrapperMainTemplate;

const RootBody = styled.div`
  user-select: none;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const SideNavigation = styled.div`
  width: 240px;
  min-width: 240px;
  height: 100%;
  border-right: 1px solid #dcdde3;
`;

const Page = styled.div`
  width: 100%;
  height: 100%;
`;
