import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { IDirectoryDTO } from "../../Models/Interfaces";
import { DirectoryUseCases } from "../../UseCases/Directory/Directory";
import * as R from "ramda";
import { CreateFileManagerContext } from "../Contexts/FileManagerContexts/CreateFileManagerContext";
import { UpdateFileManagerContext } from "../Contexts/FileManagerContexts/UpdateFileManagerContext";
import { DeleteFileManagerContext } from "../Contexts/FileManagerContexts/DeleteFileManagerContext";

const useFileTree = () => {
  const directoryUseCase = React.useMemo(() => new DirectoryUseCases(), []);
  const [root, setRoot] = useState<IDirectoryDTO>();
  //
  // const addFiles = useCallback(
  //   (directoryId: string, files: IFileDTO[]) => {
  //     if (root == null) {
  //       throw new Error("");
  //     }
  //
  //     const parentDirectory = searchDirectoryNode(root, directoryId);
  //
  //     if (parentDirectory == null) {
  //       return;
  //     }
  //
  //     parentDirectory.files = parentDirectory.files ? [...parentDirectory.files, ...files] : [...files];
  //     const updated = R.clone(root);
  //     setRoot(updated);
  //   },
  //   [root]
  // );
  //
  // const renameFile = useCallback(
  //   (directoryId: string, targetId: string, name: string) => {
  //     if (root == null) {
  //       throw new Error("");
  //     }
  //
  //     const targetDirectory = searchDirectoryNode(root, directoryId);
  //
  //     if (targetDirectory == null) {
  //       return;
  //     }
  //
  //     targetDirectory.files = targetDirectory.files?.map((file) => (file.id === targetId ? { ...file, name } : file));
  //     const updated = R.clone(root);
  //     setRoot(updated);
  //   },
  //   [root]
  // );
  //
  // const removeFile = useCallback(
  //   (directoryId: string, targetFileId: string) => {
  //     if (root == null) {
  //       throw new Error("");
  //     }
  //
  //     const targetDirectory = searchDirectoryNode(root, directoryId);
  //
  //     if (targetDirectory == null) {
  //       return;
  //     }
  //
  //     targetDirectory.files = targetDirectory.files
  //       ? targetDirectory.files.filter((file) => file.id !== targetFileId)
  //       : [];
  //     const updated = R.clone(root);
  //     setRoot(updated);
  //   },
  //   [root]
  // );

  // const moveFile = useCallback(
  //   (targetId: string, fromDirectoryId: string, toDirectoryId: string) => {
  //     if (root == null) {
  //       throw new Error("");
  //     }
  //
  //     removeFile(fromDirectoryId, targetId);
  //
  //     fileUseCase.getFile(targetId).then((file) => {
  //       addFiles(toDirectoryId, [file]);
  //     });
  //   },
  //   [root, removeFile, fileUseCase, addFiles]
  // );

  const addDirectories = useCallback(
    (directoryId: string, directories: IDirectoryDTO[]) => {
      if (root == null) {
        throw new Error("");
      }
      const parentDirectory = searchDirectoryNode(root, directoryId);

      if (parentDirectory == null) {
        return;
      }

      parentDirectory.directories = parentDirectory.directories
        ? [...parentDirectory.directories, ...directories]
        : [...directories];

      const updated = R.clone(root);
      setRoot(updated);
    },
    [root]
  );

  const renameDirectory = useCallback(
    (directoryId: string, targetId: string, name: string) => {
      if (root == null) {
        throw new Error("");
      }

      const targetDirectory = searchDirectoryNode(root, directoryId);

      if (targetDirectory == null) {
        return;
      }

      targetDirectory.directories = targetDirectory.directories?.map((directory) =>
        directory.id === targetId ? { ...directory, name } : directory
      );

      const updated = R.clone(root);
      setRoot(updated);
    },
    [root]
  );

  const removeDirectory = useCallback(
    (directoryId: string, targetId: string) => {
      if (root == null) {
        throw new Error("");
      }

      const targetDirectory = searchDirectoryNode(root, directoryId);

      if (targetDirectory == null) {
        return;
      }

      targetDirectory.directories = targetDirectory.directories?.filter((diretory) => diretory.id !== targetId);

      const updated = R.clone(root);
      setRoot(updated);
    },
    [root]
  );

  const moveDirectory = useCallback(
    (targetId: string, fromDirectoryId: string, toDirectoryId: string) => {
      if (root == null) {
        throw new Error("");
      }

      removeDirectory(fromDirectoryId, targetId);

      directoryUseCase.getDirectory(targetId).then((directory) => {
        addDirectories(toDirectoryId, [directory]);
      });
    },
    [root, removeDirectory, directoryUseCase, addDirectories]
  );

  const { subscribeDirectoryCreated } = useContext(CreateFileManagerContext);
  const { subscribeDirectoryMoved, subscribeDirectoryRenamed } = useContext(UpdateFileManagerContext);
  const { subscribeDirectoryDeleted } = useContext(DeleteFileManagerContext);
  //
  // const createFileCallback = useCallback(
  //   (params: { processId: number; directoryId: string; file: IFileDTO }) => {
  //     const { file, directoryId } = params;
  //     addFiles(directoryId, [file]);
  //   },
  //   [addFiles]
  // );
  //
  // useEffect(() => {
  //   const subscription = subscribeFileCreated!(createFileCallback);
  //   return subscription.unsubscribe;
  // }, [root, subscribeFileCreated, createFileCallback]);

  // const deleteFileCallback = useCallback(
  //   (params: { directoryId: string; targetId: string }) => {
  //     removeFile(params.directoryId, params.targetId);
  //   },
  //   [removeFile]
  // );
  //
  // useEffect(() => {
  //   const subscription = subscribeFileDelete!(deleteFileCallback);
  //   return subscription.unsubscribe;
  // }, [subscribeFileDelete, deleteFileCallback]);
  //
  // const updateFileParentDirectoryCallback = useCallback(
  //   (params: { targetId: string; fromDirectoryId: string; toDirectoryId: string }) => {
  //     moveFile(params.targetId, params.fromDirectoryId, params.toDirectoryId);
  //   },
  //   [moveFile]
  // );

  // useEffect(() => {
  //   const subscription = subscribeFileMoved!(updateFileParentDirectoryCallback);
  //   return subscription.unsubscribe;
  // }, [subscribeFileMoved, updateFileParentDirectoryCallback]);
  // const updateFileCallback = useCallback(
  //   (params: { directoryId: string; targetId: string; name: string }) => {
  //     renameFile(params.directoryId, params.targetId, params.name);
  //   },
  //   [renameFile]
  // );
  //
  // useEffect(() => {
  //   const subscription = subscribeFileRenamed!(updateFileCallback);
  //   return subscription.unsubscribe;
  // }, [subscribeFileRenamed, updateFileCallback]);
  //
  const updateDirectoryParentDirectoryCallback = useCallback(
    (params: { targetId: string; fromDirectoryId: string; toDirectoryId: string }) => {
      moveDirectory(params.targetId, params.fromDirectoryId, params.toDirectoryId);
    },
    [moveDirectory]
  );

  useEffect(() => {
    const subscription = subscribeDirectoryMoved!(updateDirectoryParentDirectoryCallback);
    return subscription.unsubscribe;
  }, [subscribeDirectoryMoved, updateDirectoryParentDirectoryCallback]);

  const deleteDirectoryCallback = useCallback(
    (params: { directoryId: string; targetId: string }) => {
      removeDirectory(params.directoryId, params.targetId);
    },
    [removeDirectory]
  );

  useEffect(() => {
    const subscription = subscribeDirectoryDeleted!(deleteDirectoryCallback);
    return subscription.unsubscribe;
  }, [subscribeDirectoryDeleted, deleteDirectoryCallback]);

  const updateDirectoryCallback = useCallback(
    (params: { directoryId: string; targetId: string; name: string }) => {
      renameDirectory(params.directoryId, params.targetId, params.name);
    },
    [renameDirectory]
  );

  useEffect(() => {
    const subscription = subscribeDirectoryRenamed!(updateDirectoryCallback);
    return subscription.unsubscribe;
  }, [subscribeDirectoryRenamed, updateDirectoryCallback]);

  const createDirectoryCallback = useCallback(
    (params: { directoryId: string; directory: IDirectoryDTO }) => {
      const { directory } = params;
      addDirectories(directory.parentDirectoryId, [directory]);
    },
    [addDirectories]
  );

  useEffect(() => {
    const subscription = subscribeDirectoryCreated!(createDirectoryCallback);
    return subscription.unsubscribe;
  }, [subscribeDirectoryCreated, createDirectoryCallback]);

  const toggleDirectory = (id: string) => {
    if (isOpened(id)) {
      closeDirectory(id);
    } else {
      if (isInitializeDirectory(id)) {
        openDirectory(id);
      } else {
        initializeChildren(id);
        openDirectory(id);
      }
    }
  };

  const initializeChildren = async (id: string) => {
    const directory = await directoryUseCase.getDirectory(id);
    initializeDirectory(id, directory.directories || []);
  };

  const initializeRootDirectory = useCallback(() => {
    directoryUseCase.getRootDirectory().then((r) => {
      setRoot(r);
    });
  }, [directoryUseCase]);

  const initializeDirectory = (targetDirectoryId: string, directories: IDirectoryDTO[]) => {
    if (root == null) {
      throw new Error("");
    }

    const targetDirectory = searchDirectoryNode(root, targetDirectoryId);

    if (targetDirectory == null) {
      return;
    }

    targetDirectory.directories = directories;
    targetDirectory.isInitialized = true;
    const updated = R.clone(root);
    setRoot(updated);
  };

  const isOpened = (directoryId: string) => {
    if (root == null) {
      throw new Error("");
    }

    const targetDirectory = searchDirectoryNode(root, directoryId);

    return targetDirectory?.isOpen || false;
  };

  const openDirectory = (directoryId: string) => {
    if (root == null) {
      throw new Error("");
    }

    const targetDirectory = searchDirectoryNode(root, directoryId);

    if (targetDirectory == null) {
      return;
    }

    targetDirectory.isOpen = true;
    const updated = R.clone(root);
    setRoot(updated);
  };

  const closeDirectory = (directoryId: string) => {
    if (root == null) {
      throw new Error("");
    }

    const targetDirectory = searchDirectoryNode(root, directoryId);

    if (targetDirectory == null) {
      return;
    }

    targetDirectory.isOpen = false;
    const updated = R.clone(root);
    setRoot(updated);
  };

  const isInitializeDirectory = (directoryId: string) => {
    if (root == null) {
      throw new Error("");
    }

    const targetDirectory = searchDirectoryNode(root, directoryId);
    return targetDirectory?.isInitialized;
  };

  return {
    root,
    toggleDirectory,
    initializeRootDirectory,
  };
};

const searchDirectoryNode = (element: IDirectoryDTO, directoryId: string): IDirectoryDTO | null => {
  if (element.id === directoryId) {
    return element;
  }

  for (const child of element.directories || []) {
    const res = searchDirectoryNode(child, directoryId);
    if (res) {
      return res;
    }
  }

  return null;
};

export default useFileTree;
