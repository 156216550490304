import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import api from "../../../../api";
import { Header, Input, Label, Form, PageBody, PageHeader, PageWrapper } from "../Commons";
import { GOOGLE_CLIENT_ID } from "./Config";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import GoogleIcon from "../../../../assets/images/google-logo.png";
import AuthenticationUseCase from "../../../../UseCases/Authentication";
import { PopupContext } from "../../../Contexts/PopupContext";
import CheckBox from "../CheckBox";
import { privacyAgreement, usageAgreement } from "./Agreements";

export const EMAIL_REGEX = /[a-z0-9!#$%&.'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*.+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/; // /^[0-9a-zA-Z]([-_/.]?[0-9a-zA-Z])*@[-_/.]?[0-9a-zA-Z].[a-zA-Z]{2,3}$/i;
export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\-[\]\\|,.<>/?'";:`~])[A-Za-z\d!@#$%^&*()_+={}\-[\]\\|,.<>/?'";:`~]{8,}$/;
// 8글자 이상, 영문자 ,숫자 및 특수문자

export interface IGoogleSignUpInfo {
  googleUserId?: string;
  googleOAuthToken?: string;
  email?: string;
  name?: string;
}

export const googleSignUpInfoForRedirect: IGoogleSignUpInfo = {
  googleUserId: undefined,
  googleOAuthToken: undefined,
  email: undefined,
  name: undefined,
};

const SignUpPage = () => {
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [googleUserId, setGoogleUserId] = useState<string>();
  const [googleOAuthToken, setGoogleOAuthToken] = useState<string>();
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    setEmail(googleSignUpInfoForRedirect.email || "");
    setName(googleSignUpInfoForRedirect.name || "");
    setGoogleOAuthToken(googleSignUpInfoForRedirect.googleOAuthToken);
    setGoogleUserId(googleSignUpInfoForRedirect.googleUserId);

    return () => {
      googleSignUpInfoForRedirect.googleUserId = undefined;
      googleSignUpInfoForRedirect.email = undefined;
      googleSignUpInfoForRedirect.name = undefined;
    };
  }, []);

  const signUp = () => {
    if (!EMAIL_REGEX.test(email)) {
      alert("이메일 형식을 확인해주세요");
      return;
    }

    if (!PASSWORD_REGEX.test(password)) {
      alert("영문, 숫자, 특수문자 포함 8자리 이상의 패스워드를 적어주세요");
      return;
    }

    if (!agree) {
      alert("약관에 동의해주세요");
      return;
    }

    new AuthenticationUseCase()
      .signUp({ email, password, name })
      .then(() => {
        history.push("/authentication/signUp/checkEmail");
      })
      .catch((e) => {
        if (e.response.status === 400) {
          api.checkEmailExist(email).then((response) => {
            const isExistEmail = response.data;
            if (isExistEmail) {
              alert("이미 가입한 이메일입니다");
            } else {
              alert("회원가입에 실패하였습니다");
            }
          });
        } else {
          alert("회원가입에 실패하였습니다");
        }
      });
  };

  const googleSignUp = () => {
    if (googleUserId == null) {
      return;
    }

    if (!agree) {
      alert("약관에 동의해주세요");
      return;
    }

    new AuthenticationUseCase()
      .googleSignUp({ name, email, googleUserId })
      .then(() => {
        new AuthenticationUseCase().googleSignIn(googleOAuthToken!).then(() => {
          history.push("/authentication/signUp/done");
        });
      })
      .catch((e) => {
        if (e.response.status === 400) {
          api.checkEmailExist(email).then((response) => {
            const isExistEmail = response.data;
            if (isExistEmail) {
              alert("이미 가입한 이메일입니다");
            } else {
              alert("회원가입에 실패하였습니다");
            }
          });
        } else {
          alert("회원가입에 실패하였습니다");
        }
      });
  };

  const updateGoogleSignUp = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ("tokenId" in response) {
      const { tokenId, profileObj, googleId } = response;

      const email = profileObj.email;
      const name = profileObj.name;

      try {
        const response = await api.checkEmailExist(email);
        const isExistEmail = response.data;
        if (isExistEmail) {
          alert("이미 가입한 이메일 입니다");
          new AuthenticationUseCase().googleSignIn(tokenId).then(() => {
            history.push("/directory");
          });
          return;
        }

        setGoogleOAuthToken(tokenId);
        setGoogleUserId(googleId);
        setEmail(email);
        setName(name);
      } catch (e) {
        if (e.response.status === 404) {
          setGoogleOAuthToken(tokenId);
          setGoogleUserId(googleId);
          setEmail(email);
          setName(name);
        }
      }
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <Header>회원가입</Header>
      </PageHeader>
      <PageBody>
        <FormWrapper>
          <Form>
            <Label>이름 (상호명)</Label>
            <Input value={name} placeholder={"홍길동"} onChange={(e) => setName(e.currentTarget.value)} />
          </Form>
          <Form>
            <Label>이메일</Label>
            <Input value={email} placeholder={"example@email.com"} onChange={(e) => setEmail(e.currentTarget.value)} />
          </Form>
          {!googleUserId && (
            <Form>
              <Label>비밀번호</Label>
              <Input
                value={password}
                placeholder={"password"}
                type={"password"}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </Form>
          )}

          <AgreementWrapper>
            <Agreement
              checked={agree}
              toggleCheck={() => {
                setAgree(!agree);
              }}
            />
          </AgreementWrapper>

          {!googleUserId && (
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              render={(renderProps) => (
                <GoogleSignInButton onClick={renderProps.onClick}>
                  <GoogleSignInIcon src={GoogleIcon} />
                  <GoogleSignInButtonText>구글 계정으로 회원가입하기</GoogleSignInButtonText>
                </GoogleSignInButton>
              )}
              accessType={"online"}
              onSuccess={updateGoogleSignUp}
              cookiePolicy={"single_host_origin"}
            />
          )}
          <SignInButton onClick={googleUserId ? googleSignUp : signUp} disabled={false}>
            회원가입
          </SignInButton>
        </FormWrapper>
      </PageBody>
    </PageWrapper>
  );
};

const Agreement = (props: { checked: boolean; toggleCheck: () => void }) => {
  const { showPopup, closePopup } = useContext(PopupContext);

  return (
    <StyledAgreement>
      <CheckBoxWrapper>
        <CheckBox checked={props.checked} onChange={props.toggleCheck} />
      </CheckBoxWrapper>
      <AgreementCommentWrapper>
        <StyledAgreementPopupLink
          onClick={() => {
            showPopup!(<AgreementPopup onConfirm={closePopup!} />);
          }}
        >
          이용약관 및 개인정보 취급방침
        </StyledAgreementPopupLink>
        에 동의합니다.
      </AgreementCommentWrapper>
    </StyledAgreement>
  );
};

const AgreementPopup = (props: { onConfirm: () => void }) => {
  return (
    <StyledPopupOverlay>
      <StyledAgreementPopup>
        <StyledAgreementPopupTitle>이용약관</StyledAgreementPopupTitle>
        <StyledAgreementPopupBody>{usageAgreement}</StyledAgreementPopupBody>
        <StyledAgreementPopupTitle>개인정보 취급방침</StyledAgreementPopupTitle>
        <StyledAgreementPopupBody>{privacyAgreement}</StyledAgreementPopupBody>
        <AgreementConfirmButtonWrapper>
          <StyledAgreementConfirmButton onClick={props.onConfirm}>확인</StyledAgreementConfirmButton>
        </AgreementConfirmButtonWrapper>
      </StyledAgreementPopup>
    </StyledPopupOverlay>
  );
};

const StyledAgreementPopupTitle = styled.div`
  font-family: NanumSquareB;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #33343d;
`;

const StyledAgreementPopupBody = styled.div`
  padding: 14px 15px 3px 16px;
  box-sizing: border-box;
  overflow-y: scroll;
  border-radius: 8px;
  border: solid 1px #e3e5ee;
  background-color: #f7f7f7;
  white-space: pre-line;

  font-family: NanumSquareR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  color: #72757b;
`;

const StyledAgreementConfirmButton = styled.div`
  box-sizing: border-box;
  padding: 14px 21px 11px;
  border-radius: 10px;
  background-color: #657eff;

  font-family: NanumSquareB;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

const AgreementConfirmButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const StyledAgreementPopup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 21px 25px 19px;
  box-sizing: border-box;
  border-radius: 8px;
  -webkit-backdrop-filter: blur(18px);
  backdrop-filter: blur(18px);
  max-width: 581px;
  background-color: #ffffff;

  ${StyledAgreementPopupTitle} {
    margin-bottom: 10px;
  }
  ${StyledAgreementPopupBody} {
    height: 207px;
  }

  ${StyledAgreementPopupBody} {
    margin-bottom: 40px;
  }

  ${AgreementConfirmButtonWrapper} {
    margin-top: 21px;
  }
`;

const StyledPopupOverlay = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignInButton = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  padding: 17px 16px 17px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? "#acacac" : "#657eff")};
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
`;

const GoogleSignInIcon = styled.img`
  width: 19px;
  height: 18px;
`;

const GoogleSignInButtonText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const GoogleSignInButton = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  padding: 17px 16px 17px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #e34d48;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  ${GoogleSignInIcon} {
    padding-right: 11px;
    border-right: 1px solid #ffffff;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledAgreementPopupLink = styled.span`
  color: #657eff;
  cursor: pointer;
`;

const AgreementCommentWrapper = styled.span`
  font-family: NanumSquareR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #33343d;
`;

const StyledAgreement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${CheckBoxWrapper} {
    margin-right: 10px;
  }
`;

const AgreementWrapper = styled.div``;

const FormWrapper = styled.div`
  width: 343px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Form} {
    width: 100%;
  }

  ${Form} {
    margin-bottom: 9px;
  }

  ${AgreementWrapper} {
    margin-bottom: 37px;
  }

  ${GoogleSignInButton} {
    width: 100%;
    margin-bottom: 20px;
  }

  ${SignInButton} {
    width: 100%;
    margin-bottom: 43px;
  }
`;

export default SignUpPage;
