// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import useSearchStore from "../../Stores/Search";
import { useHistory } from "react-router-dom";

export const generateSearchUrl = (searchValue: string, directoryIds: string[], fileIds: string[]) => {
  const path = `/search?value=${searchValue}`;
  const directoryIdParams = directoryIds.map((id) => `directoryIds=${id}`);
  const fileIdParams = fileIds.map((id) => `fileIds=${id}`);

  const scopes = [...directoryIdParams, fileIdParams].join("&");

  return path + (scopes.length > 0 ? `&${scopes}` : "");
};

const useSearch = () => {
  const { searchValue, setSearchValue, searchResults, setSearchResults } = useSearchStore();
  const history = useHistory();

  const search = (directoryIds?: string[], fileIds?: string[]) => {
    const searchUrl = generateSearchUrl(searchValue, directoryIds || [], fileIds || []);
    history.push(searchUrl);
  };

  return {
    searchValue,
    setSearchValue,
    searchResults,
    setSearchResults,
    search,
  };
};

export default useSearch;
