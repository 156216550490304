import api from "../../../api";
import { ISplitRequestInfo, ISplitVideoDriver } from "../Usecases/SplitVideoUseCase";

class SplitVideoDriver implements ISplitVideoDriver {
  public requestSplit = async (info: ISplitRequestInfo) => {
    const { data } = await api.requestSourceSplit(info);
    return data;
  };
  public getSplit = async (requestId: string) => {
    const { data } = await api.getSourceSplit(requestId);
    return data;
  };
  public downloadSplit = async (requestId: string) => {
    const { data } = await api.downloadSourceSplit(requestId);
    return data;
  };
}

export default SplitVideoDriver;
