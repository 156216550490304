import * as React from "react";
import { ReactElement, useState } from "react";
import styled from "styled-components";

const SideBarItem = (props: {
  icon: string;
  hoveredIcon?: string;
  toggled?: boolean;
  text: string | ReactElement;
  onClick: () => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { icon, text, hoveredIcon, onClick, toggled } = props;

  const onMouseOver = () => {
    setIsHovered(true);
  };

  const onMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <SideBarItemWrapper
      hovered={toggled || isHovered}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={onClick}
    >
      <SideBarIcon src={isHovered && hoveredIcon ? hoveredIcon : icon} />
      <SideBarText>{text}</SideBarText>
    </SideBarItemWrapper>
  );
};

export default SideBarItem;

const SideBarIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const SideBarText = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: #33343d;
  font-family: NanumSquareB;
`;

const SideBarItemWrapper = styled.span<{
  hovered: boolean;
}>`
  width: 100%;
  padding: 7px 11px 8px 7px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${SideBarIcon} {
    margin-right: 10px;
  }
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) => (props.hovered ? "#e8ecff" : "#ffffff")};
`;
