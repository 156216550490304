import { IAnalysisHandler, IAnalysisUseCase } from "../../Interfaces";
import AnalysisDriver from "../../../Drivers/Analysis";

export interface IAnalysisDriver {
  analysis: (target: string, analysisHandlers?: IAnalysisHandler) => void;
  checkAnalysis: (target: string) => Promise<{ done: boolean }>;
}

class AnalysisUseCase implements IAnalysisUseCase {
  private analysisDriver: IAnalysisDriver;
  constructor() {
    this.analysisDriver = new AnalysisDriver();
  }

  public analysis = (target: string, analysisHandlers?: IAnalysisHandler) => {
    this.analysisDriver.analysis(target, analysisHandlers);
  };

  public checkAnalysis = async (fileId: string) => {
    return await this.analysisDriver.checkAnalysis(fileId);
  };
}

export default AnalysisUseCase;
