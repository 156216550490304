import * as React from "react";
import { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { ISearchResult } from "../../../../Models/Interfaces";
import styled from "styled-components";
import OpenIcon from "../../../../assets/images/icn-arrow-open-search.svg";
import CloseIcon from "../../../../assets/images/icn-arrow-close-search.svg";
import { toFormattedTimeCode } from "../../../../utils/utils";
import { openProgram } from "../../MainTemplate";
import { FileUseCases } from "../../../../UseCases/Files/File";
import PlayerManager from "../../../../Programs/VideoEditor/VideoPlayer";

const SearchResult = (props: {
  initialOpen?: boolean;
  fileId: string;
  results: ISearchResult[];
  searchValue: string;
}) => {
  const { fileId, results, initialOpen, searchValue } = props;
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState<string | undefined>(undefined);

  useEffect(() => {
    new FileUseCases().getFile(fileId).then((file) => {
      setFileName(file.name);
    });
  }, [fileId]);

  useEffect(() => {
    if (initialOpen) {
      setOpen(true);
    }
  }, [initialOpen]);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <StyledSearchResult>
      <FileInfo onClick={toggle}>
        <Icon src={open ? OpenIcon : CloseIcon} />
        {fileName && <FileName toggled={open}>{fileName}</FileName>}
        <ResultAmount>{results.length} 건</ResultAmount>
      </FileInfo>
      {open && (
        <GridSearchResult>
          {results.map((r, i) => (
            <SearchResultSceneWrapper
              onClick={() => {
                const startTimeInSeconds = Math.max(r.subtitle.start.total_milliseconds / 1000 - 5, 0);
                const endTimeInSeconds = startTimeInSeconds + 15;
                openProgram("videoEditor", r.metadata.fileId, {
                  startTimeInSeconds,
                  endTimeInSeconds,
                });
              }}
            >
              <SearchResultScene
                key={i}
                text={r.subtitle.text}
                time={r.subtitle.start.total_milliseconds}
                fileId={r.metadata.fileId}
                searchValue={searchValue}
              />
            </SearchResultSceneWrapper>
          ))}
        </GridSearchResult>
      )}
    </StyledSearchResult>
  );
};

const SearchResultScene = (props: { time: number; text: string; searchValue: string; fileId: string }) => {
  const { searchValue, fileId, time } = props;
  const [isHovered, setIsHovered] = useState(false);

  const onMouseOverHandler = () => {
    setIsHovered(true);
  };

  const onMouseOutHandler = () => {
    setIsHovered(false);
  };

  return (
    <StyledSearchResultScene hovered={isHovered} onMouseOver={onMouseOverHandler} onMouseOut={onMouseOutHandler}>
      <ThumbnailWithTimeCode key={fileId + time} time={props.time} fileId={fileId} />

      <SearchResultSceneText>{wrapTags(props.text, searchValue, SearchValueText)}</SearchResultSceneText>
    </StyledSearchResultScene>
  );
};

const ThumbnailWithTimeCode = React.memo((props: { time: number; fileId: string }) => {
  const { time, fileId } = props;
  const [videoLink, setVideoLink] = useState<string | undefined>();
  const [playerManager, setPlayerManager] = useState<PlayerManager | undefined>();

  useEffect(() => {
    new FileUseCases().getFile(fileId).then((file) => {
      setVideoLink(file.s3Url);
    });
  }, [fileId, time]);

  useEffect(() => {
    playerManager?.seek(time / 1000);
  }, [playerManager, time]);

  return (
    <ThumbnailWrapper>
      <Thumbnail
        src={videoLink}
        onLoadedMetadata={(e) => {
          setPlayerManager(new PlayerManager(e.currentTarget as HTMLVideoElement));
        }}
      />
      <TimeCode>{toFormattedTimeCode(props.time)}</TimeCode>
    </ThumbnailWrapper>
  );
});

const wrapTags = (text: string, value: string, Wrapper: (props: PropsWithChildren<{}>) => ReactElement) => {
  const regex = new RegExp(value);
  const textArray = text.split(regex);
  const returnArrays: Array<ReactElement | string> = [];

  textArray.forEach((str, idx) => {
    returnArrays.push(str);
    if (idx < textArray.length - 1) {
      returnArrays.push(<Wrapper>{value}</Wrapper>);
    }
  });

  return returnArrays;
};

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const FileName = styled.div<{ toggled: boolean }>`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #33343d;
`;

const ResultAmount = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #657eff;
`;

const FileInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #dcdde3;
  padding: 9px 13px;
  box-sizing: border-box;

  ${Icon} {
    margin-right: 8px;
  }
  ${FileName} {
    margin-right: 10px;
  }
`;

const Thumbnail = styled.video`
  width: 240px;
  height: 135px;
  background-color: rgba(0, 0, 0, 0.3);
  //
`;

const TimeCode = styled.div`
  padding: 4px 5px;
  box-sizing: border-box;
  opacity: 0.75;
  border-radius: 2px;
  background-color: #000000;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  ${TimeCode} {
    position: absolute;
    left: 5px;
    bottom: 5px;
  }
`;

const SearchResultSceneText = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: -0.2px;
  color: #72757b;
`;

const SearchValueText = styled.span`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #657eff;
`;

const SearchResultSceneWrapper = styled.div`
  position: relative;
`;

const StyledSearchResultScene = styled.div<{ hovered: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 9px 10px;
  box-sizing: border-box;
  border-radius: 4px;
  position: ${(props) => (props.hovered ? "absolute" : "relative")};
  box-shadow: ${(props) => (props.hovered ? "0 0 16px 0 rgba(0, 0, 0, 0.2)" : undefined)};
  background-color: ${(props) => (props.hovered ? " #ffffff" : "transparent")};
  cursor: pointer;

  ${ThumbnailWrapper} {
    margin-right: 19px;
  }
`;

const GridSearchResult = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 100px;
  grid-row-gap: 10px;
  grid-auto-rows: minmax(135px, auto);
  padding: 14px 45px;
  box-sizing: border-box;
  background-color: #f1f1f4;
`;

const StyledSearchResult = styled.div`
  width: 100%;
`;

export default SearchResult;
