import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AuthenticationContext } from "../Contexts/AuthenticationContext";

interface IPrivateRoute extends RouteProps {}

const PrivateRoute = (props: IPrivateRoute) => {
  const { isLogin } = useContext(AuthenticationContext);
  // Add your own authentication on the below line.

  if (props.render) {
    return (
      <Route
        children={props.children}
        strict={props.strict}
        sensitive={props.sensitive}
        path={props.path}
        exact={props.exact}
        location={props.location}
        render={(routeProps) =>
          isLogin ? props.render!(routeProps) : <Redirect to={{ pathname: "/authentication" }} />
        }
      />
    );
  }
  return (
    <Route
      children={props.children}
      strict={props.strict}
      sensitive={props.sensitive}
      path={props.path}
      exact={props.exact}
      location={props.location}
      component={isLogin ? props.component : () => <Redirect to={{ pathname: "/authentication" }} />}
    />
  );
};

export default PrivateRoute;
