import Axios from "axios";

export const toFormattedLocaleDateAndTimeString = (dateTime: string) => {
  return new Date(dateTime).toLocaleDateString() + " " + new Date(dateTime).toLocaleTimeString();
};

export const toTwoLengthStr = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
};

export const toFormattedSize = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
  return Math.round(bytes / Math.pow(1024, i)) + sizes[i];
};

export const toFormattedTimeCode = (timeInMilliSeconds: number) => {
  const timeInSeconds = timeInMilliSeconds / 1000;
  let timeTmp = timeInSeconds;
  const hours = Math.floor(timeInSeconds / 3600);
  timeTmp -= hours * 3600;
  const minutes = Math.floor(timeTmp / 60);
  timeTmp -= minutes * 60;
  const seconds = Math.ceil(timeTmp);
  return `${toTwoLengthStr(hours)} : ${toTwoLengthStr(minutes)} : ${toTwoLengthStr(seconds)}`;
};

export const sleep = (ms: number): Promise<() => void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const createUUID = () => {
  const s4 = () => {
    return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
  };
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
};

export const toS3Path = (s3Url: string): string => {
  const bucket = s3Url.split("/")[2].split(".")[0];
  const urlSplit = s3Url.split("/");
  const key = s3Url.split("/").slice(3, urlSplit.length).join("/");
  return `s3://${bucket}/${key}`;
};

export const createBlob = (name: string, text: string): Blob => {
  return new Blob([text], { type: "text/plain;charset=utf-8" });
};

export const downloadFileFromLink = async (url: string, name: string) => {
  await Axios({ url, method: "GET", responseType: "blob" }).then((response) => {
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name}`);
    document.body.appendChild(link);
    link.click();
    link.remove(); //afterwards we remove the element again
  });
};

export const downloadFile = (blob: Blob, name: string) => {
  const url = window.URL.createObjectURL(blob);
  downloadFileFromLink(url, name);
};

export const getFileContents = async (url: string) => {
  return fetch(url).then((r) => r.blob());
};

export const isMP4File = (fileName: string) => {
  const ext = fileName.split(".").pop();
  return ext != null && ext.toLowerCase() === "mp4";
};
