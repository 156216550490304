import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import FileIcon from "../../../../assets/images/file-20-px-blue.svg";
import LoadingFileIcon from "../../../../assets/images/loading-24-px-light-blue.svg";
import UploadDoneIcon from "../../../../assets/images/done-24-px.svg";
import { IVideoFilePipelineProcess } from "../../../Contexts/FileManagerContexts/CreateFileManagerContext";

const ProgressFileItem = (props: { progress: IVideoFilePipelineProcess; removeFile: (processId: number) => void }) => {
  const { progress, removeFile } = props;
  const [isHovered, setIsHovered] = useState(false);

  const onMouseOverHandler = () => {
    setIsHovered(true);
  };

  const onMouseOutHandler = () => {
    setIsHovered(false);
  };

  // const onCancelUpload = () => {
  //   if (progress.cancelUpload) {
  //     progress.cancelUpload();
  //   }
  // };

  const onRemoveFile = () => {
    if (removeFile) {
      removeFile(progress.processId);
    }
  };

  const isUploadProgressing = progress.isUploading;
  const isUploadDone = progress.isUploaded;
  const isUploadCanceled = progress.isUploadError;
  const isAnalyzing = progress.isAnalyzing;

  const isAudioAnalyzing = progress.isAudioExtracting || progress.isAudioWaveExtracting;

  const isAnalyzeFinished = progress.isAnalyzed;

  return (
    <StyledProgressFileItem onMouseOver={onMouseOverHandler} onMouseOut={onMouseOutHandler}>
      <ProgressFileIcon src={isUploadDone ? FileIcon : LoadingFileIcon} />
      <ProgressFileName>{progress.name}</ProgressFileName>
      {/*{isUploadProgressing && isHovered && (*/}
      {/*  <ProgressFileStatus style={{ color: "#ff3a13", cursor: "pointer" }} onClick={onCancelUpload}>*/}
      {/*    취소*/}
      {/*  </ProgressFileStatus>*/}
      {/*)}*/}

      {isUploadProgressing && <ProgressFileStatus style={{ color: "#657eff" }}>업로드 중</ProgressFileStatus>}
      {isUploadCanceled && (
        <ProgressFileStatus style={{ color: "#657eff" }} onClick={onRemoveFile}>
          취소 됨
        </ProgressFileStatus>
      )}
      {isAnalyzeFinished && !isHovered && <ProgressFileStatusIcon src={UploadDoneIcon} />}
      {isAnalyzeFinished && isHovered && (
        <ProgressFileStatus style={{ color: "#ff3a13", cursor: "pointer" }} onClick={onRemoveFile}>
          삭제
        </ProgressFileStatus>
      )}

      {(isAnalyzing || isAudioAnalyzing) && (
        <ProgressFileStatus style={{ color: "#c3c5ca" }}>
          {isAnalyzing ? "분석 중 ..." : "오디오 분석중...."}
        </ProgressFileStatus>
      )}

      {progress.uploadProgress != null && (
        <ProgressBarWrapper>
          <ProgressBar
            progress={
              progress.isUploading && progress.file?.size ? (progress.uploadProgress / progress.file.size) * 100 : 100
            }
          />
        </ProgressBarWrapper>
      )}
    </StyledProgressFileItem>
  );
};

const ProgressFileIcon = styled.img`
  height: 24px;
  flex: 0 0 24px;
`;

const ProgressFileName = styled.div`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProgressFileStatus = styled.div`
  white-space: nowrap;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;
const ProgressFileStatusIcon = styled.img``;

const ProgressBarWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #dcdde3;
`;
const ProgressBar = styled.div<{ progress: number }>`
  width: ${(props) => `${props.progress}%`};
  height: 100%;
  background-color: #657eff;
`;

const StyledProgressFileItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #dcdde3;
  padding: 15px;
  box-sizing: border-box;
  align-items: center;

  ${ProgressFileIcon} {
    margin-right: 11px;
  }

  ${ProgressFileStatus} {
    margin-left: 50px;
  }

  ${ProgressFileStatusIcon} {
    margin-left: 50px;
  }
`;

export default ProgressFileItem;
