// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { useState } from "react";
import { FileNodeType } from "../../Models/Interfaces";

export interface IFileSelection {
  type: FileNodeType;
  id: string;
}

const useFileSelection = () => {
  const [selections, setSelections] = useState<IFileSelection[]>([]);

  const select = (type: FileNodeType, id: string) => {
    setSelections([{ type, id }]);
  };

  const unSelect = (id: string) => {
    setSelections(selections.filter((selection) => selection.id !== id));
  };

  const addMultiSelections = (fileSelections: IFileSelection[]) => {
    setSelections([...selections, ...fileSelections]);
  };

  const clearSelections = () => {
    setSelections([]);
  };

  const isSelected = (id: string) => {
    return selections.map((s) => s.id).includes(id);
  };

  return {
    selections,
    select,
    unSelect,
    addMultiSelections,
    isSelected,
    clearSelections,
  };
};

export default useFileSelection;
