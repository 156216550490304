import * as React from "react";
import { useState } from "react";
import styled from "styled-components";

const FileNodeItem = (props: {
  icon: string;
  hoveredIcon?: string;
  text: string;
  depth: number;
  selected?: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
  onIconClick: () => void;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { icon, text, depth, hoveredIcon, onClick, onIconClick, onDoubleClick, selected } = props;

  const onMouseOverHandler = () => {
    setIsHovered(true);
  };

  const onMouseOutHandler = () => {
    setIsHovered(false);
  };

  const targetIcon = isHovered && hoveredIcon ? hoveredIcon : icon;

  const onIconClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onIconClick();
  };

  return (
    <StyledFileNodeItem
      depth={depth}
      isHovered={isHovered}
      onMouseOver={onMouseOverHandler}
      onMouseOut={onMouseOutHandler}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      selected={selected}
    >
      <StyledFileNodeIcon src={targetIcon} onClick={onIconClickHandler} />
      <StyledFileNodeText>{text}</StyledFileNodeText>
    </StyledFileNodeItem>
  );
};

const StyledFileNodeIcon = styled.img`
  width: 12px;
  height: 12px;
  padding: 8px;
`;

const StyledFileNodeText = styled.span`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #33343d;
`;

const StyledFileNodeItem = styled.span<{
  depth: number;
  isHovered: boolean;
  selected?: boolean;
}>`
  width: 100%;
  padding: 7px 11px 8px ${(props) => props.depth * 20 + 17}px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  ${StyledFileNodeIcon} {
    //margin-right: 8px;
  }

  ${StyledFileNodeText} {
    flex: 1;
  }

  background-color: ${(props) => (props.isHovered || props.selected ? "#e8ecff" : "#ffffff")};
  color: ${(props) => (props.isHovered || props.selected ? "#334dd7" : "#000000")};
`;
//
// const MiddleEllipsisText = (props: { text: string }) => {
//   const { text } = props;
//
//   const middlePoint = Math.ceil(text.length / 2);
//
//   const before = text.substring(0, middlePoint);
//   const after = text.substring(middlePoint);
//
//   return (
//     <span style={{ display: "flex", width: "200px" }}>
//       <span
//         style={{
//           flex: "1 1 auto",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//           whiteSpace: "pre",
//         }}
//       >
//         {before}
//       </span>
//       <span
//         style={{
//           flex: "1 1 auto",
//           overflow: "hidden",
//           textOverflow: "ellipsis",
//           whiteSpace: "pre",
//         }}
//       >
//         {after}
//       </span>
//     </span>
//   );
// };

export default FileNodeItem;
