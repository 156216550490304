import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parseQuery } from "../MainView";
import SearchBarRow from "../MainView/SearchBarRow";
import styled from "styled-components";
import { ISearchResult } from "../../../../Models/Interfaces";
import SearchResultsSortByFile from "./SearchResultsSortByFile";
import SearchUseCase from "../../../../UseCases/Search";
import { DirectoryUseCases } from "../../../../UseCases/Directory/Directory";

const SearchView = (props: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  searchResults: ISearchResult[];
  setSearchResults: (results: ISearchResult[]) => void;
  doSearch: (scope?: string[]) => void;
}) => {
  const history = useHistory();
  const location = useLocation();
  const search = location.search;
  const parsedQuery: Array<[string, string]> = parseQuery(search);
  const searchValueParams =
    parsedQuery.filter((pq) => pq[0] === "value")[0] != null
      ? decodeURIComponent(parsedQuery.filter((pq) => pq[0] === "value")[0][1])
      : null;

  const directoryIds = parsedQuery
    .filter((pq) => pq[0] === "directoryIds")
    .map((pq) => pq[1])
    .join(",");
  const fileIds = parsedQuery
    .filter((pq) => pq[0] === "fileIds")
    .map((pq) => pq[1])
    .join(",");

  const { searchValue, setSearchResults, searchResults, setSearchValue, doSearch } = props;

  const [isSearching] = useState(false);

  useEffect(() => {
    if (searchValueParams != null) {
      setSearchValue(searchValueParams);
    }
  }, [searchValueParams, setSearchValue]);

  useEffect(() => {
    if (searchValueParams) {
      const searchInAllRanges = directoryIds.trim().length === 0 && fileIds.trim().length === 0;

      if (searchInAllRanges) {
        new DirectoryUseCases().getRootDirectory().then((r) => {
          new DirectoryUseCases().askDirectoryFullPath(r.id).then((allDirectories) => {
            new SearchUseCase().getSearchResult(searchValueParams, allDirectories, []).then((result) => {
              setSearchResults(result);
            });
          });
        });
      } else {
        const targetDirectoryIds = directoryIds.trim().length === 0 ? [] : directoryIds.split(",");

        if (targetDirectoryIds == null || targetDirectoryIds.length === 0) {
          new SearchUseCase()
            .getSearchResult(searchValueParams, [], fileIds.trim().length === 0 ? [] : fileIds.split(","))
            .then((result) => {
              setSearchResults(result);
            });
        } else {
          // support only one select directory search yet
          targetDirectoryIds.forEach((di) => {
            new DirectoryUseCases().askDirectoryFullPath(di).then((allDirectories) => {
              new SearchUseCase()
                .getSearchResult(
                  searchValueParams,
                  allDirectories,
                  fileIds.trim().length === 0 ? [] : fileIds.split(",")
                )
                .then((result) => {
                  setSearchResults(result);
                });
            });
          });
        }
      }
    }
  }, [directoryIds, fileIds, searchValue, searchValueParams, setSearchResults]);

  const onClick = () => {
    if (isSearching) {
      doSearch();
    } else {
      history.push("/directory");
    }
  };

  const onSearch = () => {
    if (isSearching) {
      doSearch();
    }
  };

  return (
    <SearchViewWrapper>
      <SearchBarRow
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isSearching={isSearching}
        onClick={onClick}
        onEnter={onSearch}
        onFocus={() => {
          history.push("/directory?search=true");
        }}
      />
      <SearchResultsSortByFile searchResults={searchResults} searchValue={searchValue} />
    </SearchViewWrapper>
  );
};

const SearchViewWrapper = styled.div`
  min-width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export default SearchView;
