import * as React from "react";
import { ReactElement, RefObject } from "react";
import { PopupContext } from "../../../Contexts/PopupContext";
import { ZIndex } from "../ZIndex";

interface IProps {
  show: boolean;
  showComponent?: ReactElement<any>;
  closePopup?: () => void;
  popupStack?: Array<ReactElement<any>>;
}

interface IState {
  isShowUp: boolean;
}

class GlobalPopup extends React.Component<IProps, IState> {
  private ref: RefObject<HTMLDivElement>;
  constructor(props: any) {
    super(props);
    this.state = {
      isShowUp: false,
    };
    this.ref = React.createRef();
  }

  public render() {
    if (this.props.popupStack && this.props.popupStack.length === 0) {
      return null;
    }
    return (
      <div
        ref={this.ref}
        style={{
          zIndex: ZIndex.popupZIndex,
          position: "absolute",
          width: "100%",
          height: "100%",
          // height: document.body.scrollHeight,
          // zIndex: 2000,
          // overflowY: "hidden",
          // backgroundColor: "rgba(0, 0, 0, 0.5)",
          // paddingTop: window.pageYOffset,
          // boxSizing: "border-box",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {this.props.popupStack &&
            this.props.popupStack.map((el, i) => {
              return (
                <div
                  key={i}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: i === this.props.popupStack!!.length - 1 ? undefined : "none",
                  }}
                >
                  {el}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default function PopupContextGlobalPopup() {
  return (
    <PopupContext.Consumer>
      {({ show, showComponent, closePopup, popupStack }) => (
        <GlobalPopup show={show} showComponent={showComponent} closePopup={closePopup} popupStack={popupStack} />
      )}
    </PopupContext.Consumer>
  );
}
