import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from "react";
import * as EventListeners from "../../eventListeners";
import styled from "styled-components";

export interface IPosition {
  x: number;
  y: number;
}

export interface ISize {
  width: number;
  height: number;
}

export const callContextMenu = (position: IPosition, size: ISize, element: ReactElement) => {
  EventListeners.emit("contextMenu", { position, size, element });
};

export const closeContextMenu = () => {
  EventListeners.emit("closeContextMenu");
};

const GlobalContextMenu = () => {
  const [visible, setVisible] = useState(false);
  const [element, setElement] = useState<ReactElement | null>(null);
  const [position, setPosition] = useState<IPosition>();

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    EventListeners.addEventListeners("contextMenu", ({ position, element }) => {
      setElement(element);
      setPosition(position);
      setVisible(true);
    });

    return () => {
      EventListeners.removeEventListeners("contextMenu", ({ position, element }) => {
        setElement(element);
        setPosition(position);
        setVisible(true);
      });
    };
  }, [element, position, visible]);

  useEffect(() => {
    EventListeners.addEventListeners("closeContextMenu", () => {
      setElement(null);
      setPosition(undefined);
      setVisible(false);
    });

    return () => {
      EventListeners.removeEventListeners("closeContextMenu", () => {
        setElement(null);
        setPosition(undefined);
        setVisible(false);
      });
    };
  }, [element, position, visible]);

  if (!visible) {
    return null;
  }

  return (
    <ContextMenuWrapper ref={ref} position={position}>
      <ContextMenu>{element}</ContextMenu>
    </ContextMenuWrapper>
  );
};

const ContextMenu = styled.div``;

const ContextMenuWrapper = styled.div<{ position?: IPosition }>`
  position: absolute;
  left: ${(props) => (props.position?.x != null ? `${props.position.x}px` : undefined)};
  top: ${(props) => (props.position?.y != null ? `${props.position.y}px` : undefined)};
`;

export default GlobalContextMenu;
