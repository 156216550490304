const eventListeners: Array<(key: string, value: any) => void> = [];

export const addEventListener = (func: (key: string, value: any) => void) => {
  eventListeners.push(func);
};

export const setItem = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
  for (const listener of eventListeners) {
    new Promise(() => listener(key, value)).then(() => true).catch(() => false);
  }
};

export const getItem = (key: string): any => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  } else {
    return null;
  }
};

export const removeItem = (key: string): any => {
  localStorage.removeItem(key);
};

const exportModule = {
  setItem,
  getItem,
  addEventListener,
  removeItem,
};

export default exportModule;
