import * as React from "react";
import CreateFileManagerContextProvider, {
  ICreateDirectorySubscribe,
  ICreateFileSubscribe,
} from "./CreateFileManagerContext";
import DeleteFileManagerContextProvider, {
  IDeleteDirectorySubscribe,
  IDeleteFileSubscribe,
} from "./DeleteFileManagerContext";
import UpdateFileManagerContextProvider, {
  IUpdateDirectorySubscribe,
  IUpdateFileSubscribe,
} from "./UpdateFileManagerContext";
import { PropsWithChildren, useContext } from "react";
import { AuthenticationContext } from "../AuthenticationContext";

export interface IFileSubscriptionProps extends ICreateFileSubscribe, IDeleteFileSubscribe, IUpdateFileSubscribe {}

export interface IDirectorySubscriptionProps
  extends ICreateDirectorySubscribe,
    IDeleteDirectorySubscribe,
    IUpdateDirectorySubscribe {}

export type FileSubscriptions = IFileSubscriptionProps & IDirectorySubscriptionProps;

const FileManagerContextProvider = (props: PropsWithChildren<{}>) => {
  const { isLogin } = useContext(AuthenticationContext);
  return (
    <CreateFileManagerContextProvider isLogin={isLogin}>
      <DeleteFileManagerContextProvider>
        <UpdateFileManagerContextProvider>{props.children}</UpdateFileManagerContextProvider>
      </DeleteFileManagerContextProvider>
    </CreateFileManagerContextProvider>
  );
};

export default FileManagerContextProvider;
