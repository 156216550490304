import { FileNodeType, IDirectory, IDirectoryDTO, IFile, IFileDTO } from "./Interfaces";

export class FileModel {
  private file: IFile;
  constructor(file: IFile) {
    this.file = file;
  }

  public toDTO = () => {
    const fileDTO: IFileDTO = {
      id: this.file.id!,
      type: FileNodeType.FILE,
      accountId: this.file.accountId,
      directoryId: this.file.directoryId,
      name: this.file.name,
      createdDateTime: this.file.createdDateTime,
      updatedDateTime: this.file.updatedDateTime,
      size: this.file.size!,
      s3Path: this.file.s3Path,
      s3Url: this.file.s3Url,
      thumbnailS3Path: this.file.thumbnailS3Path,
      thumbnailS3Url: this.file.thumbnailS3Url,
      status: this.file.status!,
      audioExtractionRequestId: this.file.audioExtractionRequestId,
      waveFormRequestId: this.file.waveFormRequestId,
      analysisRequestId: this.file.analysisRequestId,
    };
    return fileDTO;
  };
}

export class DirectoryModel {
  private directory: IDirectory;
  constructor(directory: IDirectory) {
    this.directory = directory;
  }

  public toDTO = () => {
    const directoryDTO: IDirectoryDTO = {
      ...this.directory,
      id: this.directory.id!,
      size: this.directory.size!,
      accountId: this.directory.accountId!,
      deleted: this.directory.deleted!,
      parentDirectoryId: this.directory.parentDirectoryId!,
      type: FileNodeType.DIRECTORY,
      isOpen: false,
      isInitialized: false,
      files: this.directory.files?.map((f) => new FileModel(f).toDTO()),
      directories: this.directory.directories?.map((d) => new DirectoryModel(d).toDTO()),
    };

    return directoryDTO;
  };
}
