import * as React from "react";
import { useEffect, useState } from "react";
import { ITextScript, ITextSource } from "../../../../../Programs/VideoEditor/VideoEditor";
import styled from "styled-components";
import * as R from "ramda";
import PlayerManager from "../../../../../Programs/VideoEditor/VideoPlayer";
import { createBlob, downloadFile } from "../../../../../utils/utils";

const CurrentTextSource = (props: { videoPlayer: PlayerManager; textSource: ITextSource; title: string }) => {
  const { videoPlayer, textSource, title } = props;

  const [focusScript, setFocusScript] = useState<ITextScript>();

  useEffect(() => {
    videoPlayer?.subscribeTime((time: number) => {
      const timeInMilliSeconds = time * 1000;
      const targetScript = textSource.data.scripts
        .filter((script) => script.startTime <= timeInMilliSeconds && script.endTime >= timeInMilliSeconds)
        .pop();

      if (!R.equals(targetScript, focusScript)) {
        setFocusScript(targetScript);
      }
    });
  }, [videoPlayer, focusScript, textSource]);

  const downloadScript = () => {
    const textScripts = textSource.data.scripts
      .map((script) => `${script.startTime / 1000}sec_${script.endTime / 1000}sec${"\n"}${script.data.text}`)
      .join("\n\n\n");
    const blob = createBlob("text", textScripts);
    downloadFile(blob, `${title}.txt`);
  };

  return (
    <StyledCurrentTextSource>
      <Text>{focusScript?.data.text || " "}</Text>
      <DownloadScriptButton onClick={downloadScript}>스크립트 내보내기</DownloadScriptButton>
    </StyledCurrentTextSource>
  );
};

export default CurrentTextSource;
//
// const SearchTargetText = styled.div`
//   color: #657eff;
// `;

const Text = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #72757b;
  white-space: pre;
`;

const DownloadScriptButton = styled.button`
  position: absolute;
  right: 8px;
  bottom: 8px;
  padding: 9px 11px 10px 12px;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  background-color: #657eff;
  color: #ffffff;
  cursor: pointer;
`;

const StyledCurrentTextSource = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
