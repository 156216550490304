import { IUploadDriver } from "../UseCases/Upload";
import { IUploadHandlers } from "../UseCases/Interfaces";
import axios, { CancelTokenSource } from "axios";
import sha256 from "fast-sha256";
import api from "../api";
import { createUUID } from "../utils/utils";

class MultipartUploadDriver implements IUploadDriver {
  private uploadInstance?: MultipartUpload;

  public upload = async (file: File, fileName?: string, uploadHandlers?: IUploadHandlers) => {
    if (uploadHandlers?.onStart) {
      uploadHandlers.onStart();
    }

    this.uploadInstance = new MultipartUpload(
      file,
      fileName || file.name,
      uploadHandlers?.onUploaded,
      uploadHandlers?.onUpload,
      uploadHandlers?.onCancel
    );

    const result = await this.uploadInstance.executeMultipartUpload();
    return { location: result.location };
  };

  public cancelUpload = () => {
    this.uploadInstance?.cancel();
  };
}

interface IScheduler {
  func: () => Promise<void>;
  onDone: () => void;
}

class MultipartUploadScheduler {
  private queue: IScheduler[];
  private isScheduling: boolean;
  constructor() {
    this.queue = [];
    this.isScheduling = false;
  }

  public enqueue = (func: () => Promise<void>, onDone: () => void) => {
    this.queue.push({ func, onDone });
  };

  public dequeue = () => {
    return this.queue.shift();
  };

  public executeScheduler = async (func: () => Promise<void>, onDone: () => void) => {
    this.enqueue(func, onDone);
    if (this.isScheduling) {
      return;
    }

    this.isScheduling = true;
    await this.doScheduler();
  };

  private doScheduler = async () => {
    const target = this.dequeue();

    if (target == null) {
      this.isScheduling = false;
      return;
    }

    await target.func();
    await target.onDone();
    await this.doScheduler();
  };
}

const scheduler = new MultipartUploadScheduler();

class MultipartUpload {
  private file: File;
  private fileName: string;
  private CHUNK_SIZE: number;
  private cancelTokens: { [key: number]: Array<() => void> };
  private onSuccess?: () => void;
  private progressHandler?: (loaded: number, total: number) => void;
  private onCancel?: () => void;
  private partLoaded: number[];
  private api: any;
  private processId: number;

  constructor(
    file: File,
    fileName: string,
    onSuccess?: () => void,
    progressHandler?: (loaded: number, total: number) => void,
    onCancel?: () => void
  ) {
    this.file = file;
    this.fileName = fileName;
    this.onSuccess = onSuccess;
    this.progressHandler = progressHandler;
    this.CHUNK_SIZE = 1024 * 1024 * 50;
    this.cancelTokens = {};
    this.api = api;
    this.processId = 0;
    this.partLoaded = [];
    this.onCancel = onCancel;
  }

  public cancel = () => {
    for (const processId of Object.keys(this.cancelTokens)) {
      this.cancelTokens[Number(processId)].forEach((c) => {
        c();
      });
    }

    if (this.onCancel) {
      this.onCancel();
    }
  };

  public executeMultipartUpload = async () => {
    const { uploadId, key } = await this.createMultipartUpload();
    const { eTags, partNumbers } = await this.uploadParts(uploadId, key);
    const response = await this.completeMultipart(uploadId, key, partNumbers, eTags);

    if (this.onSuccess) {
      this.onSuccess();
    }

    return {
      bucket: response.bucket,
      key: response.key,
      location: response.location,
    };
  };

  private createMultipartUpload = async () => {
    const { api, file } = this;
    const name = createUUID() + "." + file.name.split(".").pop();
    const { data } = await api.createMultipartUpload(name);
    return { uploadId: data.uploadId, key: data.key };
  };

  private uploadParts = async (uploadId: string, key: string) => {
    return new Promise<{ partNumbers: number[]; eTags: string[] }>(async (resolve) => {
      const partNumbers = this.generatePartNumbersArray();
      const eTags: string[] = [];
      this.partLoaded = partNumbers.map(() => {
        return 0;
      });

      const { file, CHUNK_SIZE } = this;
      const chunkCount = Math.ceil(file.size / CHUNK_SIZE);

      const leftIndexes: number[] = [];
      for (let i = 0; i < chunkCount; i += 1) {
        leftIndexes.push(i);
      }

      const cancelToken = axios.CancelToken.source();
      const processId = this.processId++;

      this.addCancelToken(processId, cancelToken);

      await this.enqueueToScheduler(
        async () => await this.uploadPart(leftIndexes, CHUNK_SIZE, uploadId, key, processId, file, eTags),
        () => {
          resolve({ eTags, partNumbers });
        }
      );
    });
  };

  private enqueueToScheduler = async (func: () => Promise<void>, onDone: () => void) => {
    await scheduler.executeScheduler(func, onDone);
  };

  private async uploadPart(
    leftIndexes: number[],
    CHUNK_SIZE: number,
    uploadId: string,
    key: string,
    processId: number,
    file: File,
    eTags: string[]
  ) {
    const i = leftIndexes.pop();
    if (i === undefined) {
      return;
    }

    await this.uploadWorker(key, i, CHUNK_SIZE, file, uploadId, processId, eTags);
    await this.uploadPart(leftIndexes, CHUNK_SIZE, uploadId, key, processId, file, eTags);
  }

  private uploadWorker = async (
    key: string,
    i: number,
    CHUNK_SIZE: number,
    file: File,
    uploadId: string,
    processId: number,
    eTags: any[]
  ) => {
    const startByte = CHUNK_SIZE * i;
    let chunk: Blob | null = file.slice(startByte, startByte + CHUNK_SIZE, file.type);
    // @ts-ignore
    let chunkAsString: number[] | null = await chunk!.arrayBuffer();
    let encrypted: string | null = this.encryptChunk(chunkAsString!!);

    const { data } = await this.api.createUploadPartHeaderValue(encrypted, i + 1, uploadId, key);
    const { bucketName, authorization, date, sha256Checksum } = data;

    const cancelToken = axios.CancelToken.source();
    const { headers } = await this.api.uploadPartToS3(
      bucketName,
      authorization,
      date,
      sha256Checksum,
      this.fileName,
      i + 1,
      uploadId,
      key,
      chunkAsString,
      (e: ProgressEvent) => this.uploadProgressHandler(e, i, cancelToken),
      cancelToken
    );
    eTags[i] = headers.etag;

    // free variable due to memory issue, recursive logic stack memory
    chunk = null;
    chunkAsString = null;
    encrypted = null;
  };

  private generatePartNumbersArray = () => {
    const numbers: number[] = [];
    const chunkCount = Math.ceil(this.file.size / this.CHUNK_SIZE);
    for (let i = 1; i <= chunkCount; i++) {
      numbers.push(i);
    }
    return numbers;
  };

  private encryptChunk = (arrayBuffer: number[]): string => {
    const SHA256 = sha256(new Uint8Array(arrayBuffer));
    return Buffer.from(SHA256).toString("hex");
  };

  private addCancelToken(key: number, cancelToken: CancelTokenSource) {
    const cancelCalls = this.cancelTokens[key] || [];
    cancelCalls.push(() => {
      cancelToken.cancel();
    });
    this.cancelTokens[key] = cancelCalls;
  }

  private uploadProgressHandler = (e: ProgressEvent, index: number, cancelToken: CancelTokenSource) => {
    if (this.progressHandler) {
      this.partLoaded[index] = e.loaded;
      const sum = this.partLoaded.reduce((a, b) => {
        return a + b;
      }, 0);
      try {
        this.progressHandler(sum, this.file.size);
      } catch (e) {
        cancelToken.cancel(e.message);
      }
    }
  };

  private completeMultipart = async (uploadId: string, key: string, partNumbers: number[], eTags: string[]) => {
    const { api } = this;
    const { data } = await api.completeMultipartUpload(key, uploadId, partNumbers, eTags);
    return {
      bucket: data.bucket,
      key: data.key,
      location: data.location,
    };
  };
}

export default MultipartUploadDriver;
