import { ISearchDriver } from "../UseCases/Search";
import { ISearchRepository } from "../Respositories/Interfaces";
import SearchRepository from "../Respositories/Search";

interface ISearchFileParams {
  fileId: string;
}

interface ISearchDirectoryParams {
  directoryId: string;
}

export interface ISearchFilter {
  $or: Array<ISearchFileParams | ISearchDirectoryParams>;
}

class SearchDriver<T> implements ISearchDriver<T> {
  private repository: ISearchRepository<T>;

  constructor() {
    this.repository = new SearchRepository();
  }

  public search = async (value: string, directoryIds: string[], fileIds: string[]) => {
    const filter = convertQueryFilter(directoryIds, fileIds);
    const result = await this.repository.getSearchResult(value, filter);
    return result;
  };
}

const convertQueryFilter = (directoryIds: string[], fileIds: string[]): ISearchFilter => {
  const directories = directoryIds.map((id) => ({ directoryId: id }));
  const files = fileIds.map((id) => ({ fileId: id }));

  return {
    $or: [...directories, ...files],
  };
};

export default SearchDriver;
