import * as React from "react";
import { PropsWithChildren } from "react";
import { IDirectoryDTO } from "../../../Models/Interfaces";
import FileNodeItem from "./FileNodeItem";

import OpenDirectoryIcon from "../../../assets/images/icn-arrow-open.svg";
import FocusedOpenDirectoryIcon from "../../../assets/images/icn-arrow-open-focused.svg";
import ClosedDirectoryIcon from "../../../assets/images/icn-arrow-close.svg";
import FocusedClosedDirectoryIcon from "../../../assets/images/icn-arrow-close-focused.svg";

const DirectoryItem = (
  props: PropsWithChildren<{
    directory: IDirectoryDTO;
    depth: number;
    selected?: boolean;
    onClick: (id: string) => void;
    onDoubleClick: (id: string) => void;
    onIconClick: () => void;
  }>
) => {
  const { directory, depth, selected, onClick, onDoubleClick, onIconClick } = props;
  const targetIcons = props.directory.isOpen
    ? {
        icon: OpenDirectoryIcon,
        hoveredIcon: FocusedOpenDirectoryIcon,
      }
    : {
        icon: ClosedDirectoryIcon,
        hoveredIcon: FocusedClosedDirectoryIcon,
      };

  const onClickHandlers = () => {
    onClick(directory.id);
  };

  const onDoubleClickHandlers = () => {
    onDoubleClick(directory.id);
  };

  return (
    <>
      {
        <FileNodeItem
          onClick={onClickHandlers}
          onDoubleClick={onDoubleClickHandlers}
          onIconClick={onIconClick}
          icon={targetIcons.icon}
          hoveredIcon={targetIcons.hoveredIcon}
          text={directory.name}
          selected={selected}
          depth={depth}
        />
      }
      {directory.isOpen && props.children}
    </>
  );
};

export default DirectoryItem;
