import { IFileUseCase } from "../Interfaces";
import { IFileRepository } from "../../Respositories/Interfaces";
import FileRepository from "../../Respositories/File";
import { IFile } from "../../Models/Interfaces";
import { FileModel } from "../../Models/FileNode";

export class FileUseCases implements IFileUseCase {
  protected repository: IFileRepository;

  constructor() {
    this.repository = new FileRepository();
  }

  public createFile = async (directoryId: string, name: string, s3Path: string, s3Url?: string) => {
    const fileModel: IFile = {
      directoryId,
      name: name,
      s3Path: s3Path,
      s3Url: s3Url,
    };

    const createdFile = await this.repository.createFile(directoryId, fileModel);

    return new FileModel(createdFile).toDTO();
  };

  public getFile = async (id: string) => {
    const file = await this.repository.getFile(id);
    return new FileModel(file).toDTO();
  };

  public deleteFile = async (id: string) => {
    const deletedFile = await this.repository.deleteFile(id);
    return new FileModel(deletedFile).toDTO();
  };

  public renameFile = async (id: string, name: string) => {
    const renamedFile = await this.repository.renameFile(id, name);
    return new FileModel(renamedFile).toDTO();
  };

  public updateParentDirectory = async (targetId: string, directoryId: string) => {
    const movedFile = await this.repository.updateParentDirectory(targetId, directoryId);
    return new FileModel(movedFile).toDTO();
  };

  public copyFile = async (id: string, directoryId: string) => {
    const movedFile = await this.repository.copyFile(id, directoryId);
    return new FileModel(movedFile).toDTO();
  };
}
