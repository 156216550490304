import SplitVideoUseCase, { ISplitVideoHandlers, ISplitVideoUseCase } from "./Usecases/SplitVideoUseCase";

export interface ITextScript {
  type: "TEXT_SCRIPT";
  startTime: number;
  endTime: number;
  data: {
    text: string;
  };
}

export interface IVideoSource {
  key: string;
  type: "VIDEO";
  data: {
    fileId: string;
    src: string;
    durationInMilliSeconds?: number;
    url?: string;
  };
}

export interface IAudioSource {
  key: string;
  type: "AUDIO";
  data: {
    durationInMilliSeconds?: number;
    pps: number;
    wave: number[];
  };
}

export interface ITextSource {
  key: string;
  type: "TEXT";
  data: {
    durationInMilliSeconds?: number;
    scripts: ITextScript[];
  };
}

export type Script = ITextScript;
export type Source = ITextSource | IVideoSource | IAudioSource;

export interface IResult {
  duration: number;
  name: string;
  resultSources: {
    videoResults?: IVideoSource[];
    subtitleResults?: ITextSource[];
    audioResults?: IAudioSource[];
  };
}

export interface IProject {
  sources: Source[];
  result?: IResult;
}

export interface IVideoEditor {
  createNewProject: () => void;
  openProject: (project: IProject, range?: ISelection) => void;
  importSources: (sources: Source[]) => void;
  split: (source: IVideoSource, selection: ISelection) => Promise<Source>;
  subscribeEditorChanges: (func: (project: IProject) => void) => void;
}

export interface ISelection {
  startTimeInMillSeconds: number;
  endTimeInMillSeconds: number;
}

class VideoEditor implements IVideoEditor {
  private project?: IProject;
  private selection: ISelection | null;
  private subscribers: Array<(project: IProject) => void>;
  private splitVideoUsecase: ISplitVideoUseCase;

  constructor() {
    this.subscribers = [];
    this.selection = null;
    this.splitVideoUsecase = new SplitVideoUseCase();
  }

  public createNewProject = () => {
    this.project = { sources: [] };
    this.onChange();
  };

  public openProject = (project: IProject) => {
    this.project = project;
    this.onChange();
  };

  public importSources = (sources: Source[]) => {
    this.project = {
      ...this.project,
      sources: this.project?.sources ? [...this.project.sources, ...sources] : sources,
    };
    this.onChange();
  };

  public split = async (source: IVideoSource, selection: ISelection, handlers?: ISplitVideoHandlers) => {
    const response = await this.splitVideoUsecase.requestSplit(source.data.fileId, selection, handlers);
    return {
      ...source,
      data: {
        ...source.data,
        url: response.outputS3FilePathParams[0].s3Path,
      },
    };
  };
  //
  // private getPartialSource = async (source: Source, selection: ISelection) => {
  //   if (source.type === "VIDEO" || source.type === "AUDIO") {
  //     return { ...source };
  //   }
  //
  //   const isInSelection = (s: {
  //     startTimeInMillSeconds: number;
  //     endTimeInMillSeconds: number;
  //   }) => {
  //     return true;
  //   };
  //
  //   const scripts = source.data.scripts;
  //
  //   return {
  //     ...source,
  //     data: {
  //       ...source.data,
  //       scripts: scripts
  //         ? // @ts-ignore
  //           scripts.filter((s: Script) => isInSelection(s))
  //         : [],
  //     },
  //   };
  // };

  private onChange = () => {
    if (this.project == null) {
      throw new Error("not initialized");
    }

    this.subscribers.forEach((func) => {
      func(this.project!);
    });
  };

  public subscribeEditorChanges = (func: (project: IProject) => void) => {
    this.subscribers.push(func);
    return {
      unsubscribe: () => {
        this.unsubscribeEditorChanges(func);
      },
    };
  };

  private unsubscribeEditorChanges = (func: (project: IProject) => void) => {
    this.subscribers = this.subscribers.filter((f) => f !== func);
  };
}

export default VideoEditor;
