import * as React from "react";
import { useCallback, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PopupContext } from "../../../Contexts/PopupContext";
import { authorizationManager } from "../../../../api";

const LoggedInPopup = (props: { onClose: () => void }) => {
  const { closePopup } = useContext(PopupContext);
  const history = useHistory();

  const loggedInPopup = useRef<HTMLDivElement | null>(null);

  const onCloseHandler = useCallback(
    (e: MouseEvent) => {
      if (loggedInPopup.current != null && !loggedInPopup.current.contains(e.target as Node)) {
        props.onClose();
        closePopup!();
      }
    },
    [props, closePopup]
  );

  useEffect(() => {
    window.addEventListener("click", onCloseHandler);

    return () => {
      window.removeEventListener("click", onCloseHandler);
    };
  }, [onCloseHandler]);

  const goToChangePasswordPage = () => {
    history.push("/authentication/changePassword");
    props.onClose();
    closePopup!();
  };

  return (
    <StyledLoggedInPopupWrapper>
      <LoggedInMenu ref={loggedInPopup}>
        <LoggedInMenuItem onClick={goToChangePasswordPage}>비밀번호 변경</LoggedInMenuItem>
        <LoggedInMenuItem
          onClick={() => {
            authorizationManager.signOut();
            history.push("/authentication");
            if (closePopup) {
              closePopup();
            }
            props.onClose();
          }}
        >
          로그아웃
        </LoggedInMenuItem>
      </LoggedInMenu>
    </StyledLoggedInPopupWrapper>
  );
};

const LoggedInMenuItem = styled.div`
  padding: 11px 64px 11px 13px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: #33343d;
  &:hover {
    background-color: #eff0f2;
  }
`;

const LoggedInMenu = styled.div`
  padding: 5px 0;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
`;

const StyledLoggedInPopupWrapper = styled.div`
  position: absolute;
  left: 240px;
  width: 100%;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export default LoggedInPopup;
