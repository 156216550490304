import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { FileStatus, IDirectoryDTO, IFileDTO } from "../../Models/Interfaces";
import { DirectoryUseCases } from "../../UseCases/Directory/Directory";
import { FileUseCases } from "../../UseCases/Files/File";
import { CreateFileManagerContext } from "../Contexts/FileManagerContexts/CreateFileManagerContext";
import { UpdateFileManagerContext } from "../Contexts/FileManagerContexts/UpdateFileManagerContext";
import { DeleteFileManagerContext } from "../Contexts/FileManagerContexts/DeleteFileManagerContext";

const useCurrentDirectory = (props: { directoryId: string }) => {
  const { directoryId } = props;
  const fileUseCase = React.useMemo(() => new FileUseCases(), []);
  const directoryUseCase = React.useMemo(() => new DirectoryUseCases(), []);

  const [isRoot, setIsRoot] = useState<boolean | undefined>();
  const [parentId, setParentId] = useState<string | undefined>();
  const [files, setFiles] = useState<Array<IFileDTO | IDirectoryDTO>>();

  const { subscribeFileCreated, subscribeDirectoryCreated, subscribeFileAnalyzed } = useContext(
    CreateFileManagerContext
  );
  const { subscribeFileDelete, subscribeDirectoryDeleted } = useContext(DeleteFileManagerContext);
  const { subscribeFileRenamed, subscribeFileMoved, subscribeDirectoryRenamed, subscribeDirectoryMoved } = useContext(
    UpdateFileManagerContext
  );

  const addFiles = useCallback(
    (targets: Array<IFileDTO | IDirectoryDTO>) => {
      if (files == null) {
        throw new Error("");
      }

      setFiles([...files, ...targets]);
    },
    [files]
  );

  const renameFile = useCallback(
    (targetId: string, name: string) => {
      if (files == null) {
        throw new Error("");
      }

      setFiles((prevState) => prevState?.map((f) => (f.id === targetId ? { ...f, name } : f)));
    },
    [files]
  );

  const updateFilStatus = useCallback(
    (targetId: string, status: FileStatus) => {
      if (files == null) {
        throw new Error("");
      }

      setFiles((prevState) => prevState?.map((f) => (f.id === targetId ? { ...f, status } : f)));
    },
    [files]
  );

  const deleteFile = useCallback(
    (targetId: string) => {
      if (files == null) {
        throw new Error("");
      }
      setFiles((prevState) => prevState?.filter((f) => f.id !== targetId));
    },
    [files]
  );

  const createFileCallback = useCallback(
    (params: { directoryId: string; file: IFileDTO }) => {
      if (params.directoryId === directoryId) {
        addFiles([params.file]);
      }
    },
    [addFiles, directoryId]
  );

  useEffect(() => {
    const subscription = subscribeFileCreated!(createFileCallback);
    return subscription.unsubscribe;
  }, [subscribeFileCreated, directoryId, createFileCallback]);

  const renameFileCallback = useCallback(
    (params: { directoryId: string; targetId: string; name: string }) => {
      if (params.directoryId === directoryId) {
        renameFile(params.targetId, params.name);
      }
    },
    [directoryId, renameFile]
  );

  useEffect(() => {
    const subscription = subscribeFileRenamed!(renameFileCallback);
    return subscription.unsubscribe;
  }, [subscribeFileRenamed, directoryId, renameFileCallback]);

  const updateFileStatusAnalyzed = useCallback(
    (params: { processId: number; fileId: string }) => {
      updateFilStatus(params.fileId, FileStatus.ANALYZED);
    },
    [updateFilStatus]
  );

  useEffect(() => {
    const subscription = subscribeFileAnalyzed!(updateFileStatusAnalyzed);
    return subscription.unsubscribe;
  }, [directoryId, updateFileStatusAnalyzed, subscribeFileAnalyzed]);

  const deleteFileCallback = useCallback(
    (params: { directoryId: string; targetId: string }) => {
      if (params.directoryId === directoryId) {
        deleteFile(params.targetId);
      }
    },
    [directoryId, deleteFile]
  );

  useEffect(() => {
    const subscription = subscribeFileDelete!(deleteFileCallback);
    return subscription.unsubscribe;
  }, [subscribeFileDelete, directoryId, deleteFileCallback]);

  const updateFileParentDiretoryCallback = useCallback(
    (params: { targetId: string; fromDirectoryId: string; toDirectoryId: string }) => {
      if (params.fromDirectoryId === directoryId) {
        deleteFile(params.targetId);
      }

      if (params.toDirectoryId === directoryId) {
        fileUseCase.getFile(params.targetId).then((file) => {
          addFiles([file]);
        });
      }
    },
    [directoryId, deleteFile, fileUseCase, addFiles]
  );

  useEffect(() => {
    const subscription = subscribeFileMoved!(updateFileParentDiretoryCallback);
    return subscription.unsubscribe;
  }, [subscribeFileMoved, directoryId, updateFileParentDiretoryCallback]);

  const createDirectoryCallback = useCallback(
    (params: { directoryId: string; directory: IDirectoryDTO }) => {
      if (params.directoryId === directoryId) {
        addFiles([params.directory]);
      }
    },
    [directoryId, addFiles]
  );

  useEffect(() => {
    const subscription = subscribeDirectoryCreated!(createDirectoryCallback);
    return subscription.unsubscribe;
  }, [subscribeDirectoryCreated, directoryId, createDirectoryCallback]);

  const renameDirectoryCallback = useCallback(
    (params: { directoryId: string; targetId: string; name: string }) => {
      if (params.directoryId === directoryId) {
        renameFile(params.targetId, params.name);
      }
    },
    [directoryId, renameFile]
  );

  useEffect(() => {
    const subscription = subscribeDirectoryRenamed!(renameDirectoryCallback);
    return subscription.unsubscribe;
  }, [subscribeDirectoryRenamed, directoryId, renameDirectoryCallback]);

  const deleteDirectoryCallback = useCallback(
    (params: { directoryId: string; targetId: string }) => {
      if (params.directoryId === directoryId) {
        deleteFile(params.targetId);
      }
    },
    [directoryId, deleteFile]
  );

  useEffect(() => {
    const subscription = subscribeDirectoryDeleted!(deleteDirectoryCallback);
    return subscription.unsubscribe;
  }, [subscribeDirectoryDeleted, directoryId, deleteDirectoryCallback]);

  const updateDirectoryParentDiretoryCallback = useCallback(
    (params: { targetId: string; fromDirectoryId: string; toDirectoryId: string }) => {
      if (params.fromDirectoryId === directoryId) {
        deleteFile(params.targetId);
      }

      if (params.toDirectoryId === directoryId) {
        directoryUseCase.getDirectory(params.targetId).then((directory) => {
          addFiles([directory]);
        });
      }
    },
    [directoryId, deleteFile, directoryUseCase, addFiles]
  );

  useEffect(() => {
    const subscription = subscribeDirectoryMoved!(updateDirectoryParentDiretoryCallback);
    return subscription.unsubscribe;
  }, [subscribeDirectoryMoved, directoryId, updateDirectoryParentDiretoryCallback]);

  const initializeFiles = (
    fileNodes: Array<IFileDTO | IDirectoryDTO>,
    targetParentId?: string,
    isRootDirectory?: boolean
  ) => {
    setFiles(fileNodes);
    setParentId(targetParentId);
    setIsRoot(isRootDirectory);
  };

  const initializeCurrentDirectory = useCallback(() => {
    directoryUseCase.getDirectory(directoryId).then((directory) => {
      const childrenFiles: Array<IFileDTO | IDirectoryDTO> = [];
      if (directory.directories) {
        directory.directories.forEach((sd) => {
          childrenFiles.push(sd);
        });
      }

      if (directory.files) {
        directory.files.forEach((f) => {
          childrenFiles.push(f);
        });
      }

      initializeFiles(childrenFiles, directory.parentDirectoryId, !directory.parentDirectoryId);
    });
  }, [directoryId, directoryUseCase]);

  useEffect(() => {
    initializeCurrentDirectory();
  }, [directoryId, initializeCurrentDirectory]);

  return {
    isRoot,
    parentId,
    files,
  };
};

const searchDirectoryNode = (element: IDirectoryDTO, directoryId: string): IDirectoryDTO | null => {
  if (element.id === directoryId) {
    return element;
  }

  for (const child of element.directories || []) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const res = searchDirectoryNode(child, directoryId);
    if (res) {
      return res;
    }
  }

  return null;
};

export default useCurrentDirectory;
