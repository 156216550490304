import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";

const toTimeFormattedText = (timeInSeconds: number) => {
  const stringMinute: string = String(timeInSeconds / 60);
  const minute: number = parseInt(stringMinute, 10);
  const seconds: number = timeInSeconds - minute * 60;
  let rt = "";
  if (minute < 10) {
    rt += `0${minute.toFixed(0)}`;
  } else {
    rt += `${minute.toFixed(0)}`;
  }

  rt += ":";
  if (seconds < 10) {
    rt += `0${seconds.toFixed(0)}`;
  } else {
    rt += `${seconds.toFixed(0)}`;
  }

  return rt;
};

const TimelineHeader = React.memo(
  (props: { unitWidth: number; parentWidth: number; durationInMilliSeconds: number }) => {
    const { unitWidth, parentWidth, durationInMilliSeconds } = props;
    const [timeArray, setTimeArray] = useState<number[]>([]);
    const [textWidth] = useState(50);
    useEffect(() => {
      const timeArray = [];
      for (let i = 0; i < Math.ceil(durationInMilliSeconds / 1000); i++) {
        timeArray.push(i);
      }
      setTimeArray(timeArray);
    }, [unitWidth, parentWidth, durationInMilliSeconds]);

    return (
      <StyledTimelineHeader>
        {timeArray.map((time) => (
          <TimeScaleWrapper key={time} width={unitWidth} textWidth={textWidth}>
            <TimeScale textWidth={textWidth}>{toTimeFormattedText(time)}</TimeScale>
            <TimeScaleLine />
          </TimeScaleWrapper>
        ))}
      </StyledTimelineHeader>
    );
  }
);

export default TimelineHeader;

const StyledTimelineHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  border-bottom: 1px solid #dcdde3;
  height: 20px;
`;

const TimeScale = styled.div<{ textWidth: number }>`
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.16px;
  color: #a2a6b1;
  text-align: center;
  width: ${(props) => `${props.textWidth}px`};
`;

const TimeScaleLine = styled.div`
  position: absolute;
  top: 16px;
  height: 8px;
  width: 1px;
  background-color: #dcdde3;
`;

const TimeScaleWrapper = styled.div<{ width: number; textWidth: number }>`
  width: ${(props) => props.width || 0}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${TimeScale} {
    margin-left: ${(props) => -`${props.textWidth / 2}`}px;
  }
`;
