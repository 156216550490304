import * as React from "react";
import styled from "styled-components";
import CheckBoxIcon from "../../../assets/images/check-box-24-px.svg";
import CheckBoxOutlineIcon from "../../../assets/images/check-box-outline-blank-24-px.svg";

const CheckBox = (props: { checked: boolean; onChange: () => void }) => {
  const { checked, onChange } = props;
  return <StyledCheckBox src={checked ? CheckBoxIcon : CheckBoxOutlineIcon} onClick={onChange} />;
};

export default CheckBox;

const StyledCheckBox = styled.img`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`;
