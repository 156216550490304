import * as React from "react";
import { Authorization, authorizationManager } from "../../api";
import * as EventListeners from "../../eventListeners";

export const AuthenticationContext = React.createContext<{
  accountInfo?: IAccountInfo;
  resetAccountInfo?: () => void;
  setAccountInfo?: () => void;
  isLogin?: boolean;
}>({});

export interface IAccountInfo {
  id: string;
  name: string;
}

interface IState {
  accountInfo?: IAccountInfo;
  isLogin: boolean;
}

class AuthenticationContextProvider extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isLogin: !!authorizationManager.getToken(),
    };
    this.addSetTokenEvent();
    this.addAuthResetEvent();
  }

  private addAuthResetEvent = () => {
    EventListeners.addEventListeners(Authorization.RESET, this.resetAccountInfo);
  };

  private addSetTokenEvent = () => {
    EventListeners.addEventListeners(Authorization.SET_TOKEN, this.setAccountInfo);
  };

  public resetAccountInfo = () => {
    this.setState({ accountInfo: undefined, isLogin: false });
  };

  public setAccountInfo = () => {
    this.setState({ isLogin: true });
  };

  public render() {
    return (
      <AuthenticationContext.Provider
        value={{
          accountInfo: this.state.accountInfo,
          resetAccountInfo: this.resetAccountInfo,
          setAccountInfo: this.setAccountInfo,
          isLogin: this.state.isLogin,
        }}
      >
        {this.props.children}
      </AuthenticationContext.Provider>
    );
  }
}

export default AuthenticationContextProvider;
