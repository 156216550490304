import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import useFileSort, { SORT } from "../../../hooks/useFileSort";
import { FileNodeType, FileStatus, IDirectoryDTO, IFileDTO } from "../../../../Models/Interfaces";
import { ListViewHeader } from "./ListViewFile/ListViewItem";
import { IPosition } from "../../GlobalContextMenu";
import FileViewItem from "./FileViewItem";
import DirectoryViewItem from "./DirectoryViewItem";

interface IProps {
  isShowCheck: boolean;
  fileViewType: "GRID" | "LIST";
  directoryId: string;
  files: Array<IFileDTO | IDirectoryDTO>;
  isSelected: (id: string) => boolean;
  onFileNameChange: (id: string, name: string) => void;
  onDirectoryNameChange: (id: string, name: string) => void;
  createFile: (directoryId: string, file: File) => void;
  onDragStart: (id: string) => void;
  onDragEnd: (id: string) => void;
  onDrop: (id: string) => void;
  onFileClick: (e: React.MouseEvent, id: string) => void;
  onFileDoubleClick: (e: React.MouseEvent, id: string) => void;
  onDirectoryClick: (e: React.MouseEvent, id: string) => void;
  onDirectoryDoubleClick: (e: React.MouseEvent, id: string) => void;
  callContext: (position: IPosition, onNameChange: () => void) => void;
  isRoot?: boolean;
}

const Files = (props: IProps) => {
  const {
    fileViewType,
    files,
    isShowCheck,
    createFile,
    onDirectoryNameChange,
    onFileNameChange,
    isSelected,
    onDragStart,
    onDragEnd,
    onDrop,
    onFileClick,
    onFileDoubleClick,
    onDirectoryClick,
    onDirectoryDoubleClick,
    callContext,
  } = props;

  const [sort, setSort] = useState<SORT | undefined>(undefined);
  const [sortInverted, setSortInverted] = useState<boolean>(false);
  const sortedFiles = useFileSort({
    data: files || [],
    sort,
    inverted: sortInverted,
  });

  return (
    <FileWrapper type={fileViewType}>
      {fileViewType === "LIST" && (
        <ListViewHeader
          sort={sort}
          setSort={setSort}
          sortInverted={sortInverted}
          setSortInverted={setSortInverted}
          isShowCheck={isShowCheck}
        />
      )}
      {sortedFiles &&
        sortedFiles.map((node) =>
          node.type === FileNodeType.FILE ? (
            <FileViewItem
              type={fileViewType}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              onDrop={onDrop}
              isShowCheck={isShowCheck}
              key={node.id}
              selected={isSelected(node.id)}
              file={node}
              onClick={onFileClick}
              callContext={callContext}
              onNameChange={onFileNameChange}
              onDoubleClick={onFileDoubleClick}
              disabled={node.status !== FileStatus.ANALYZED}
            />
          ) : (
            <DirectoryViewItem
              type={fileViewType}
              onDrop={onDrop}
              isShowCheck={isShowCheck}
              key={node.id}
              directory={node}
              selected={isSelected(node.id)}
              onClick={onDirectoryClick}
              onDoubleClick={onDirectoryDoubleClick}
              callContext={callContext}
              onNameChange={onDirectoryNameChange}
              createFile={createFile}
            />
          )
        )}
    </FileWrapper>
  );
};

const FileWrapper = styled.div<{ type: "GRID" | "LIST" }>`
  width: 100%;
  height: 100%;

  display: ${(props) => (props.type === "GRID" ? "flex" : undefined)};
  flex-direction: ${(props) => (props.type === "GRID" ? "row" : undefined)};
  flex-wrap: ${(props) => (props.type === "GRID" ? "wrap" : undefined)};
  align-content: ${(props) => (props.type === "GRID" ? "flex-start" : undefined)};
`;
export default Files;
