import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Header, Input, Label, Form, PageBody, PageHeader, PageWrapper } from "../Commons";
import AuthenticationUseCase from "../../../../UseCases/Authentication";

const ChangePasswordPage = () => {
  const [newPassword, setNewPassword] = useState("");

  const changeNewPassword = async () => {
    try {
      const authenication = new AuthenticationUseCase();
      authenication.changePassword(newPassword);
    } catch (e) {
      console.log("error");
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <Header>비밀번호 변경</Header>
      </PageHeader>
      <PageBody>
        <FormWrapper>
          <Form>
            <Label>새 비밀번호</Label>
            <Input
              type={"password"}
              value={newPassword}
              placeholder={"새 비밀번호"}
              onChange={(e) => setNewPassword(e.currentTarget.value)}
            />
          </Form>
          <SignInButton onClick={changeNewPassword} disabled={false}>
            변경하기
          </SignInButton>
        </FormWrapper>
      </PageBody>
    </PageWrapper>
  );
};

const Description = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #33343d;
`;

const SignInButton = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  padding: 17px 16px 17px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? "#acacac" : "#657eff")};
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
`;

const FormWrapper = styled.div`
  width: 343px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Description} {
    width: 100%;
    margin-bottom: 29px;
  }

  ${Form} {
    width: 100%;
    margin-bottom: 20px;
  }

  ${SignInButton} {
    width: 100%;
    margin-bottom: 43px;
  }
`;

export default ChangePasswordPage;
