import { ISearchRepository } from "./Interfaces";
import api from "../api";
import { ISearchFilter } from "../Drivers/Search";

class SearchRepository<T> implements ISearchRepository<T> {
  public getSearchResult = async (value: string, filter: ISearchFilter) => {
    const { data } = await api.search(value, filter);
    return data.results;
  };
}

export default SearchRepository;
