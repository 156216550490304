import * as React from "react";
import { useState } from "react";
import { IDirectoryDTO } from "../../../../Models/Interfaces";
import { IPosition } from "../../GlobalContextMenu";
import GridViewItem from "./GridViewFile/GridViewItem";
import DirectoryIcon from "../../../../assets/images/folder-20-px.svg";
import ListViewItem from "./ListViewFile/ListViewItem";
import { addFileWithDrops } from "./FileViewComponent";

const DirectoryViewItem = (props: {
  type: "GRID" | "LIST";
  directory: IDirectoryDTO;
  selected: boolean;
  isShowCheck: boolean;
  onClick?: (e: React.MouseEvent, id: string) => void;
  onDoubleClick?: (e: React.MouseEvent, id: string) => void;
  callContext?: (position: IPosition, onNameChange: () => void) => void;
  onNameChange: (id: string, name: string) => void;
  onDragStart?: (id: string) => void;
  onDragEnd?: (id: string) => void;
  onDrop?: (id: string) => void;
  createFile: (directoryId: string, file: File) => void;
  disabled?: boolean;
}) => {
  const {
    type,
    directory,
    selected,
    isShowCheck,
    onClick,
    onDoubleClick,
    callContext,
    onNameChange,
    onDragStart,
    onDragEnd,
    onDrop,
    createFile,
    disabled,
  } = props;

  const [isDragging, setIsDragging] = useState(false);

  const onClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onClick) {
      onClick(e, directory.id);
    }
  };

  const onDoubleClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (onDoubleClick) {
      onDoubleClick(e, directory.id);
    }
  };

  const onRightClickHandler = (e: React.MouseEvent, onNameChange: () => void) => {
    e.preventDefault();
    if (onClick) {
      onClick(e, directory.id);
    }
    if (callContext) {
      callContext({ x: e.pageX, y: e.pageY }, onNameChange);
    }
  };

  const onNameChangeHandler = (name: string) => {
    onNameChange(directory.id, name);
  };

  const onDragStartHandler = () => {
    if (onDragStart) {
      onDragStart(directory.id);
    }
  };

  const onDragEndHandler = () => {
    if (onDragEnd) {
      onDragEnd(directory.id);
    }
    setIsDragging(false);
  };

  const onDropHandler = (e: React.DragEvent) => {
    e.preventDefault();
    if (onDrop) {
      onDrop(directory.id);
    }

    addFileWithDrops(e, directory.id, createFile);

    setIsDragging(false);
  };

  if (type === "GRID") {
    return (
      <GridViewItem
        onDragOver={() => {
          setIsDragging(true);
        }}
        onDragLeave={() => {
          setIsDragging(false);
        }}
        onDragStart={onDragStartHandler}
        onDragEnd={onDragEndHandler}
        onDrop={onDropHandler}
        selected={selected || isDragging}
        icon={DirectoryIcon}
        isShowCheck={isShowCheck}
        name={directory.name}
        size={directory.size}
        createdDateTime={directory.createdDateTime!}
        onClick={onClickHandler}
        onDoubleClick={onDoubleClickHandler}
        onRightClick={onRightClickHandler}
        onNameChange={onNameChangeHandler}
        disabled={disabled}
      />
    );
  }

  return (
    <ListViewItem
      onDragOver={() => {
        setIsDragging(true);
      }}
      onDragLeave={() => {
        setIsDragging(false);
      }}
      onDragStart={onDragStartHandler}
      onDragEnd={onDragEndHandler}
      onDrop={onDropHandler}
      selected={selected || isDragging}
      icon={DirectoryIcon}
      isShowCheck={isShowCheck}
      name={directory.name}
      size={directory.size}
      createdDateTime={directory.createdDateTime!}
      onClick={onClickHandler}
      onDoubleClick={onDoubleClickHandler}
      onRightClick={onRightClickHandler}
      onNameChange={onNameChangeHandler}
      disabled={disabled}
    />
  );
};

export default DirectoryViewItem;
