import * as React from "react";
import { ReactElement } from "react";

export interface IPopupContextProps {
  readonly show: boolean;
  showComponent?: ReactElement<any>;
  closePopup?: () => void;
  showPopup?: (e: ReactElement<any>, key?: string) => void;
  popupStack?: Array<ReactElement<any>>;
  removePopup?: (key: string) => void;
}

export const PopupContext = React.createContext<IPopupContextProps>({
  show: false,
});

interface IPopupContextProviderState {
  readonly show: boolean;
  showComponent?: ReactElement<any>;
  popupStack?: Array<ReactElement<any>>;
}

export default class PopupContextProvider extends React.Component<{}, IPopupContextProviderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      popupStack: [],
    };
    this.closePopup = this.closePopup.bind(this);
  }

  public render() {
    const { show, showComponent, popupStack } = this.state;

    const { closePopup, showPopup } = this;
    return (
      <PopupContext.Provider value={{ show, closePopup, showPopup, showComponent, popupStack }}>
        {this.props.children}
      </PopupContext.Provider>
    );
  }

  private closePopup: () => void = () => {
    if (this.state.popupStack && this.state.popupStack.length > 0) {
      this.state.popupStack.pop();
      this.setState({ popupStack: this.state.popupStack });
    }
  };

  private showPopup = (element: ReactElement<any>) => {
    this.callPopup(element);
  };

  private callPopup = (popup: ReactElement<any>) => {
    if (this.state.popupStack) {
      this.state.popupStack.push(popup);
      this.setState({ popupStack: this.state.popupStack });
    }
  };
}
