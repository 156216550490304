import * as React from "react";
import { ReactElement, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { closeContextMenu } from "../../GlobalContextMenu";

export interface IContextMenuItem {
  text: ReactElement | string;
  onClick: () => void;
}

const FileVieContextMenu = (props: { menus: IContextMenuItem[] }) => {
  const contextMenuRef = useRef<HTMLDivElement>(null);

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (contextMenuRef && contextMenuRef.current && e.target && !contextMenuRef.current.contains(e.target as Node)) {
        closeContextMenu();
      }
    },
    [contextMenuRef]
  );

  useEffect(() => {
    window.addEventListener("click", clickOutside);
    return () => {
      window.removeEventListener("click", clickOutside);
    };
  }, [clickOutside]);

  return (
    <StyledContextMenu ref={contextMenuRef}>
      {props.menus.map((item, index) => (
        <ContextMenuItem
          key={index}
          onClick={(e) => {
            e.preventDefault();
            item.onClick();
          }}
        >
          {item.text}
        </ContextMenuItem>
      ))}
    </StyledContextMenu>
  );
};

const ContextMenuItem = styled.div`
  padding: 11px 76px 11px 13px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.16px;
  color: #33343d;

  &:hover {
    background-color: #eff0f2;
  }
`;

const StyledContextMenu = styled.div`
  padding: 5px 0;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  width: 151px;
`;

export default FileVieContextMenu;
