import * as React from "react";
import { FileNodeType, IDirectoryDTO, IFileDTO } from "../../../../Models/Interfaces";
import DirectoryItem from "../../FileNodes/DirectoryItem";
import FileItem from "../../FileNodes/FileItem";

export const FileItemView = (props: {
  file: IFileDTO;
  depth: number;
  onClick: (id: string) => void;
  onDoubleClick: (id: string) => void;
  onIconClick: () => void;
  selected: boolean;
}) => {
  const { file, onClick, onIconClick, selected, onDoubleClick } = props;
  return (
    <FileItem
      file={file}
      depth={props.depth}
      selected={selected}
      onClick={onClick}
      onIconClick={onIconClick}
      onDoubleClick={onDoubleClick}
    />
  );
};

export const DirectoryItemView = (props: {
  directory: IDirectoryDTO;
  onDirectoryClick: (id: string) => void;
  onDirectoryDoubleClick: (id: string) => void;
  onFileClick: (id: string) => void;
  fileSelected: (id: string) => boolean;
  directorySelected: (id: string) => boolean;
  toggleDirectory: (id: string) => void;
  depth: number;
  hideRoot?: boolean;
}) => {
  const {
    directory,
    onDirectoryClick,
    onDirectoryDoubleClick,
    onFileClick,
    toggleDirectory,
    depth,
    fileSelected,
    directorySelected,
  } = props;
  const target: Array<IFileDTO | IDirectoryDTO> = [];
  if (directory.directories != null) {
    directory.directories.forEach((sd) => {
      target.push(sd);
    });
  }

  const onToggle = () => {
    toggleDirectory(directory.id);
  };

  // if (hideRoot) {
  //   return (
  //     <>
  //       {target.map((t) =>
  //         t.type === FileNodeType.FILE ? (
  //           <FileItemView
  //             depth={depth + 1}
  //             key={t.id}
  //             file={t}
  //             selected={fileSelected(t.id)}
  //             onClick={onFileClick}
  //             onIconClick={() => {}}
  //           />
  //         ) : (
  //           <DirectoryItemView
  //             depth={depth + 1}
  //             key={t.id}
  //             directory={t}
  //             fileSelected={fileSelected}
  //             directorySelected={directorySelected}
  //             onDirectoryClick={onDirectoryClick}
  //             onFileClick={onFileClick}
  //             toggleDirectory={toggleDirectory}
  //           />
  //         )
  //       )}
  //     </>
  //   );
  // }

  return (
    <DirectoryItem
      directory={directory}
      depth={depth}
      onClick={onDirectoryClick}
      onIconClick={onToggle}
      onDoubleClick={onDirectoryDoubleClick}
      selected={directorySelected(directory.id)}
    >
      {target.map((t) =>
        t.type === FileNodeType.DIRECTORY ? (
          <DirectoryItemView
            depth={depth + 1}
            key={t.id}
            directory={t}
            onFileClick={onFileClick}
            onDirectoryClick={onDirectoryClick}
            onDirectoryDoubleClick={onDirectoryDoubleClick}
            fileSelected={fileSelected}
            directorySelected={directorySelected}
            toggleDirectory={toggleDirectory}
          />
        ) : null
      )}
    </DirectoryItem>
  );
};
