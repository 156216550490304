import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Description, Header, PageBody, PageHeader, PageWrapper, Title } from "../Commons";

const SignupDone = () => {
  const history = useHistory();
  const goToRootDirectory = () => {
    history.push("/directory");
  };

  return (
    <PageWrapper>
      <PageHeader>
        <Header>회원가입</Header>
      </PageHeader>
      <PageBody>
        <FormWrapper>
          <Title>환영합니다</Title>
          <Description>이제 귀찮고 따분한 밑작업은 VWS에게 맡기고, 여러분은 창작에만 집중하세요</Description>
          <SignInButton onClick={goToRootDirectory}>시작하기</SignInButton>
        </FormWrapper>
      </PageBody>
    </PageWrapper>
  );
};

const SignInButton = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  padding: 17px 16px 17px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? "#acacac" : "#657eff")};
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
`;

const FormWrapper = styled.div`
  width: 343px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Title} {
    margin-bottom: 20px;
  }

  ${Description} {
    width: 100%;
    margin-bottom: 47px;
  }

  ${SignInButton} {
    width: 100%;
  }
`;

export default SignupDone;
