import * as React from "react";
import styled from "styled-components";
import { toFormattedTimeCode } from "../../../../utils/utils";
import PlayIcon from "../../../../assets/images/icn-play@3x.png";
import PauseIcon from "../../../../assets/images/icn-pause@3x.png";

const VideoOverlay = (props: { playing: boolean; durationInMilliSeconds: number }) => {
  const { playing, durationInMilliSeconds } = props;

  return (
    <StyledVideoOverlay>
      <img src={playing ? PauseIcon : PlayIcon} alt={"재생"} />
      <TimeCode>{toFormattedTimeCode(durationInMilliSeconds)}</TimeCode>
    </StyledVideoOverlay>
  );
};

export default VideoOverlay;

const TimeCode = styled.div`
  padding: 4px 5px;
  box-sizing: border-box;
  opacity: 0.75;
  border-radius: 2px;
  background-color: #000000;
  font-size: 9px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;

const StyledVideoOverlay = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  ${TimeCode} {
    position: absolute;
    left: 8px;
    bottom: 9px;
  }

  & > img {
    width: 100px;
    height: 99px;
  }
`;
