import * as React from "react";
import AuthenticationContextProvider from "../Contexts/AuthenticationContext";
import MainTemplate from "./MainTemplate";
import PopupContextProvider from "../Contexts/PopupContext";
import FileManagerContextProvider from "../Contexts/FileManagerContexts";

const ContextWrapper = () => {
  return (
    <AuthenticationContextProvider>
      <PopupContextProvider>
        <FileManagerContextProvider>
          <MainTemplate />
        </FileManagerContextProvider>
      </PopupContextProvider>
    </AuthenticationContextProvider>
  );
};

export default ContextWrapper;
