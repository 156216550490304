import * as React from "react";
import { privacyAgreement, usageAgreement } from "./Agreements";

const AgreementPage = (props: { text: string }) => {
  return <div style={{ whiteSpace: "pre-line" }}>{props.text}</div>;
};

export const PrivacyAgreement = () => {
  return <AgreementPage text={privacyAgreement} />;
};

export const UsageAgreement = () => {
  return <AgreementPage text={usageAgreement} />;
};
