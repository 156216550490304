import { createBrowserHistory, History } from "history";

class HistoryManager {
  private history: History;

  constructor(historyInstance: History) {
    this.history = historyInstance;
  }

  public getHistory() {
    return this.history;
  }

  public updateHistory(historyInstance: History) {
    this.history = historyInstance;
  }
}

export function createBrowserHistoryWithBaseName(basename?: string): History {
  return createBrowserHistory({ basename });
}

const defaultHistory = createBrowserHistoryWithBaseName();

const historyManager = new HistoryManager(defaultHistory);

export default historyManager;
