import * as React from "react";
import { useEffect, useRef, useState } from "react";
import ProgressFileHeader from "./ProgressFilesHeader";
import ProgressFileItem from "./ProgressFileItem";
import styled from "styled-components";
import { IVideoFilePipelineProcess } from "../../../Contexts/FileManagerContexts/CreateFileManagerContext";

const ProgressFilePopup = (props: {
  processingFiles: IVideoFilePipelineProcess[];
  removeFile: (processId: number) => void;
}) => {
  const { processingFiles, removeFile } = props;
  const [isToggled, setIsToggled] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    if (processingFiles.length === 0) {
      setIsToggled(false);
    } else {
      setIsToggled(true);
    }
  }, [processingFiles]);

  const ref = useRef<HTMLDivElement>(null);

  const toggleMinimized = () => {
    setIsMinimized(!isMinimized);
  };

  if (!isToggled) {
    return null;
  }

  return (
    <StyledProgressFiles ref={ref}>
      <ProgressFileHeader processingFiles={processingFiles} onClick={toggleMinimized} />
      <StyledProgressFilesBody minimized={isMinimized}>
        {processingFiles.map((wf) => (
          <ProgressFileItem key={wf.processId} progress={wf} removeFile={removeFile} />
        ))}
      </StyledProgressFilesBody>
    </StyledProgressFiles>
  );
};

const StyledProgressFilesBody = styled.div<{ minimized: boolean }>`
  height: ${(props) => (props.minimized ? "0px" : "305px")};
  transition: height 0.2s;
  overflow: scroll;
`;

const StyledProgressFiles = styled.div`
  position: absolute;
  right: 21px;
  bottom: 20px;
  width: 374px;
  padding: 0 0 1px;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;

export default ProgressFilePopup;
