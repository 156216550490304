import * as React from "react";
import { ISubscription } from "./Interfaces";
import * as EventListeners from "../../../eventListeners";
import { IDirectoryUseCase, IFileUseCase } from "../../../UseCases/Interfaces";
import { FileUseCases } from "../../../UseCases/Files/File";
import { DirectoryUseCases } from "../../../UseCases/Directory/Directory";

export interface IDeleteFileSubscribe {
  subscribeFileDelete?: (func: (params: { directoryId: string; targetId: string }) => void) => ISubscription;
}

export interface IDeleteDirectorySubscribe {
  subscribeDirectoryDeleted?: (func: (params: { directoryId: string; targetId: string }) => void) => ISubscription;
}

export const DeleteFileManagerContext = React.createContext<
  IDeleteFileSubscribe &
    IDeleteDirectorySubscribe & {
      deleteDirectory?: (directoryId: string, targetId: string) => Promise<void>;
      deleteFile?: (directoryId: string, targetId: string) => Promise<void>;
    }
>({});

class DeleteFileManagerContextProvider extends React.Component {
  private fileUseCase: IFileUseCase;
  private directoryUseCase: IDirectoryUseCase;

  constructor(props: {}) {
    super(props);
    this.fileUseCase = new FileUseCases();
    this.directoryUseCase = new DirectoryUseCases();
  }

  public deleteDirectory = async (directoryId: string, targetId: string) => {
    await this.directoryUseCase.deleteDirectory(targetId);
    this.notifyDirectoryDeleted(directoryId, targetId);
  };

  public deleteFile = async (directoryId: string, targetId: string) => {
    await this.fileUseCase.deleteFile(targetId);
    this.notifyFileDeleted(directoryId, targetId);
  };

  private notifyDirectoryDeleted = (directoryId: string, targetId: string) => {
    EventListeners.emit("DIRECTORY_DELETED", { directoryId, targetId });
  };

  public subscribeDirectoryDeleted = (func: (params: { directoryId: string; targetId: string }) => void) => {
    EventListeners.addEventListeners("DIRECTORY_DELETED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("DIRECTORY_DELETED", func),
    };
  };

  private notifyFileDeleted = (directoryId: string, targetId: string) => {
    EventListeners.emit("FILE_DELETED", { directoryId, targetId });
  };

  public subscribeFileDeleted = (func: (params: { directoryId: string; targetId: string }) => void) => {
    EventListeners.addEventListeners("FILE_DELETED", func);
    return {
      unsubscribe: () => EventListeners.removeEventListeners("FILE_DELETED", func),
    };
  };

  public render() {
    return (
      <DeleteFileManagerContext.Provider
        value={{
          deleteFile: this.deleteFile,
          deleteDirectory: this.deleteDirectory,
          subscribeDirectoryDeleted: this.subscribeDirectoryDeleted,
          subscribeFileDelete: this.subscribeFileDeleted,
        }}
      >
        {this.props.children}
      </DeleteFileManagerContext.Provider>
    );
  }
}

export default DeleteFileManagerContextProvider;
