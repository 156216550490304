import * as React from "react";
import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import SignInPage from "./SignIn";
import SignUpPage from "./SignUp";
import { AuthenticationContext } from "../../../Contexts/AuthenticationContext";
import ResetPasswordPage from "./ResetPassword";
import SignupDone from "./SignupDone";
import ChangePasswordPage from "./ChangePassword";
import SignUpEmailCheck from "./SignUpEmailCheck";

const Authentication = () => {
  const { isLogin } = useContext(AuthenticationContext);
  return (
    <Switch>
      <Route path={"/authentication/resetPassword"} component={ResetPasswordPage} />
      <Route path={"/authentication/changePassword"} component={ChangePasswordPage} />
      <Route path={"/authentication/signUp/done"} component={SignupDone} />
      <Route path={"/authentication/signUp/checkEmail"} component={SignUpEmailCheck} />
      <Route exact={true} path={"/authentication/signUp"} component={SignUpPage} />
      <Route exact={true} path={"/authentication"} component={!isLogin ? SignInPage : Empty} />
    </Switch>
  );
};

const Empty = () => {
  return null;
};

export default Authentication;
