import * as React from "react";
import { useEffect, useState } from "react";
import { ISelection, ITextScript, ITextSource } from "../../../../../Programs/VideoEditor/VideoEditor";
import styled from "styled-components";
import * as R from "ramda";
import PlayerManager from "../../../../../Programs/VideoEditor/VideoPlayer";

const TextTimeline = React.memo(
  (props: {
    selection: ISelection | null;
    parentWidth: number;
    duration: number;
    textSource?: ITextSource;
    height: number;
    videoPlayer?: PlayerManager;
  }) => {
    const { selection, textSource, parentWidth, height, duration, videoPlayer } = props;

    const [focusScript, setFocusScript] = useState<ITextScript>();

    useEffect(() => {
      const subscription = videoPlayer?.subscribeTime((time: number) => {
        const timeInMilliSeconds = time * 1000;
        if (textSource == null) {
          return;
        }

        const targetScript = textSource.data.scripts
          .filter((script) => script.startTime <= timeInMilliSeconds && script.endTime >= timeInMilliSeconds)
          .pop();

        if (!R.equals(targetScript, focusScript)) {
          setFocusScript(targetScript);
        }
      });

      return subscription?.unsubscribe;
    }, [videoPlayer, textSource, focusScript]);

    if (parentWidth == null || duration == null) {
      return null;
    }

    const isInSelection = (textScript: ITextScript) => {
      if (selection == null || selection.startTimeInMillSeconds == null || selection.endTimeInMillSeconds == null) {
        return false;
      }
      return (
        textScript.startTime >= selection?.startTimeInMillSeconds &&
        textScript.endTime <= selection.endTimeInMillSeconds
      );
    };

    const textScripts = textSource?.data.scripts;

    return (
      <StyledTextTimeline height={height}>
        {textScripts &&
          textScripts.map((textScript, idx) => (
            <TextTimelineBox
              key={idx}
              focused={selection != null ? isInSelection(textScript) : R.equals(textScript, focusScript)}
              width={((textScript.endTime - textScript.startTime) * parentWidth) / duration}
              left={(textScript.startTime * parentWidth) / duration}
            >
              {textScript.data.text}
            </TextTimelineBox>
          ))}
      </StyledTextTimeline>
    );
  }
);

export default TextTimeline;

const TextTimelineBox = styled.div<{
  focused: boolean;
  width: number;
  left: number;
}>`
  position: absolute;
  left: ${(props) => props.left}px;
  padding: 6px 7px;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 1px ${(props) => (props.focused ? "#cad0f5" : "#edeef4")};
  background-color: ${(props) => (props.focused ? "#e8ecff" : "#f7f7f7")};
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -0.2px;
  color: #33343d;
  width: ${(props) => props.width}px;
  height: 100px;
  word-break: break-all;
`;

const StyledTextTimeline = styled.div<{ height: number }>`
  position: relative;
  height: ${(props) => props.height}px;
  padding-bottom: 11px;
`;
