import * as React from "react";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import api from "../../../../api";
import { AuthenticationContext } from "../../../Contexts/AuthenticationContext";
import { DirectoryUseCases } from "../../../../UseCases/Directory/Directory";
import { Header, Input, Label, Form, PageHeader } from "../Commons";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";
import { GOOGLE_CLIENT_ID } from "./Config";
import { googleSignUpInfoForRedirect } from "./SignUp";
import GoogleIcon from "../../../../assets/images/google-logo.png";
import AuthenticationUseCase from "../../../../UseCases/Authentication";

const SignInPage = () => {
  const { setAccountInfo } = useContext(AuthenticationContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signIn = () => {
    new AuthenticationUseCase()
      .signIn(email, password)
      .then(() => {
        setAccountInfo!();
        new DirectoryUseCases().getRootDirectory().then((directory) => {
          history.push(`/directory/${directory.id}`);
        });
      })
      .catch((e) => {
        if (
          e.response.status === 400 &&
          e.response.data != null &&
          e.response.data.message != null &&
          e.response.data.message === "password doesn't match"
        ) {
          alert("잘못된 패스워드입니다");
        }
      });
  };

  const checkEmailAndRedirectWhenNotSignUp = async (
    email: string,
    name: string,
    googleUserId: string,
    googleOAuthToken: string
  ) => {
    try {
      const response = await api.checkEmailExist(email);
      const isExistEmail = response.data;

      if (isExistEmail) {
        alert("이미 가입한 계정입니다");
      } else {
        googleSignUpInfoForRedirect.email = email;
        googleSignUpInfoForRedirect.name = name;
        googleSignUpInfoForRedirect.googleUserId = googleUserId;
        googleSignUpInfoForRedirect.googleOAuthToken = googleOAuthToken;

        history.push(`/authentication/signUp`);
        return;
      }
    } catch (e) {
      googleSignUpInfoForRedirect.email = email;
      googleSignUpInfoForRedirect.name = name;
      googleSignUpInfoForRedirect.googleUserId = googleUserId;
      googleSignUpInfoForRedirect.googleOAuthToken = googleOAuthToken;

      history.push(`/authentication/signUp`);
      return;
    }
  };

  const googleSignIn = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ("tokenId" in response) {
      const { tokenId, profileObj } = response;

      try {
        await new AuthenticationUseCase().googleSignIn(tokenId);
        setAccountInfo!();
        new DirectoryUseCases().getRootDirectory().then((directory) => {
          history.push(`/directory/${directory.id}`);
        });
      } catch (e) {
        const { googleId } = response;
        await checkEmailAndRedirectWhenNotSignUp(profileObj.email!, profileObj.name!, googleId, tokenId);
      }
    }
  };

  return (
    <SignInPageWrapper>
      <PageHeader>
        <Header>로그인하기</Header>
      </PageHeader>
      <SignInPageBody>
        <FormWrapper>
          <Form>
            <Label>이메일</Label>
            <Input value={email} placeholder={"example@email.com"} onChange={(e) => setEmail(e.currentTarget.value)} />
          </Form>
          <Form>
            <Label>비밀번호</Label>
            <Input
              value={password}
              placeholder={"password"}
              type={"password"}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </Form>
          <SignInButton onClick={signIn} disabled={false}>
            로그인
          </SignInButton>
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <GoogleSignInButton disabled={false} onClick={renderProps.onClick}>
                <GoogleSignInIcon src={GoogleIcon} />
                <GoogleSignInButtonText>구글 계정으로 로그인</GoogleSignInButtonText>
              </GoogleSignInButton>
            )}
            accessType={"online"}
            onSuccess={googleSignIn}
            onFailure={(e) => {
              alert("구글 정보를 가져오는데 실패하였습니다");
            }}
            cookiePolicy={"single_host_origin"}
          />
          <BottomButtonsWrapper>
            <StyledResetPassword
              onClick={() => {
                history.push("/authentication/resetPassword");
              }}
            >
              비밀번호 찾기
            </StyledResetPassword>
            <StyledSignUp
              onClick={() => {
                history.push("/authentication/signUp");
              }}
            >
              회원가입
            </StyledSignUp>
          </BottomButtonsWrapper>
        </FormWrapper>
      </SignInPageBody>
    </SignInPageWrapper>
  );
};

const SignInButton = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  padding: 17px 16px 17px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? "#acacac" : "#657eff")};
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

const GoogleSignInIcon = styled.img`
  width: 19px;
  height: 18px;
`;

const GoogleSignInButtonText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const GoogleSignInButton = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  padding: 17px 16px 17px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #e34d48;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  ${GoogleSignInIcon} {
    padding-right: 11px;
    border-right: 1px solid #ffffff;
  }
`;
const StyledResetPassword = styled.div`
  padding-right: 20px;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #33343d;
  cursor: pointer;
`;

const StyledSignUp = styled.div`
  padding-left: 20px;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #33343d;
  cursor: pointer;
`;

const BottomButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${StyledResetPassword} {
    border-right: 1px solid black;
  }
`;

const FormWrapper = styled.div`
  width: 343px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Form} {
    width: 100%;
  }

  ${Form}:first-of-type {
    margin-bottom: 9px;
  }

  ${Form}:last-of-type {
    margin-bottom: 9px;
  }

  ${SignInButton} {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  }

  ${GoogleSignInButton} {
    width: 100%;
    margin-bottom: 43px;
  }
`;

const SignInPageBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SignInPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default SignInPage;
