import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FileNodeType, IDirectoryDTO, IFileDTO } from "../../../../Models/Interfaces";
import MoreIcon from "../../../../assets/images/more-20-px.svg";
import useCurrentDirectory from "../../../Containers/UseCurrentDirectory";

import GridViewIcon from "../../../../assets/images/1-icon-gallery-view.svg";
import FocusedGridViewIcon from "../../../../assets/images/1-icon-gallery-view-focused.svg";
import ListViewIcon from "../../../../assets/images/1-icon-list-view.svg";
import FocusedListViewIcon from "../../../../assets/images/1-icon-list-view-focused.svg";
import { IFileSelection } from "../../../hooks/useFileSelection";
import { CreateFileManagerContext } from "../../../Contexts/FileManagerContexts/CreateFileManagerContext";
import { DeleteFileManagerContext } from "../../../Contexts/FileManagerContexts/DeleteFileManagerContext";
import { UpdateFileManagerContext } from "../../../Contexts/FileManagerContexts/UpdateFileManagerContext";
import FileViewComponent from "./FileViewComponent";

interface IProps {
  directoryId: string;
  isRoot?: boolean;
  parentId?: string;
  files?: Array<IFileDTO | IDirectoryDTO>;
  selections: IFileSelection[];
  select: (type: FileNodeType, id: string) => void;
  isSelected: (id: string) => boolean;
  addMultiSelections: (selections: IFileSelection[]) => void;
  clearSelections: () => void;
  isShowCheck: boolean;
}

const fileClipBoard: IFileSelection[] = [];

const FileView = (props: IProps) => {
  const { directoryId, select, isSelected, addMultiSelections, clearSelections, isShowCheck, selections } = props;
  const { isRoot, parentId, files } = useCurrentDirectory({ directoryId });
  const history = useHistory();
  const location = useLocation();
  const [fileViewType, setFileView] = useState<"LIST" | "GRID">("GRID");
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { startVideoFileCreateProcessFromUpload, createDirectory, copyFile } = useContext(CreateFileManagerContext);
  const { deleteDirectory, deleteFile } = useContext(DeleteFileManagerContext);
  const { renameFile, renameDirectory, updateDirectoryParentDirectory, updateFileParentDirectory } = useContext(
    UpdateFileManagerContext
  );

  const createFile = (directoryId: string, file: File) => {
    startVideoFileCreateProcessFromUpload!(directoryId, file, file.name);
  };

  const onDirectoryNameChange = (targetId: string, name: string) => {
    renameDirectory!(directoryId, targetId, name);
  };
  const onFileNameChange = (targetId: string, name: string) => {
    renameFile!(directoryId, targetId, name);
  };

  const onDirectoryDelete = (targetId: string) => {
    deleteDirectory!(directoryId, targetId);
  };

  const onFileDelete = (targetId: string) => {
    deleteFile!(directoryId, targetId);
  };

  const onCopyFile = () => {
    selections.forEach((s) => {
      while (fileClipBoard.length !== 0) {
        fileClipBoard.pop();
      }

      if (s.type === FileNodeType.FILE) {
        fileClipBoard.push(s);
      }
    });
  };

  const moveFileToParent = React.useCallback(
    (id: string) => {
      const currentDirectory = directoryId;
      selections.forEach((selection) => {
        if (id === selection.id) {
          return;
        }

        if (selection.type === FileNodeType.FILE) {
          updateFileParentDirectory!(selection.id, currentDirectory, id);
        }

        if (selection.type === FileNodeType.DIRECTORY) {
          updateDirectoryParentDirectory!(selection.id, currentDirectory, id);
        }
      });
    },
    [updateDirectoryParentDirectory, directoryId, updateFileParentDirectory, selections]
  );

  const pasteFile = (directoryId: string) => {
    while (fileClipBoard.length > 0) {
      const fileSelection = fileClipBoard.pop();

      if (fileSelection == null) {
        break;
      }

      copyFile!(fileSelection.id, directoryId);
    }
  };

  const onCreateDirectory = (name: string) => {
    if (isDirectoryId && directoryId != null) {
      createDirectory!(directoryId, name);
    }
  };

  const onGoToParentDirectory = () => {
    if (isRoot) {
      return;
    }

    history.push(`/directory/${parentId}`);
    clearSelections();
  };

  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      if (wrapperRef && e.target && wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
        clearSelections();
      }
    };
    window.addEventListener("click", clickOutside);
    return () => {
      window.removeEventListener("click", clickOutside);
    };
  }, [clearSelections]);

  const isDirectoryId = location.pathname.split("/")[location.pathname.split("/").length - 2] === "directory";

  if (files == null) {
    return null;
  }

  return (
    <>
      {!isRoot && (
        <StyledGoToParent onClick={onGoToParentDirectory}>
          <Icon src={MoreIcon} />
          <StyledText>상위 폴더로 이동</StyledText>
        </StyledGoToParent>
      )}
      <FileViewToggleWrapper>
        <FileViewIcon
          src={fileViewType === "GRID" ? FocusedGridViewIcon : GridViewIcon}
          onClick={() => {
            setFileView("GRID");
          }}
        />
        <FileViewIcon
          src={fileViewType === "LIST" ? FocusedListViewIcon : ListViewIcon}
          onClick={() => {
            setFileView("LIST");
          }}
        />
      </FileViewToggleWrapper>
      <FileViewComponent
        directoryId={directoryId}
        fileViewType={fileViewType}
        files={files}
        copyFiles={onCopyFile}
        pasteFile={pasteFile}
        selections={selections}
        addMultiSelections={addMultiSelections}
        select={select}
        isSelected={isSelected}
        clearSelections={clearSelections}
        createFile={createFile}
        onDirectoryNameChange={onDirectoryNameChange}
        onFileNameChange={onFileNameChange}
        isShowCheck={isShowCheck}
        onDirectoryDelete={onDirectoryDelete}
        onFileDelete={onFileDelete}
        isRoot={isRoot}
        parentId={parentId}
        onCreateDirectory={onCreateDirectory}
        moveFileToParent={moveFileToParent}
      />
    </>
  );
};

const FileViewIcon = styled.img``;

const FileViewToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 13px;
  ${FileViewIcon}:last-of-type {
    margin-left: 3px;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledText = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a2a6b1;
`;

const StyledGoToParent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  ${Icon} {
    margin-right: 10px;
  }
`;

export default FileView;
