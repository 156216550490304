import api from "../../api";
import { IFile } from "../../Models/Interfaces";

export interface IVideoRepository {
  requestGenerateAudioSource: (fileId: string) => Promise<IFile>;
  getAudioSource: (
    fileId: string
  ) => Promise<{
    status: string;
    pps?: number;
    wave?: number[];
  }>;
}

class VideoRepository implements IVideoRepository {
  public getAudioSource = async (fileId: string) => {
    const { data } = await api.getAudioWaveForm(fileId);

    if (data.status !== "DONE") {
      return { status: data.status };
    }

    const file = await getFileFromUrl(data.waveFormDownloadUrl);

    return {
      status: "DONE",
      pps: 60,
      wave: file.data,
    };
  };

  public requestGenerateAudioSource = async (fileId: string) => {
    const { data } = await api.requestGenerateAudioWaveForm(fileId);
    return data;
  };
}

const getFileFromUrl = async (url: string) => {
  return fetch(url).then((res) => res.json());
};

export default VideoRepository;
