import * as React from "react";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { AuthenticationContext } from "../../../Contexts/AuthenticationContext";
import useFileTree from "../../../Containers/UseFileTree";
import { DirectoryItemView } from "../FileTree";
import { PopupContext } from "../../../Contexts/PopupContext";

const FileTree = (props: { selectedDirectory?: string; onDirectoryClick: (id: string) => void }) => {
  const { selectedDirectory, onDirectoryClick } = props;
  const { isLogin } = useContext(AuthenticationContext);
  const { root, toggleDirectory, initializeRootDirectory } = useFileTree();
  useEffect(() => {
    if (isLogin) {
      initializeRootDirectory();
    }
  }, [initializeRootDirectory, isLogin]);

  const onFileClick = () => {};

  const directorySelected = (id: string) => {
    return id === selectedDirectory;
  };
  if (root == null) {
    return null;
  }

  return (
    <DirectoryItemView
      directory={root}
      hideRoot={true}
      onDirectoryClick={onDirectoryClick}
      onDirectoryDoubleClick={toggleDirectory}
      directorySelected={directorySelected}
      fileSelected={() => false}
      onFileClick={onFileClick}
      toggleDirectory={toggleDirectory}
      depth={0}
    />
  );
};

const SaveToArchivePopup = (props: {
  initialFileName: string;
  onConfirm?: (directoryId: string, fileName: string) => void;
  onCancel?: () => void;
}) => {
  const { initialFileName, onConfirm, onCancel } = props;
  const [selectedDirectory, selectDirectory] = useState<string | undefined>();
  const { closePopup } = useContext(PopupContext);
  const [fileName, setFileName] = useState(initialFileName);

  const onConfirmHandler = () => {
    if (selectedDirectory) {
      try {
        onConfirm && onConfirm(selectedDirectory, fileName);
      } catch (e) {
        onCancel && onCancel();
      }
      closePopup!();
    }
  };

  const onCancelHandler = () => {
    if (onCancel) {
      onCancel();
    }
    if (closePopup) {
      closePopup();
    }
  };

  return (
    <PopupOverlay>
      <PopupBody>
        <PopupTitle>폴더 선택</PopupTitle>
        <FileNameInput value={fileName} onChange={(e) => setFileName(e.currentTarget.value)} />
        <FileTreeWrapper>
          <FileTree onDirectoryClick={selectDirectory} selectedDirectory={selectedDirectory} />
        </FileTreeWrapper>
        <ButtonsWrapper>
          <CancelButton onClick={onCancelHandler}>취소</CancelButton>
          <ConfirmButton onClick={onConfirmHandler}>확인</ConfirmButton>
        </ButtonsWrapper>
      </PopupBody>
    </PopupOverlay>
  );
};

export default SaveToArchivePopup;

const PopupTitle = styled.div``;
const FileNameInput = styled.input`
  outline: none;
  padding: 16px 19px;
  box-sizing: border-box;
  border-radius: 8px;
  border: solid 1px #e3e5ee;
  background-color: #f7f7f7;
`;

const FileTreeWrapper = styled.div`
  border-radius: 8px;
  border: solid 1px #e3e5ee;
  background-color: #ffffff;
  padding: 17px 19px;
  height: 494px;
  overflow-y: scroll;
`;

const CancelButton = styled.div`
  padding: 14px 21px 11px;
  border-radius: 10px;
  background-color: #f2f4f6;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e5968;
`;
const ConfirmButton = styled.div`
  padding: 14px 21px 11px;
  border-radius: 10px;
  background-color: #657eff;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${CancelButton} {
    margin-right: 10px;
  }

  ${ConfirmButton} {
  }
`;

const PopupBody = styled.div`
  padding: 18px 19px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 351px;
  max-height: 694px;
  height: 100%;
  min-height: 300px;
  background-color: #ffffff;
  ${PopupTitle} {
    margin-bottom: 14px;
  }
  ${FileNameInput} {
    margin-bottom: 14px;
  }

  ${FileTreeWrapper} {
    margin-bottom: 15px;
  }
`;

const PopupOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
