import axios, { AxiosInstance, CancelTokenSource } from "axios";
import { BASE_URL } from "./config";
import { sleep } from "../utils/utils";
import localStorageManager from "../localStorageManager";
import * as EventListeners from "../eventListeners";
import { IDirectory, IFile } from "../Models/Interfaces";
import historyManager from "../utils/HistoryManager";
import { ISearchFilter } from "../Drivers/Search";
import { ISplitRequestInfo } from "../Programs/VideoEditor/Usecases/SplitVideoUseCase";
import { ISignUpInfo } from "../UseCases/Authentication";

export interface IResponse<T> {
  ok: boolean;
  problem: string;
  data: T;
  status: number;
  headers: object;
  config: object;
  duration: number;
}

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 40000,
});

export enum Authorization {
  RESET = "RESET",
  SET_TOKEN = "SET_TOKEN",
}

class AuthorizationManager {
  private token?: string;
  private refreshToken?: string;
  private isRefreshing: boolean;
  private apiInstance: AxiosInstance;

  constructor(apiInstance: AxiosInstance) {
    const accountInfo = localStorageManager.getItem("accountInfo");
    const token = accountInfo == null ? undefined : accountInfo.token;
    const refreshToken = accountInfo == null ? undefined : accountInfo.refreshToken;

    if (token) {
      this.setToken(token);
    }

    if (refreshToken) {
      this.setRefreshToken(refreshToken);
    }

    this.isRefreshing = false;
    this.apiInstance = apiInstance;
  }

  public getRefreshTokenAsync = async () => {
    let refreshing = false;

    if (this.isRefreshing) {
      refreshing = true;
    }

    while (refreshing) {
      if (this.isRefreshing) {
        await sleep(100);
      } else {
        return this.token;
      }
    }

    const { refreshedToken, newRefreshToken } = await this.refreshAccessToken();
    this.token = refreshedToken;

    if (newRefreshToken) {
      this.refreshToken = newRefreshToken;
    }

    return refreshedToken;
  };

  public getToken = () => {
    return this.token;
  };

  public signIn = async (email: string, password: string) => {
    const { data } = await login(email, password);
    this.setToken(data.accessToken);
    this.setRefreshToken(data.refreshToken);
    return data;
  };

  public googleSignIn = async (googleOAuthId: string) => {
    const { data } = await loginViaGoogle(googleOAuthId);
    this.setToken(data.accessToken);
    this.setRefreshToken(data.refreshToken);
    return data;
  };

  public signOut = () => {
    this.token = undefined;
    this.refreshToken = undefined;
    api.defaults.headers.Authorization = undefined;
    localStorageManager.removeItem("accountInfo");
    EventListeners.emit(Authorization.RESET);
  };

  private setToken = (token: string) => {
    this.token = token;
    api.defaults.headers.Authorization = "Bearer " + token;
    const beforeAccountInfo =
      localStorageManager.getItem("accountInfo") != null ? localStorageManager.getItem("accountInfo") : undefined;

    localStorageManager.setItem("accountInfo", beforeAccountInfo ? { ...beforeAccountInfo, token } : { token });
    EventListeners.emit(Authorization.SET_TOKEN, { token });
  };

  private setRefreshToken = (refreshToken: string) => {
    this.refreshToken = refreshToken;
    const beforeAccountInfo =
      localStorageManager.getItem("accountInfo") != null ? localStorageManager.getItem("accountInfo") : undefined;

    localStorageManager.setItem(
      "accountInfo",
      beforeAccountInfo
        ? {
            ...beforeAccountInfo,
            refreshToken,
          }
        : { refreshToken }
    );
  };

  private refreshAccessToken() {
    const refreshToken =
      localStorageManager.getItem("accountInfo") != null
        ? localStorageManager.getItem("accountInfo").refreshToken
        : null;

    if (refreshToken == null) {
      historyManager.getHistory().push({
        pathname: "/",
      });
      resetLogin();
      return Promise.reject();
    }

    return this.apiInstance
      .post("/api/v1/refreshToken", { refreshToken })
      .then((response) => {
        if (response.status === 200) {
          const refreshedToken = response.data.accessToken;
          const newRefreshToken = response.data.refreshToken;

          this.isRefreshing = false;
          this.setToken(refreshedToken);
          if (newRefreshToken) {
            this.setRefreshToken(newRefreshToken);
          }
          return { refreshedToken, newRefreshToken };
        } else {
          this.isRefreshing = false;
          historyManager.getHistory().push({
            pathname: "/authentication",
          });
          resetLogin();
          return Promise.reject(response);
        }
      })
      .catch((reason) => {
        historyManager.getHistory().push({
          pathname: "/authentication",
        });
        resetLogin();
        return Promise.reject();
      });
  }
}

const resetLogin = () => {
  EventListeners.emit(Authorization.RESET);
};

export const authorizationManager = new AuthorizationManager(api);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {
      if (api.defaults.headers.Authorization == null) {
        historyManager.getHistory().push({
          pathname: "/authentication",
        });
        resetLogin();
        return Promise.reject(error.response);
      }

      return authorizationManager
        .getRefreshTokenAsync()
        .then((token) => {
          // callAccountContextMethod(externalCallerableType.SET_TOKEN, {token});
          api.defaults.headers = {
            ...api.defaults.headers,
            Authorization: `Bearer ${token}`,
          };

          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: `Bearer ${token}`,
          };
          return api(originalRequest);
        })
        .catch((e) => {
          Promise.reject(e);
        });
    } else if (error.response && error.response.status === 401) {
      resetLogin();
      historyManager.getHistory().push({
        pathname: "/authentication",
      });
    }
    return Promise.reject(error);
  }
);

const hello = () => {
  return api.get("/api/v1/health/ping");
};

const login = (email: string, password: string) => {
  return api.post("/api/v1/accessToken", { email, password });
};

const loginViaGoogle = (googleIdToken: string) => {
  return api.post("/api/v1/accessToken", { googleIdToken });
};

const signUp = (accountDTO: ISignUpInfo) => {
  return api.post("/api/v1/signUp", accountDTO);
};

const googleOauthSignUp = (accountDTO: ISignUpInfo) => {
  return api.post("/api/v1/signUp/googleSignUp", accountDTO);
};

const checkEmailExist = (email: string) => {
  return api.post(`api/v1/signUp/isEmailExist?email=${encodeURIComponent(email)}`);
};

const resetPassword = (email: string) => {
  return api.post(`/api/v1/accounts/resetPassword`, { email });
};

const changePassword = (password: string) => {
  return api.patch(`/api/v1/accounts/password`, { password });
};

interface FileForUpload {
  name: string;
  uri: string;
  type: string;
}

export class UploadRequest {
  private key: string;
  private "X-Amz-Credential": string;
  private "X-Amz-Date": string;
  private policy: string;
  private "x-amz-signature": string;
  private file: FileForUpload;
  private acl = "private";
  private "X-Amz-Algorithm" = "AWS4-HMAC-SHA256";
  private "x-amz-server-side-encryption" = "AES256";
  private "x-amz-meta-tag" = "";

  constructor(key: string, credential: string, date: string, policy: string, signature: string, file: FileForUpload) {
    this.key = key;
    this["X-Amz-Credential"] = credential;
    this["X-Amz-Date"] = date;
    this.policy = policy;
    this["x-amz-signature"] = signature;
    this.file = file;
  }

  public toFormData() {
    const formData = new FormData();
    for (const key of Object.keys(this)) {
      // @ts-ignore
      formData.append(key, this[key]);
    }
    return formData;
  }
}

const getRootDirectory = () => {
  return api.get(`/api/v1/directories/root`);
};

const getDirectory = (directoryId: string) => {
  return api.get(`/api/v1/directories/${directoryId}`);
};

const createDirectory = (directory: IDirectory) => {
  return api.post(`/api/v1/directories`, directory);
};

const deleteDirectory = (directoryId: string) => {
  return api.delete(`/api/v1/directories/${directoryId}`);
};

const patchDirectoryName = (directoryId: string, name: string) => {
  return api.patch(`/api/v1/directories/${directoryId}/name`, { name });
};

const patchParentDirectory = (directoryId: string, parentDirectoryId: string) => {
  return api.patch(`/api/v1/directories/${directoryId}/parentDirectoryId`, {
    directoryId: parentDirectoryId,
  });
};

const explorerDirectory = (directoryId: string, information: string) => {
  return api.get(`/api/v1/directories/${directoryId}/explorer?information=${information}`);
};

const getFile = (fileId: string) => {
  return api.get(`/api/v1/files/${fileId}`);
};

const getProcessingFiles = () => {
  return api.get(`/api/v1/files/processingFileIds`);
};

const deleteProcessingFile = (fileId: string) => {
  return api.delete(`/api/v1/files/processingFileIds/${fileId}`);
};

const createFile = (file: IFile) => {
  return api.post(`/api/v1/files/`, file);
};

const updateFileStatus = (fileId: string, status: string) => {
  return api.patch(`/api/v1/files/${fileId}/status?fileStatus=${status}`);
};

const deleteFile = (fileId: string) => {
  return api.delete(`/api/v1/files/${fileId}`);
};

const patchFileName = (fileId: string, name: string) => {
  return api.patch(`/api/v1/files/${fileId}/name`, { name });
};

const patchFileDirectory = (fileId: string, directoryId: string) => {
  return api.patch(`/api/v1/files/${fileId}/directoryId`, { directoryId });
};

const copyFile = (fileId: string, directoryId: string) => {
  return api.post(`/api/v1/files/${fileId}/duplicate`, { directoryId });
};

const createMultipartUpload = (fileName: string) => {
  return api.post(
    `/api/v1/files/multipartUpload?fileName=${fileName}&sha256Checksum=e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855`
  );
};

const createUploadPartHeaderValue = (sha256Checksum: string, partNumber: number, uploadId: string, key: string) => {
  return api.post(
    `/api/v1/files/uploadPartHeaderValue?sha256Checksum=${sha256Checksum}&partNumber=${partNumber}&uploadId=${uploadId}&key=${key}`,
    undefined,
    { timeout: 30000 }
  );
};

const uploadPartToS3 = (
  bucket: string,
  authorization: string,
  date: string,
  sha256Checksum: string,
  fileName: string,
  partNumber: number,
  uploadId: string,
  key: string,
  file: File,
  uploadProgressHandler?: (e: ProgressEvent) => void,
  cancelToken?: CancelTokenSource
) => {
  const instance = axios.create();
  return instance.put(
    `https://${bucket}.s3.ap-northeast-2.amazonaws.com/${key}?partNumber=${partNumber}&uploadId=${uploadId}`,
    file,
    {
      onUploadProgress: uploadProgressHandler,
      cancelToken: cancelToken!.token,
      headers: {
        Authorization: authorization,
        "x-amz-date": date,
        "x-amz-content-sha256": sha256Checksum,
        "content-type": "multipart/form",
      },
    }
  );
};

const completeMultipartUpload = (key: string, uploadId: string, partNumbers: number[], eTags: string[]) => {
  return api.post(
    `/api/v1/files/completeMultipartUpload?key=${key}&uploadId=${uploadId}&partNumbers=${partNumbers}&eTags=${eTags}`
  );
};

const checkAnalysis = (fileId: string) => {
  return api.get(`/api/v1/files/${fileId}/analyses`);
};

const requestAnalysis = (fileId: string) => {
  return api.post(`/api/v1/analyses`, { fileId });
};

const search = (query: string, filters: ISearchFilter) => {
  return api.post(`/api/v1/searches`, { query, filters });
};

const getAudioWaveForm = (fileId: string) => {
  return api.get(`/api/v1/audioWaveForm/${fileId}`);
};

const requestGenerateAudioWaveForm = (fileId: string) => {
  return api.post(`/api/v1/audioWaveForm/${fileId}`);
};

const getASR = (fileId: string) => {
  return api.get(`/api/v1/files/${fileId}/analyses/asr`);
};

const requestExtractAudio = (fileId: string) => {
  return api.post(`/api/v1/ffmpeg/audioExtract/${fileId}`);
};

const checkExtractAudio = (fileId: string) => {
  return api.get(`/api/v1/ffmpeg/audioExtract/${fileId}`);
};

const requestExtractWaveForm = (fileId: string) => {
  return api.post(`/api/v1/audioWaveForm/${fileId}`);
};

const checkExtractWaveForm = (fileId: string) => {
  return api.get(`/api/v1/audioWaveForm/${fileId}`);
};

const requestSourceSplit = (splitRequestInfo: ISplitRequestInfo) => {
  return api.post(`/api/v1/ffmpeg/split`, splitRequestInfo);
};

const getSourceSplit = (requestId: string) => {
  return api.get(`/api/v1/ffmpeg/split/${requestId}`);
};

const downloadSourceSplit = (requestId: string) => {
  return api.get(`/api/v1/ffmpeg/split/download/${requestId}`);
};

const sendFeedback = (text: string) => {
  return api.post(`/api/v1/feedbacks`, { text });
};

const defaultModule = {
  hello,
  login,
  loginViaGoogle,
  signUp,
  checkEmailExist,
  googleOauthSignUp,
  getRootDirectory,
  getDirectory,
  createDirectory,
  deleteDirectory,
  patchDirectory: patchDirectoryName,
  patchParentDirectory,
  getFile,
  getProcessingFiles,
  deleteProcessingFile,
  createFile,
  updateFileStatus,
  deleteFile,
  patchFileName,
  copyFile,
  patchFileDirectory,
  explorerDirectory,
  createMultipartUpload,
  createUploadPartHeaderValue,
  uploadPartToS3,
  completeMultipartUpload,
  requestAnalysis,
  checkAnalysis,
  resetPassword,
  changePassword,
  search,
  getAudioWaveForm,
  requestGenerateAudioWaveForm,
  getTextSource: getASR,
  requestExtractAudio,
  checkExtractAudio,
  requestExtractWaveForm,
  checkExtractWaveForm,
  requestSourceSplit,
  getSourceSplit,
  downloadSourceSplit,
  sendFeedback,
};

export default defaultModule;
