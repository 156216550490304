import styled from "styled-components";

export const Title = styled.div`
  font-size: 26px;
  color: #33343d;
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #33343d;
  text-align: center;
`;

export const Header = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #33343d;
`;

export const PageHeader = styled.div`
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #e9e9e9;
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: -0.2px;
  color: #4b4b4b;
  font-family: NanumSquareR;
`;

export const Input = styled.input`
  padding: 15px 47px 15px 15px;
  box-sizing: border-box;
  border-radius: 8px;
  border: solid 1px #e9e9e9;
  background-color: #f3f3f3;
  font-family: NanumSquareR;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  ${Label} {
    margin-bottom: 8px;
  }
  ${Input} {
    margin-bottom: 25px;
  }
`;

export const PageBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
