import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import useFileSelection from "../../../hooks/useFileSelection";
import { RouteComponentProps, useHistory } from "react-router-dom";
import FileView from "../FileView";
import styled from "styled-components";
import SearchBarRow from "./SearchBarRow";
import { FileNodeType } from "../../../../Models/Interfaces";
import { DirectoryUseCases } from "../../../../UseCases/Directory/Directory";
import { generateSearchUrl } from "../../../Containers/UseSearch";

interface IMainViewProps extends RouteComponentProps<{ id: string }> {
  searchValue: string;
  setSearchValue: (value: string) => void;
  doSearch: (directoryIds: string[], fileIds: string[]) => void;
}

export const parseQuery = (search: string): Array<[string, string]> => {
  const query = search.replace("?", "");

  if (query === "") {
    return [];
  }

  const params = query.split("&");

  const parsedQueries: Array<[string, string]> = [];

  params.forEach((param) => {
    const key = param.split("=")[0];
    const value = param.split("=")[1];

    parsedQueries.push([key, value]);
  });

  return parsedQueries;
};

const MainView = (props: IMainViewProps) => {
  const { searchValue, setSearchValue, doSearch, location } = props;
  const directoryId = props.match.params.id;
  const { selections, select, addMultiSelections, isSelected, clearSelections } = useFileSelection();
  const history = useHistory();
  const search = location.search;
  const parsedQuery: Array<[string, string]> = parseQuery(search);

  const isSearchMode = parsedQuery.filter((q) => q[0] === "search" && q[1] === "true").length > 0;
  const [isSearchRangedSelecting, setIsSearchRangedSelecting] = useState(isSearchMode);
  const [isSearchDone] = useState(false);

  useEffect(() => {
    if (directoryId == null) {
      new DirectoryUseCases().getRootDirectory().then((r) => {
        const url = `/directory/${r.id}` + (isSearchMode ? "?search=true" : "");
        history.push(url);
      });
    }
  }, [history, directoryId, isSearchMode]);

  useEffect(() => {
    if (isSearchDone && searchValue.trim().length !== 0) {
      const urlSearchValue = encodeURIComponent(searchValue);
      const directoryIds = selections.filter((selection) => selection.type === FileNodeType.DIRECTORY).map((s) => s.id);
      const fileIds = selections.filter((selection) => selection.type === FileNodeType.FILE).map((s) => s.id);

      const searchUrl = generateSearchUrl(urlSearchValue, directoryIds, fileIds);
      history.push(searchUrl);
    }
  }, [history, isSearchDone, searchValue, selections]);

  const searchWithValueSelection = useCallback(() => {
    if (isSearchRangedSelecting) {
      const directoryIds = selections.filter((selection) => selection.type === FileNodeType.DIRECTORY).map((s) => s.id);
      const fileIds = selections.filter((selection) => selection.type === FileNodeType.FILE).map((s) => s.id);

      doSearch(directoryIds, fileIds);
    } else {
      setIsSearchRangedSelecting(true);
    }
  }, [doSearch, isSearchRangedSelecting, selections]);

  if (directoryId == null) {
    return null;
  }

  return (
    <MainViewWrapper>
      <SearchBarRow
        searchValue={searchValue}
        isSearching={isSearchRangedSelecting}
        onFocus={() => {
          setIsSearchRangedSelecting(true);
        }}
        onClick={searchWithValueSelection}
        setSearchValue={setSearchValue}
        onEnter={searchWithValueSelection}
      />
      <FileView
        isShowCheck={isSearchRangedSelecting}
        directoryId={directoryId}
        selections={selections}
        isSelected={isSelected}
        select={select}
        addMultiSelections={addMultiSelections}
        clearSelections={clearSelections}
      />
    </MainViewWrapper>
  );
};

const MainViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export default MainView;
