import * as React from "react";
import styled from "styled-components";
import { IVideoFilePipelineProcess } from "../../../Contexts/FileManagerContexts/CreateFileManagerContext";

const ProgressFileHeader = (props: { processingFiles: IVideoFilePipelineProcess[]; onClick?: () => void }) => {
  const { processingFiles, onClick } = props;

  const totalProgress = processingFiles
    .map((pf) => pf.uploadProgress)
    .filter((progress) => progress != null)
    .reduce((a, b) => a! + b!, 0);

  const totalSize = processingFiles
    .map((pf) => pf.file?.size || 0)
    .filter((progress) => progress != null)
    .reduce((a, b) => a! + b!, 0);

  return (
    <StyledProcessingHeader onClick={onClick}>
      {processingFiles.length > 0 ? <Title>{processingFiles.length}개 항목 업로드 중</Title> : null}
      {totalProgress ? <TotalProgress>{Math.floor((totalProgress / totalSize) * 100)} %</TotalProgress> : null}
    </StyledProcessingHeader>
  );
};

const Title = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #ffffff;
`;

const TotalProgress = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #ffffff;
`;

const StyledProcessingHeader = styled.div`
  width: 100%;
  min-height: 46px;
  padding: 16px 15px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #33343d;
  display: flex;
  justify-content: space-between;
`;

export default ProgressFileHeader;
