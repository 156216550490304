import { ISearchResult } from "../Models/Interfaces";
import SearchDriver from "../Drivers/Search";
import { ISearchUseCase } from "./Interfaces";

export interface ISearchDriver<T> {
  search: (query: string, directoryIds: string[], fileIds: string[]) => Promise<T[]>;
}

class SearchUseCase implements ISearchUseCase {
  private searchDriver: ISearchDriver<ISearchResult>;

  constructor() {
    this.searchDriver = new SearchDriver<ISearchResult>();
  }

  public getSearchResult = async (value: string, directoryIds: string[], fileIds: string[]) => {
    return await this.searchDriver.search(value, directoryIds, fileIds);
  };
}

export default SearchUseCase;
