import * as React from "react";
import SearchBar from "./SearchBar";
import styled from "styled-components";
import SearchIcon from "../../../../assets/images/search-20-px.svg";

const SearchBarRow = (props: {
  searchValue: string;
  setSearchValue: (value: string) => void;
  isSearching: boolean;
  onFocus: () => void;
  onClick: () => void;
  onEnter: () => void;
}) => {
  const { searchValue, setSearchValue, isSearching, onClick, onFocus, onEnter } = props;

  return (
    <StyledSearchBarRow>
      <StyledSearchIcon src={SearchIcon} />
      <SearchBar
        value={searchValue}
        setValue={setSearchValue}
        isSearching={isSearching}
        onFocus={onFocus}
        onEnter={onEnter}
      />
      <StyledSearchRangeSelectionToggleButton
        isToggled={isSearching}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }}
      >
        {isSearching ? "범위 지정 완료" : "검색범위 지정"}
      </StyledSearchRangeSelectionToggleButton>
    </StyledSearchBarRow>
  );
};

const StyledSearchIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const StyledSearchRangeSelectionToggleButton = styled.button<{
  isToggled: boolean;
}>`
  padding: 10px 15px 9px 9px;
  border-radius: 5px;
  border: ${(props) => (props.isToggled ? "solid 1px #657eff" : "none")};
  background-color: ${(props) => (props.isToggled ? "transparent" : "#f0f1f3")};
  color: ${(props) => (props.isToggled ? "#657eff" : "#414245")};
  outline: none;
  white-space: nowrap;
`;

const StyledSearchBarRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdde3;
  margin-bottom: 13px;
`;

export default SearchBarRow;
