import * as React from "react";
import { PureComponent } from "react";
import styled from "styled-components";
import * as R from "ramda";
import PlayerManager from "../../../../../Programs/VideoEditor/VideoPlayer";

const AudioWaveTimeline = React.memo(
  (props: {
    durationInMilliSeconds: number;
    selectionMode: boolean;
    parentWidth: number;
    wave: number[];
    pps: number;
    playerManager: PlayerManager;
    height: number;
  }) => {
    const { wave, pps, durationInMilliSeconds, parentWidth, height, playerManager, selectionMode } = props;

    return (
      <StyledAudioTimeline height={height} selectionMode={selectionMode}>
        <AudioTimelineBox focused={false} id={"wave-container"}>
          <WaveV3Component
            durationInMilliSeconds={durationInMilliSeconds}
            wave={wave}
            parentWidth={parentWidth}
            pps={pps}
            playerManager={playerManager}
          />
        </AudioTimelineBox>
      </StyledAudioTimeline>
    );
  }
);

interface IProps {
  wave: number[];
  parentWidth: number;
  pps: number;
  durationInMilliSeconds: number;
  playerManager: PlayerManager;
}

interface IState {
  height?: number;
  width?: number;
  showWidth?: boolean;
  readonly canvasWidthLimit: number;
}

class WaveV3Component extends PureComponent<IProps, IState> {
  public initWave: boolean;

  constructor(props: IProps) {
    super(props);
    this.initWave = false;
    this.state = {
      height: undefined,
      width: undefined,
      showWidth: undefined,
      canvasWidthLimit: 4000,
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    if (
      (this.props.wave != null && !R.equals(prevProps.parentWidth, this.props.parentWidth)) ||
      prevProps.wave !== this.props.wave
    ) {
      this._clearWave();
      this._drawWave();
    }
  }

  public componentDidMount() {
    if (this.props.wave != null) {
      this.initWave = true;
      this._drawWave();
    }
  }

  public render() {
    return (
      <div style={{ width: this.state.width!, height: "100%" }}>
        <div id="wave" style={{ flex: 1, display: "flex", flexDirection: "row" }} />
      </div>
    );
  }

  private seek = (e: MouseEvent) => {
    const target = e.currentTarget as HTMLCanvasElement;
    const movement =
      ((target.offsetLeft + e.offsetX) / this.props.parentWidth) * (this.props.durationInMilliSeconds / 1000);
    this.props.playerManager?.seek(movement);
  };

  private _clearWave = () => {
    const waveArea: HTMLElement | null = document.getElementById("wave");
    if (waveArea == null) {
      return;
    }
    for (const child of Array.from(waveArea.childNodes)) {
      waveArea.removeChild(child);
    }
  };

  private _drawWave() {
    interface ICanvasData {
      x: number;
      y: number;
    }

    interface ICanvasMeta {
      width: number;
    }

    let wave = this.props.wave;
    if (wave.length === 0) {
      return;
    }

    const maxHeight = wave.map((f) => Math.abs(f)).reduce((a, b) => Math.max(a, b));
    wave = wave.map((f) => f / maxHeight);
    const wavecontainer: HTMLElement | null = document.getElementById("wave-container");

    if (wavecontainer == null) {
      return;
    }
    const height = wavecontainer.clientHeight;
    const width = this.props.parentWidth;

    const canvasUnitSize = Math.ceil(width / this.state.canvasWidthLimit);

    if (canvasUnitSize === 0) {
      return;
    }
    const canvasDatas: ICanvasData[][] = [];
    const canvasMetas: ICanvasMeta[] = [];
    for (let i = 0; i < canvasUnitSize; i++) {
      canvasDatas.push([]);
    }

    // add positive wave shape
    for (let idx = 0; idx < wave.length / 2; idx++) {
      const x =
        idx *
        ((this.props.parentWidth * 1000) / this.props.durationInMilliSeconds / this.props.pps) *
        (((this.props.durationInMilliSeconds / 1000) * this.props.pps) / (wave.length / 2));

      const canvasUnitIdx = Math.floor(x / this.state.canvasWidthLimit);

      canvasDatas[canvasUnitIdx].push({
        x: x - canvasUnitIdx * this.state.canvasWidthLimit,
        y: ((1 + wave[idx * 2]) / 2) * height,
      });
    }

    // make end point smooth
    for (let idx = 0; idx < canvasUnitSize - 1; idx++) {
      const lastPoint = canvasDatas[idx][canvasDatas[idx].length - 1];

      canvasDatas[idx].push({
        x: this.state.canvasWidthLimit,
        y: lastPoint == null ? 0 : lastPoint.y,
      });
      canvasDatas[idx].push({ x: this.state.canvasWidthLimit, y: height / 2 });
      canvasMetas.push({ width: this.state.canvasWidthLimit });
    }

    canvasMetas.push({
      width: Math.min(width - (canvasUnitSize - 1) * this.state.canvasWidthLimit, this.state.canvasWidthLimit),
    });

    // add negative wave shape
    for (let idx = wave.length / 2 - 1; idx >= 0; idx--) {
      const x =
        idx *
        ((this.props.parentWidth * 1000) / this.props.durationInMilliSeconds / this.props.pps) *
        (((this.props.durationInMilliSeconds / 1000) * this.props.pps) / (wave.length / 2));

      const canvasUnitIdx = Math.floor(x / this.state.canvasWidthLimit);

      canvasDatas[canvasUnitIdx].push({
        x: x - canvasUnitIdx * this.state.canvasWidthLimit,
        y: ((1 + wave[idx * 2 + 1]) / 2) * height,
      });
    }

    // make end point smooth
    for (let idx = canvasUnitSize - 1; idx > 0; idx--) {
      const firstPoint = canvasDatas[idx][0];
      canvasDatas[idx] = [
        { x: 0, y: height / 2 },
        { x: 0, y: firstPoint == null ? 0 : firstPoint.y },
        ...canvasDatas[idx],
      ];
    }

    const canvasContainer: HTMLElement | null = document.getElementById("wave");
    if (canvasContainer == null) {
      return;
    }

    for (let idx = 0; idx < canvasUnitSize; idx++) {
      const canvas = document.createElement("canvas");
      canvas.id = `canvas-${idx}`;

      if (canvas.getContext) {
        canvas.width = canvasMetas[idx].width;
        canvas.height = height;

        const ctx: CanvasRenderingContext2D = canvas.getContext("2d")!;
        ctx.beginPath();
        ctx.moveTo(0, height / 2);
        for (const point of canvasDatas[idx]) {
          ctx.lineTo(point.x, point.y);
        }
        ctx.lineTo(0, height / 2);
        ctx.lineWidth = 1;
        ctx.fillStyle = "#dcdde3";
        ctx.globalAlpha = 0.59;
        ctx.fill();
        canvas.addEventListener("click", this.seek);
      }

      canvasContainer.appendChild(canvas);
    }

    this.setState({
      width,
      height,
    });
  }
}

export default AudioWaveTimeline;

const AudioTimelineBox = styled.div<{ focused: boolean }>`
  position: absolute;
  width: 100%;
  height: 64px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
`;

const StyledAudioTimeline = styled.div<{ height: number; selectionMode: boolean }>`
  position: relative;
  height: ${(props) => props.height}px;
  padding: 12px 0 4px;
  border-radius: 3px;
  border: ${(props) => (props.selectionMode ? "1px solid #e5e6ec" : "1px solid #edeef4")};
  background-color: ${(props) => (props.selectionMode ? "#eeeeef" : "#f7f7f7")};
`;
