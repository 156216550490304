import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import ProgressFilePopup from "./ProgressFilePopup";
import {
  CreateFileManagerContext,
  IVideoFilePipelineProcess,
} from "../../../Contexts/FileManagerContexts/CreateFileManagerContext";

const ProgressFiles = () => {
  const [processingFiles, setProcessingFiles] = useState<IVideoFilePipelineProcess[]>([]);

  useEffect(() => {
    if (isAllProcessDone(processingFiles)) {
      setProcessingFiles([]);
    }
  }, [processingFiles]);

  const addToProcessingFiles = (target: IVideoFilePipelineProcess) => {
    setProcessingFiles((prevState) => [...prevState, target]);
  };

  const updateProgress = useCallback((processId: number, progress: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) => (s.processId === processId ? { ...s, uploadProgress: progress } : s))
    );
  }, []);

  const updateUploadingStatus = (processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId ? { ...s, isUploading: true, isUploaded: false, isUploadError: false } : s
      )
    );
  };

  const updateUploadedStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) => (s.processId === processId ? { ...s, isUploading: false, isUploaded: true } : s))
    );
  }, []);

  const updateUploadCancelStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId ? { ...s, isUploading: false, isUploaded: false, isUploadError: true } : s
      )
    );
  }, []);

  const updateAnalyzingStatus = (processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId ? { ...s, isAnalyzing: true, isAnalyzed: false, isUploadError: false } : s
      )
    );
  };

  const updateAnalyzedStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId
          ? {
              ...s,
              isAnalyzing: false,
              isAnalyzed: true,
              isAnalyzeError: false,
            }
          : s
      )
    );
  }, []);

  const updateAnalyzeErrorStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId
          ? {
              ...s,
              isAnalyzing: false,
              isAnalyzed: false,
              isAnalyzeError: true,
            }
          : s
      )
    );
  }, []);

  const updateAudioExtractingStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId
          ? {
              ...s,
              isAudioExtracting: true,
              isAudioExtracted: false,
              isAudioExtractError: false,
            }
          : s
      )
    );
  }, []);

  const updateAudioExtractedStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId
          ? {
              ...s,
              isAudioExtracting: false,
              isAudioExtracted: true,
              isAudioExtractError: false,
            }
          : s
      )
    );
  }, []);

  const updateAudioExtractErrorStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId
          ? {
              ...s,
              isAudioExtracting: false,
              isAudioExtracted: false,
              isAudioExtractError: true,
            }
          : s
      )
    );
  }, []);

  const updateAudioWaveExtractingStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId
          ? {
              ...s,
              isAudioWaveExtracting: true,
              isAudioWaveExtracted: false,
              isAudioWaveExtractError: false,
            }
          : s
      )
    );
  }, []);

  const updateAudioWaveExtractedStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId
          ? {
              ...s,
              isAudioWaveExtracting: false,
              isAudioWaveExtracted: true,
              isAudioWaveExtractError: false,
            }
          : s
      )
    );
  }, []);

  const updateAudioWaveExtractErrorStatus = useCallback((processId: number) => {
    setProcessingFiles((prevState) =>
      prevState.map((s) =>
        s.processId === processId
          ? {
              ...s,
              isAudioWaveExtracting: false,
              isAudioWaveExtracted: false,
              isAudioWaveExtractError: true,
            }
          : s
      )
    );
  }, []);

  const updateUploadProgress = useCallback(
    (params: { processId: number; loaded: number; total: number }) => {
      updateUploadingStatus(params.processId);
      updateProgress(params.processId, params.loaded);
    },
    [updateProgress]
  );

  const {
    subscribePipelineStart,
    subscribeFileUploading,
    subscribeFileUploadCanceled,
    subscribeFileCreated,
    subscribeFileAnalyzing,
    subscribeFileAnalyzed,
    subscribeFileAnalyzeError,
    subscribeAudioExtracting,
    subscribeAudioExtracted,
    subscribeAudioExtractError,
    subscribeAudioWaveExtracting,
    subscribeAudioWaveExtracted,
    subscribeAudioWaveExtractError,
  } = useContext(CreateFileManagerContext);

  useEffect(() => {
    const subscription = subscribePipelineStart!(addToProcessingFiles);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribePipelineStart]);

  useEffect(() => {
    const subscription = subscribeFileUploading!(updateUploadProgress);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeFileUploading, updateUploadProgress]);

  const updateUploadDone = useCallback(
    (params: { processId: number }) => {
      updateUploadedStatus(params.processId);
    },
    [updateUploadedStatus]
  );

  useEffect(() => {
    const subscription = subscribeFileCreated!(updateUploadDone);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeFileCreated, updateUploadDone]);

  const updateUploadCanceled = useCallback(
    (params: { processId: number }) => {
      updateUploadCancelStatus(params.processId);
    },
    [updateUploadCancelStatus]
  );

  useEffect(() => {
    const subscription = subscribeFileUploadCanceled!(updateUploadCanceled);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeFileUploadCanceled, updateUploadCanceled]);

  const updateAnalyzing = useCallback((params: { processId: number }) => {
    updateAnalyzingStatus(params.processId);
  }, []);

  useEffect(() => {
    const subscription = subscribeFileAnalyzing!(updateAnalyzing);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeFileAnalyzing, updateAnalyzing]);

  const updateAnalyzed = useCallback(
    (params: { processId: number }) => {
      updateAnalyzedStatus(params.processId);
    },
    [updateAnalyzedStatus]
  );

  useEffect(() => {
    const subscription = subscribeFileAnalyzed!(updateAnalyzed);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeFileAnalyzed, updateAnalyzed]);

  const updateAnalyzeCanceled = useCallback(
    (params: { processId: number }) => {
      updateAnalyzeErrorStatus(params.processId);
    },
    [updateAnalyzeErrorStatus]
  );

  useEffect(() => {
    const subscription = subscribeFileAnalyzeError!(updateAnalyzeCanceled);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeFileAnalyzeError, updateAnalyzeCanceled]);

  const updateAudioExtracting = useCallback(
    (params: { processId: number }) => {
      updateAudioExtractingStatus(params.processId);
    },
    [updateAudioExtractingStatus]
  );

  useEffect(() => {
    const subscription = subscribeAudioExtracting!(updateAudioExtracting);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeAudioExtracting, updateAudioExtracting]);

  const updateAudioExtracted = useCallback(
    (params: { processId: number }) => {
      updateAudioExtractedStatus(params.processId);
    },
    [updateAudioExtractedStatus]
  );

  useEffect(() => {
    const subscription = subscribeAudioExtracted!(updateAudioExtracted);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeAudioExtracted, updateAudioExtracted]);

  const updateAudioExtractCanceled = useCallback(
    (params: { processId: number }) => {
      updateAudioExtractErrorStatus(params.processId);
    },
    [updateAudioExtractErrorStatus]
  );

  useEffect(() => {
    const subscription = subscribeAudioExtractError!(updateAudioExtractCanceled);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeAudioExtractError, updateAudioExtractCanceled]);

  const updateAudioWaveExtracting = useCallback(
    (params: { processId: number }) => {
      updateAudioWaveExtractingStatus(params.processId);
    },
    [updateAudioWaveExtractingStatus]
  );

  useEffect(() => {
    const subscription = subscribeAudioWaveExtracting!(updateAudioWaveExtracting);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeAudioWaveExtracting, updateAudioWaveExtracting]);

  const updateAudioWaveExtracted = useCallback(
    (params: { processId: number }) => {
      updateAudioWaveExtractedStatus(params.processId);
    },
    [updateAudioWaveExtractedStatus]
  );

  useEffect(() => {
    const subscription = subscribeAudioWaveExtracted!(updateAudioWaveExtracted);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeAudioWaveExtracted, updateAudioWaveExtracted]);

  const updateAudioWaveExtractCanceled = useCallback(
    (params: { processId: number }) => {
      updateAudioWaveExtractErrorStatus(params.processId);
    },
    [updateAudioWaveExtractErrorStatus]
  );

  useEffect(() => {
    const subscription = subscribeAudioWaveExtractError!(updateAudioWaveExtractCanceled);
    return () => {
      subscription.unsubscribe();
    };
  }, [processingFiles, subscribeAudioWaveExtractError, updateAudioWaveExtractCanceled]);

  const removeFile = (processId: number) => {
    setProcessingFiles((prevState) => prevState.filter((s) => s.processId !== processId));
  };

  return <ProgressFilePopup processingFiles={processingFiles} removeFile={removeFile} />;
};

const isAllProcessDone = (processingFiles: IVideoFilePipelineProcess[]) => {
  const isUploadDone = processingFiles.filter((pf) => pf.isUploaded).length === processingFiles.length;
  const isAnalyzeDone = processingFiles.filter((pf) => pf.isAnalyzed).length === processingFiles.length;
  const isAudioExtractDone = processingFiles.filter((pf) => pf.isUploaded).length === processingFiles.length;
  const isAudioWaveExtractDone = processingFiles.filter((pf) => pf.isUploaded).length === processingFiles.length;
  return processingFiles.length > 0 && isUploadDone && isAnalyzeDone && isAudioExtractDone && isAudioWaveExtractDone;
};

export default ProgressFiles;
