import * as React from "react";
import { useContext, useEffect } from "react";
import { AuthenticationContext } from "../../../Contexts/AuthenticationContext";
import useFileTree from "../../../Containers/UseFileTree";
import { DirectoryItemView } from "../FileTree";
import { useHistory, useLocation } from "react-router-dom";

const FileTree = () => {
  const { isLogin } = useContext(AuthenticationContext);
  const history = useHistory();
  const location = useLocation();
  const { root, toggleDirectory, initializeRootDirectory } = useFileTree();
  useEffect(() => {
    if (isLogin) {
      initializeRootDirectory();
    }
  }, [initializeRootDirectory, isLogin]);

  const parsedDirectoryIdFromPath = location.pathname.split("/").pop();

  const onDirectoryClick = (id: string) => {
    history.push(`/directory/${id}`);
  };

  const onDirectoryDoubleClick = (id: string) => {
    toggleDirectory(id);
  };

  const onFileClick = () => {};

  const onFileSelected = () => {
    return false;
  };

  const onDirectorySelected = (id: string) => {
    return parsedDirectoryIdFromPath === id;
  };

  if (root == null) {
    return null;
  }

  return (
    <DirectoryItemView
      directory={root}
      hideRoot={true}
      onDirectoryClick={onDirectoryClick}
      onDirectoryDoubleClick={onDirectoryDoubleClick}
      onFileClick={onFileClick}
      fileSelected={onFileSelected}
      directorySelected={onDirectorySelected}
      toggleDirectory={toggleDirectory}
      depth={0}
    />
  );
};

export default FileTree;
