import api from "../api";
import { IAudioDriver, IAudioExtractHandlers } from "../UseCases/Files/VideoFile/AudioExtractUseCase";

class AudioDriver implements IAudioDriver {
  public extractAudio = async (fileId: string, handlers?: IAudioExtractHandlers) => {
    api.requestExtractAudio(fileId).then(() => {
      let isDone = false;
      let extractError: any; // error

      const checkStatus = () => {
        if (isDone) {
          if (handlers?.onDone) {
            handlers.onDone();
          }
          return;
        }

        if (extractError != null) {
          if (handlers?.onCanceled) {
            handlers.onCanceled();
          }
          return;
        }

        this.checkExtractAudio(fileId)
          .then(({ status, waveFormS3Path, waveFormDownloadUrl }) => {
            if (status === "DONE") {
              isDone = true;
            }

            if (status === "ERROR") {
              extractError = "error";
            }

            if (status === "PROCESSING" || status === "WAITING" || status === "START") {
              if (handlers?.onProgress) {
                handlers.onProgress();
              }
            }

            setTimeout(checkStatus, 5000);
          })
          .catch((e) => {
            extractError = e;
            setTimeout(checkStatus, 5000);
          });
      };

      checkStatus();
    });
  };

  public checkExtractAudio = async (fileId: string) => {
    const { data } = await api.checkExtractAudio(fileId);
    return {
      status: data.status,
      waveFormS3Path: data.waveFormS3Path,
      waveFormDownloadUrl: data.waveFormDownloadUrl,
    };
  };

  public extractWaveForm = (fileId: string, handlers?: IAudioExtractHandlers) => {
    api.requestExtractWaveForm(fileId).then(() => {
      let isDone = false;
      let extractError: any; // error

      const checkStatus = () => {
        if (isDone) {
          if (handlers?.onDone) {
            handlers.onDone();
          }
          return;
        }

        if (extractError != null) {
          if (handlers?.onCanceled) {
            handlers.onCanceled();
          }
          return;
        }

        this.checkExtractWaveForm(fileId)
          .then(({ status, waveFormS3Path, waveFormDownloadUrl }) => {
            if (status === "DONE") {
              isDone = true;
            }

            if (status === "ERROR") {
              extractError = "error";
            }

            if (status === "PROCESSING" || status === "WAITING" || status === "START") {
              if (handlers?.onProgress) {
                handlers.onProgress();
              }
            }
            setTimeout(checkStatus, 5000);
          })
          .catch((e) => {
            extractError = e;
            setTimeout(checkStatus, 5000);
          });
      };

      checkStatus();
    });
  };

  public checkExtractWaveForm = async (fileId: string) => {
    const { data } = await api.checkExtractWaveForm(fileId);
    return {
      status: data.status,
      waveFormS3Path: data.waveFormS3Path,
      waveFormDownloadUrl: data.waveFormDownloadUrl,
    };
  };
}

export default AudioDriver;
