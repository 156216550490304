import api from "../../api";

export interface ISubtitle {
  start: { total_milliseconds: number };
  end: { total_milliseconds: number };
  text: string;
}

export interface IASRResponse {
  done: boolean;
  progress: number;
  response: {
    duration: { total_milliseconds: number };
    subtitles: ISubtitle[];
  };
}

export interface IASRRepository {
  getASRText: (fileId: string) => Promise<IASRResponse>;
}

class ASRRepository implements IASRRepository {
  public getASRText = async (fileId: string) => {
    const { data } = await api.getTextSource(fileId);
    return data;
  };
}

export default ASRRepository;
