// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as React from "react";
import { useEffect, useState } from "react";
import { IDirectoryDTO, IFileDTO } from "../../Models/Interfaces";

export enum SORT {
  NAME = "NAME",
  SIZE = "SIZE",
  TIME = "TIME",
}

const useFileSort = (props: { data: Array<IFileDTO | IDirectoryDTO>; sort?: SORT; inverted?: boolean }) => {
  const { data, sort, inverted } = props;
  const [sortedFiles, setSortedFiles] = useState(data);

  useEffect(() => {
    if (sort == null) {
      setSortedFiles(data);
    }

    const sortFunction = (a: any, b: any) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }

      return 0;
    };

    if (sort === SORT.NAME) {
      let nameSorted = [...data].sort((a, b) => sortFunction(a.name.toUpperCase(), b.name.toUpperCase()));

      if (inverted) {
        nameSorted = nameSorted.reverse();
      }

      setSortedFiles(nameSorted);
    }

    if (sort === SORT.SIZE) {
      let sizeSorted = [...data].sort((a, b) => sortFunction(a.size, b.size));

      if (inverted) {
        sizeSorted = sizeSorted.reverse();
      }

      setSortedFiles(sizeSorted);
    }

    if (sort === SORT.TIME) {
      let timeSorted = [...data].sort((a, b) => sortFunction(a.createdDateTime, b.createdDateTime));

      if (inverted) {
        timeSorted = timeSorted.reverse();
      }

      setSortedFiles(timeSorted);
    }
  }, [data, sort, inverted]);

  return sortedFiles;
};

export default useFileSort;
