import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Header, Input, Label, Form, PageBody, PageHeader, PageWrapper } from "../Commons";
import AuthenticationUseCase from "../../../../UseCases/Authentication";
import { useHistory } from "react-router-dom";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();
  const resetPassword = async () => {
    try {
      const authentication = new AuthenticationUseCase();
      await authentication.resetPassword(email);
      alert("임시 비밀번호가 발급되었습니다. 메일에서 확인해주세요");
      history.push("/authentication");
    } catch (e) {
      if (e.response.status === 404) {
        alert("가입하지않은 이메일입니다");
      }
      console.log(e);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <Header>비밀번호 찾기</Header>
      </PageHeader>
      <PageBody>
        <FormWrapper>
          <Description>회원가입 시 등록한 이메일로 임시 비밀번호를 보내드립니다.</Description>
          <Form>
            <Label>이메일 주소</Label>
            <Input value={email} placeholder={"이메일 주소"} onChange={(e) => setEmail(e.currentTarget.value)} />
          </Form>
          <SignInButton onClick={resetPassword} disabled={false}>
            변경하기
          </SignInButton>
        </FormWrapper>
      </PageBody>
    </PageWrapper>
  );
};

const Description = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #33343d;
`;

const SignInButton = styled.button.attrs((props) => ({
  disabled: props.disabled,
}))`
  padding: 17px 16px 17px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? "#acacac" : "#657eff")};
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
`;

const FormWrapper = styled.div`
  width: 343px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Description} {
    width: 100%;
    margin-bottom: 29px;
  }

  ${Form} {
    width: 100%;
    margin-bottom: 20px;
  }

  ${SignInButton} {
    width: 100%;
    margin-bottom: 43px;
  }
`;

export default ResetPasswordPage;
