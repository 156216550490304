import { IAnalysisHandler, IUploadHandlers } from "../../Interfaces";
import AnalysisUseCase from "./Analysis";
import { FileStatus, IFile, IFileDTO } from "../../../Models/Interfaces";
import UploadUseCase from "../../Upload";
import { toS3Path } from "../../../utils/utils";
import AudioExtractUseCase, { IAudioExtractHandlers, IAudioExtractUseCase } from "./AudioExtractUseCase";
import { FileUseCases } from "../File";
import { FileModel } from "../../../Models/FileNode";

export interface IFileCreateHandlers {
  uploadHandlers?: IUploadHandlers;
  fileCreated?: (file: IFileDTO) => void;
  audioExtractHandlers?: IAudioExtractHandlers;
  audioWaveExtractHandlers?: IAudioExtractHandlers;
  analyzeHandlers?: IAnalysisHandler;
}

export interface IVideoFileCreateUseCase {
  requestCreateFromFileUpload: (
    directoryId: string,
    file: File,
    name: string,
    handlers?: IFileCreateHandlers
  ) => Promise<IFileDTO>;
  requestCreateFromFileCreate: (
    directoryId: string,
    s3Path: string,
    s3Url: string,
    name: string,
    handlers?: IFileCreateHandlers
  ) => Promise<IFileDTO>;
  requestCreateFromFileExtractAudio: (fileId: string, handlers?: IFileCreateHandlers) => Promise<IFileDTO>;
  requestCreateFromFileExtractWaveForm: (fileId: string, handlers?: IFileCreateHandlers) => Promise<IFileDTO>;
  requestCreateFromFileAnalyze: (fileId: string, handlers?: IFileCreateHandlers) => Promise<IFileDTO>;
  processProcessingFile: (fileId: string, handlers?: IFileCreateHandlers) => Promise<void>;
}

class VideoFileCreateUseCase extends FileUseCases implements IVideoFileCreateUseCase {
  private audioExtractUseCase: IAudioExtractUseCase;
  private analysisUseCase: AnalysisUseCase;

  constructor() {
    super();
    this.audioExtractUseCase = new AudioExtractUseCase();
    this.analysisUseCase = new AnalysisUseCase();
  }

  public requestCreateFromFileUpload = async (
    directoryId: string,
    file: File,
    name: string,
    handlers?: IFileCreateHandlers
  ) => {
    const { s3Path, s3Url } = await this.requestUpload(file, name, handlers?.uploadHandlers);
    const fileDTO = await this.requestCreateFromFileCreate(directoryId, s3Path, s3Url, name, handlers);
    return fileDTO;
  };

  public requestCreateFromFileCreate = async (
    directoryId: string,
    s3Path: string,
    s3Url: string,
    name: string,
    handlers?: IFileCreateHandlers
  ) => {
    const fileDTO = await this.createFile(directoryId, name, s3Path, s3Url);

    if (handlers?.fileCreated) {
      handlers.fileCreated(fileDTO);
    }

    await this.updateStatusAnalyzing(fileDTO.id);
    const updatedFile = await this.requestCreateFromFileExtractAudio(fileDTO.id, handlers);
    return updatedFile;
  };

  public requestCreateFromFileExtractAudio = async (fileId: string, handlers?: IFileCreateHandlers) => {
    await this.requestAudioExtract(fileId, handlers?.audioExtractHandlers);
    const updatedFile = await this.requestCreateFromFileExtractWaveForm(fileId, handlers);
    return updatedFile;
  };

  public requestCreateFromFileExtractWaveForm = async (fileId: string, handlers?: IFileCreateHandlers) => {
    await this.requestAudioWaveFormExtract(fileId, handlers?.audioWaveExtractHandlers);
    const updatedFile = await this.requestCreateFromFileAnalyze(fileId, handlers);
    return updatedFile;
  };

  public requestCreateFromFileAnalyze = async (fileId: string, handlers?: { analyzeHandlers?: IAnalysisHandler }) => {
    await this.requestAnalyze(fileId, handlers?.analyzeHandlers);
    const updatedFile = await this.getFile(fileId);
    return updatedFile;
  };

  public processProcessingFile = async (fileId: string, handlers?: IFileCreateHandlers) => {
    const file = await this.getFile(fileId);
    const isFileProcessing = file.status === FileStatus.ANALYZING || file.status === FileStatus.UPLOADED;

    if (isFileProcessing) {
      const processFunc = await this.getProcessFileFunc(file);
      if (processFunc) {
        await processFunc(handlers);
      }
    }
  };

  private getProcessFileFunc = async (file: IFileDTO) => {
    if (await this.isNeedRequestAudioExtract(file)) {
      return async (handlers?: IFileCreateHandlers) => await this.requestCreateFromFileExtractAudio(file.id, handlers);
    }

    if (await this.isProcessingAudioExtract(file)) {
      return async (handlers?: IFileCreateHandlers) => {
        const checker = async () => {
          const { status } = await this.audioExtractUseCase.checkGenerateAudio(file.id);

          if (status === "DONE") {
            await this.requestCreateFromFileExtractWaveForm(file.id, handlers);
            return;
          }

          if (handlers?.audioExtractHandlers?.onProgress) {
            handlers.audioExtractHandlers.onProgress();
          }

          setTimeout(checker, 3000);
        };

        await checker();
      };
    }

    if (await this.isNeedRequestAudioWaveExtract(file)) {
      return (handlers?: IFileCreateHandlers) => this.requestCreateFromFileExtractWaveForm(file.id, handlers);
    }

    if (await this.isProcessingAudioWaveExtract(file)) {
      return async (handlers?: IFileCreateHandlers) => {
        const checker = async () => {
          const { status } = await this.audioExtractUseCase.checkGenerateAudioWave(file.id);

          if (status === "DONE") {
            await this.requestCreateFromFileAnalyze(file.id, handlers);
            return;
          }

          if (handlers?.audioWaveExtractHandlers?.onProgress) {
            handlers.audioWaveExtractHandlers.onProgress();
          }

          setTimeout(checker, 3000);
        };

        await checker();
      };
    }

    if (await this.isNeedRequestAnalysis(file)) {
      return (handlers?: IFileCreateHandlers) => this.requestCreateFromFileAnalyze(file.id, handlers);
    }

    if (await this.isRequestedAnalysis(file)) {
      return async (handlers?: IFileCreateHandlers) => {
        const checker = async () => {
          const { done } = await this.analysisUseCase.checkAnalysis(file.id);

          if (done) {
            if (handlers?.analyzeHandlers?.onDone) {
              handlers.analyzeHandlers.onDone(file.id);
            }
            await this.setAnalyzeDone(file.id);
            await this.deleteProcessingFile(file.id);
            return;
          }

          if (handlers?.analyzeHandlers?.onProgress) {
            handlers.analyzeHandlers.onProgress();
          }

          setTimeout(checker, 10000);
        };

        await checker();
      };
    }
  };

  public createFile = async (directoryId: string, name: string, s3Path: string, s3Url?: string) => {
    const fileModel: IFile = {
      directoryId,
      name: name,
      s3Path: s3Path,
      s3Url: s3Url,
    };

    const createdFile = await this.repository.createFile(directoryId, fileModel);

    return new FileModel(createdFile).toDTO();
  };

  public getProcessingFiles = async () => {
    const fileIds = await this.repository.getProcessingFiles();
    const getProcessingFilesPromises = fileIds.map(async (id) => await this.getFile(id));
    const fileDTOs = await Promise.all(getProcessingFilesPromises);
    return fileDTOs;
  };

  public deleteProcessingFile = async (fileId: string) => {
    await this.repository.deleteProcessingFile(fileId);
  };

  private requestUpload = async (file: File, name?: string, uploadHandlers?: IUploadHandlers) => {
    const uploadInstance = new UploadUseCase();

    const cancelUpload = () => {
      uploadInstance.cancelUpload();
    };

    if (uploadHandlers?.addCancelSubscribers) {
      uploadHandlers.addCancelSubscribers(cancelUpload);
    }

    const uploadResult = await uploadInstance.upload(file, name, uploadHandlers);

    const s3Url = uploadResult.location;
    const s3Path = toS3Path(uploadResult.location);

    return { s3Url, s3Path };
  };

  private requestAudioExtract = (fileId: string, audioExtractHandlers?: IAudioExtractHandlers) => {
    return new Promise((resolve, reject) => {
      const handlers: IAudioExtractHandlers = {
        onRequest: audioExtractHandlers?.onRequest,
        onProgress: audioExtractHandlers?.onProgress,
        onDone: () => {
          if (audioExtractHandlers?.onDone) {
            audioExtractHandlers.onDone();
          }
          resolve(true);
        },
        onCanceled: () => {
          if (audioExtractHandlers?.onCanceled) {
            audioExtractHandlers.onCanceled();
          }
          reject(false);
        },
      };
      this.audioExtractUseCase.requestGenerateAudio(fileId, handlers);
    });
  };

  private requestAudioWaveFormExtract = (fileId: string, audioExtractHandlers?: IAudioExtractHandlers) => {
    return new Promise((resolve, reject) => {
      const handlers: IAudioExtractHandlers = {
        onRequest: audioExtractHandlers?.onRequest,
        onProgress: audioExtractHandlers?.onProgress,
        onDone: () => {
          if (audioExtractHandlers?.onDone) {
            audioExtractHandlers.onDone();
          }
          resolve(true);
        },
        onCanceled: () => {
          if (audioExtractHandlers?.onCanceled) {
            audioExtractHandlers.onCanceled();
          }
          reject(false);
        },
      };
      this.audioExtractUseCase.requestGenerateWaveForm(fileId, handlers);
    });
  };

  private requestAnalyze = (fileId: string, analysisHandlers?: IAnalysisHandler) => {
    return new Promise((resolve, reject) => {
      const handlers: IAnalysisHandler = {
        onProgress: analysisHandlers?.onProgress,
        onDone: () => {
          this.setAnalyzeDone(fileId).then(() => {
            this.deleteProcessingFile(fileId).then(() => {
              if (analysisHandlers?.onDone) {
                analysisHandlers.onDone(fileId);
              }

              resolve(true);
            });
          });
        },
        onCanceled: () => {
          if (analysisHandlers?.onCanceled) {
            analysisHandlers.onCanceled();
          }
          reject(false);
        },
      };
      this.analysisUseCase.analysis(fileId, handlers);
    });
  };

  private updateStatusAnalyzing = async (id: string) => {
    const file = await this.repository.setAnalyzing(id);
    return new FileModel(file).toDTO();
  };

  private setAnalyzeDone = async (id: string) => {
    const file = await this.repository.setAnalyzeDone(id);
    return new FileModel(file).toDTO();
  };

  private isRequestedAnalysis = async (file: IFileDTO): Promise<boolean> => {
    if (file.analysisRequestId == null) {
      return false;
    }

    const response = await this.analysisUseCase.checkAnalysis(file.id);
    return !response.done;
  };

  private isNeedRequestAnalysis = async (file: IFileDTO): Promise<boolean> => {
    return file.analysisRequestId == null;
  };

  private isNeedRequestAudioExtract = async (file: IFileDTO): Promise<boolean> => {
    return file.audioExtractionRequestId == null;
  };

  private isProcessingAudioExtract = async (file: IFileDTO): Promise<boolean> => {
    if (file.audioExtractionRequestId == null) {
      return false;
    }

    const response = await this.audioExtractUseCase.checkGenerateAudio(file.id);
    return response.status !== "DONE";
  };

  private isNeedRequestAudioWaveExtract = async (file: IFileDTO): Promise<boolean> => {
    return file.waveFormRequestId == null;
  };

  private isProcessingAudioWaveExtract = async (file: IFileDTO): Promise<boolean> => {
    if (file.waveFormRequestId == null) {
      return false;
    }
    const response = await this.audioExtractUseCase.checkGenerateAudioWave(file.id);
    return response.status !== "DONE";
  };
}

export default VideoFileCreateUseCase;
