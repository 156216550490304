import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import PlayerManager from "../../../../../Programs/VideoEditor/VideoPlayer";
import styled from "styled-components";

import DecreasePlayRateIcon from "../../../../../assets/images/fast-rewind-24-px.svg";
import IncreasePlayRateIcon from "../../../../../assets/images/fast-rewind-24-px-copy.svg";

const PlayControlComponent = (props: { playerManager: PlayerManager }) => {
  const { playerManager } = props;
  const [playRate, setPlayRate] = useState(1);

  useEffect(() => {
    const currentPlayRate = playerManager.getPlaybackRate();
    setPlayRate(currentPlayRate);
  }, [playerManager]);

  const increasePlayRate = useCallback(() => {
    if (playerManager.getPlaybackRate() >= 2) {
      return;
    }
    const playRate = playerManager.increasePlaybackRate();
    setPlayRate(playRate);
  }, [playerManager]);

  const decreasePlayRate = useCallback(() => {
    if (playerManager.getPlaybackRate() <= 0.5) {
      return;
    }
    const playRate = playerManager.decreasePlaybackRate();
    setPlayRate(playRate);
  }, [playerManager]);

  return (
    <StyledPlayControl>
      <PlayControlTitle>배속재생</PlayControlTitle>
      <PlayControlWrapper>
        <DecreasePlayRate src={DecreasePlayRateIcon} onClick={decreasePlayRate} />
        <CurrentPlayRate>{playRate}배</CurrentPlayRate>
        <IncreasePlayRate src={IncreasePlayRateIcon} onClick={increasePlayRate} />
      </PlayControlWrapper>
    </StyledPlayControl>
  );
};

const PlayControlTitle = styled.div``;

const DecreasePlayRate = styled.img`
  width: 24px;
  height: 24px;
`;
const IncreasePlayRate = styled.img`
  width: 24px;
  height: 24px;
`;
const CurrentPlayRate = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${DecreasePlayRate} {
    margin-right: 9px;
  }
  ${CurrentPlayRate} {
    margin-right: 9px;
  }
`;

const StyledPlayControl = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${PlayControlTitle} {
    margin-right: 23px;
  }
`;

export default PlayControlComponent;
