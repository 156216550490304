import api from "../api";
import { IFile } from "../Models/Interfaces";
import { IFileRepository } from "./Interfaces";

class FileRepository implements IFileRepository {
  public getFile = async (id: string) => {
    const { data } = await api.getFile(id);
    return data;
  };

  public createFile = async (directoryId: string, file: IFile) => {
    const { data } = await api.createFile(file);
    return data;
  };

  public deleteFile = async (id: string) => {
    const { data } = await api.deleteFile(id);
    return data;
  };

  public renameFile = async (id: string, name: string) => {
    const { data } = await api.patchFileName(id, name);
    return data;
  };

  public updateParentDirectory = async (id: string, directoryId: string) => {
    const { data } = await api.patchFileDirectory(id, directoryId);
    return data;
  };

  public copyFile = async (id: string, directoryId: string) => {
    const { data } = await api.copyFile(id, directoryId);
    return data;
  };

  public getProcessingFiles = async () => {
    const { data } = await api.getProcessingFiles();
    return data.fileIds;
  };

  public deleteProcessingFile = async (fileId: string) => {
    await api.deleteProcessingFile(fileId);
  };

  public setAnalyzeDone = async (id: string) => {
    const { data } = await api.updateFileStatus(id, "ANALYZED");
    return data;
  };

  public setUploadDone = async (id: string) => {
    const { data } = await api.updateFileStatus(id, "UPLOADED");
    return data;
  };

  public setAnalyzing = async (id: string) => {
    const { data } = await api.updateFileStatus(id, "ANALYZING");
    return data;
  };
}

export default FileRepository;
