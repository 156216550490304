import { IUploadHandlers, IUploadUseCase } from "./Interfaces";
import MultipartUploadDriver from "../Drivers/MultipartUpload";

export interface IUploadDriver {
  upload: (file: File, fileName?: string, uploadHandlers?: IUploadHandlers) => Promise<{ location: string }>;
  cancelUpload: () => void;
}

class UploadUseCase implements IUploadUseCase {
  private uploadDriver: IUploadDriver;

  constructor() {
    this.uploadDriver = new MultipartUploadDriver();
  }

  public upload = async (file: File, name?: string, uploadHandlers?: IUploadHandlers) => {
    const result = await this.uploadDriver.upload(file, name, uploadHandlers);
    return { location: result.location };
  };

  public cancelUpload = () => {
    if (this.uploadDriver && this.uploadDriver.cancelUpload) {
      this.uploadDriver.cancelUpload();
    }
  };
}

export default UploadUseCase;
