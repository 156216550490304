import api from "../api";
import { IDirectory } from "../Models/Interfaces";
import { IDirectoryRepository } from "./Interfaces";

class DirectoryRepository implements IDirectoryRepository {
  public getRootDirectory = async () => {
    const { data } = await api.getRootDirectory();
    const rootDirectory: IDirectory = data;
    return rootDirectory;
  };

  public getDirectory = async (id: string) => {
    const { data } = await api.getDirectory(id);
    return data;
  };

  public createDirectory = async (directory: IDirectory) => {
    const { data } = await api.createDirectory(directory);
    return data;
  };

  public deleteDirectory = async (id: string) => {
    const { data } = await api.deleteDirectory(id);
    return data;
  };

  public renameDirectory = async (id: string, name: string) => {
    const { data } = await api.patchDirectory(id, name);
    return data;
  };

  public updateParentDirectory = async (id: string, parentDirectoryId: string) => {
    const { data } = await api.patchParentDirectory(id, parentDirectoryId);
    return data;
  };

  public askDirectoryFullPath = async (id: string): Promise<string[]> => {
    const { data } = await api.explorerDirectory(id, "DIRECTORY_IDS");
    return data;
  };
}

export default DirectoryRepository;
